import React, { useState, useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';

import { useStore } from '../../stores';
import useAxios from '../../hooks/axios';
import * as ConstantAPI from '../../constants/api';
import { PAGE_LIST } from '../../constants/pages';
import { isImageExt } from '../../utils/functions';
import { toLowerCase } from '../../utils/formatters';

import Layout from '../../components/Layout';
import SearchInput from '../../components/SearchInput';
import Title from '../../components/Title';
import QuickAccessList from '../../components/home/QuickAccessList';
import RecentList from '../../components/home/RecentList';
import ViewGridMode from '../../components/ViewGridMode';
import ViewListMode from '../../components/ViewListMode';

const HomeList = observer(() => {
  const [title, setTitle] = useState('Trang chủ');

  const axios = useAxios();
  const { viewStore, fileActionStore, OCRStore, notificationStore, searchStore } = useStore();
  const [recentData, setrecentData] = useState({});
  const [folderData, setFolderData] = useState([]);
  const params = queryString.parse(window.location.search);

  const submitRename = (params) => {
    return axios.put(ConstantAPI.API_URL_RENAME, params);
  }

  const submitMove = (params) => {
    return axios.post(ConstantAPI.API_URL_MOVE, params);
  }

  const submitDelete = (id) => {
    return axios.delete(`${ConstantAPI.API_URL_DOCUMENT}/${id}`);
  }

  const submitApprove = (id) => {
    return axios.put(`${ConstantAPI.API_URL_APPROVE}/${id}`);
  }

  const getDetailDoc = (id) => {
    return axios.get(ConstantAPI.API_URL_DOCUMENT_DETAIL + id);
  }

  const getLog = (id) => {
    return axios.get(ConstantAPI.API_URL_VIEW_LOG + id);
  }

  const getFolderTree = (params) => {
    return axios.get(ConstantAPI.API_URL_DOCUMENT, { params });
  }

  const getFile = (id) => {
    return axios.get(`${ConstantAPI.API_URL_DOCUMENT}/${id}`, { responseType: 'blob' });
  }

  const downloadFile = (id) => {
    return axios.get(`${ConstantAPI.API_URL_DOWNLOAD_FILE}/${id}`, { responseType: 'blob' });
  }

  const getTag = (id) => {
    return axios.get(`${ConstantAPI.API_URL_TAG}/${id}`);
  }

  const submitUpdateTag = (params) => {
    return axios.post(ConstantAPI.API_URL_TAG, params);
  }

  const submitChangeOwner = (params) => {
    return axios.put(ConstantAPI.API_URL_CHANGE_OWNER, params);
  }

  const checkUserIsExisted = (params) => {
    return axios.get(ConstantAPI.API_URL_CHECK_USER_IS_EXISTED + params);
  }

  const suggestExistingUser = (keyword) => {
    return axios.get(`${ConstantAPI.API_URL_SEARCH_USER}?keyword=${keyword}`, { silentLoading: true });
  }

  const submitCreateOCR = (id) => {
    return axios.post(ConstantAPI.API_URL_DOCUMENT_OCR + id);
  }

  const getOCR = (id, pageNumber) => {
    return axios.get(`${ConstantAPI.API_URL_DOCUMENT_OCR + id}?pageNumber=${pageNumber}`);
  }

  const submitApproveOCR = (id) => {
    return axios.put(ConstantAPI.API_URL_APPROVE_OCR + id);
  }

  const submitCancelOCR = (id) => {
    return axios.put(ConstantAPI.API_URL_CANCEL_OCR + id);
  }

  const submitUpdateOCR = (data, params) => {
    return axios.put(`${ConstantAPI.API_URL_UPDATE_OCR}?${params}`, data);
  }

  const getViewEditOCR = (id, pageNumber) => {
    return axios.get(`${ConstantAPI.API_URL_VIEW_EDIT_OCR + id}?pageNumber=${pageNumber}`);
  }

  const getPermission = (id) => {
    return axios.get(`${ConstantAPI.API_URL_PERMISSION}/${id}`);
  }

  const createPermission = (id, params) => {
    return axios.post(`${ConstantAPI.API_URL_PERMISSION}/${id}`, params);
  }

  const updatePermission = (params) => {
    return axios.put(ConstantAPI.API_URL_PERMISSION, params);
  }

  const deletePermission = (id) => {
    return axios.delete(`${ConstantAPI.API_URL_PERMISSION}/${id}`);
  }

  const submitSearch = (params) => {
    return axios.get(ConstantAPI.API_URL_SEARCH, params);
  }

  const cancelRequestOCR = (id, data) => {
    return axios.put(ConstantAPI.API_URL_CANCEL_REQUEST_OCR + id, data);
  }

  const getNotification = () => {
    return axios.get(ConstantAPI.API_URL_NOTI_LIST, { silentLoading: true });
  }

  const submitSeen = (id) => {
    return axios.put(ConstantAPI.API_URL_NOTI_SEEN + id);
  }

  const submitSeenAll = () => {
    return axios.put(ConstantAPI.API_URL_NOTI_SEEN_ALL);
  }

  const getDocumentInfo = (id) => {
    return axios.get(ConstantAPI.API_URL_DOCUMENT_INFO + id);
  }

  useEffect(() => autorun(() => {
    try {
      async function getDataRecent() {
        const data = await axios.get(ConstantAPI.API_URL_HOMEPAGE_RECENT_LIST);
        if (data) {
          setrecentData(data);
        }

        searchStore.setTitle('');
        setTitle('Trang chủ')
      }

      async function getDataSearch() {
        if (searchStore.afterSearch) {
          let sort = {
            sortBy: viewStore.sortBy,
            sortOrder: viewStore.sortOrder,
          }
  
          const afterResult = await searchStore.handleSubmitSearch(searchStore.getKeyWord, sort);
          if (afterResult && afterResult.success) {
            viewStore.setIsShowBreadcrumb(true);
          }
        }

        setFolderData(searchStore.resultSearch);
        setTitle(searchStore.getTitle);
      }

      async function getDataInfoFile() {
        let data = await getDocumentInfo(params.id);
        if (data) {
          fileActionStore.setInfo(data);

          if (toLowerCase(data.extension) === 'pdf') {
            fileActionStore.setShowModal('PDF', true);
            fileActionStore.handleShowFilePDF(data.id);
          } else if (isImageExt(data.extension)) {
            fileActionStore.setShowModal('Image', true);
            fileActionStore.handleShowFileImage(data.id);
          } else {
            fileActionStore.setShowModal('Image', true);
          }

          viewStore.setIsShowViewFile(false);
        }
      }

      if (axios) {
        fileActionStore.setSubmitRenameFunction(submitRename);
        fileActionStore.setSubmitMoveFunction(submitMove);
        fileActionStore.setSubmitDeleteFunction(submitDelete);
        fileActionStore.setSubmitApproveFunction(submitApprove);
        fileActionStore.setGetFolderTreeFunction(getFolderTree);
        fileActionStore.setGetFileFunction(getFile);
        fileActionStore.setDownloadFileFunction(downloadFile);
        fileActionStore.setGetDetailFunction(getDetailDoc);
        fileActionStore.setGetViewLogFunction(getLog);
        fileActionStore.setGetTagFunction(getTag);
        fileActionStore.setSubmitUpdateTagFunction(submitUpdateTag);
        fileActionStore.setSubmitChangeOwnerFunction(submitChangeOwner);
        fileActionStore.setCheckUserIsExistedFunction(checkUserIsExisted);
        fileActionStore.setSuggestExistingUserFunction(suggestExistingUser);
        fileActionStore.setGetPermissionFunction(getPermission);
        fileActionStore.setSubmitCreatePermissionFunction(createPermission);
        fileActionStore.setSubmitUpdatePermissionFunction(updatePermission);
        fileActionStore.setSubmitDeletePermissionFunction(deletePermission);
        fileActionStore.setGetDocumentInfoFunction(getDocumentInfo);

        OCRStore.setSubmitCreateOCRFunction(submitCreateOCR);
        OCRStore.setGetOCRFunction(getOCR);
        OCRStore.setSubmitApproveOCRFunction(submitApproveOCR);
        OCRStore.setSubmitCancelOCRFunction(submitCancelOCR);
        OCRStore.setSubmitUpdateOCRFunction(submitUpdateOCR);
        OCRStore.setGetViewEditOCRFunction(getViewEditOCR);
        OCRStore.setSubmitCancelRequestOCRFunction(cancelRequestOCR);

        notificationStore.setGetNotificationFunction(getNotification);
        notificationStore.setSeenNotificationFunction(submitSeen);
        notificationStore.setSeenAllNotificationFunction(submitSeenAll);

        searchStore.setSubmitSearchFunction(submitSearch);

        if (viewStore.refreshList && !searchStore.isSearch) {
          getDataRecent();
        }

        if (viewStore.refreshList && searchStore.isSearch) {
          getDataSearch();
        }

        if (viewStore.refreshList && viewStore.isShowViewFile && params.id) {
          getDataInfoFile();
        }
      }
    } catch (error) {
      console.log('Có lỗi xảy ra khi lấy dữ liệu trang chủ: ', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [axios]);

  useEffect(() => {
    viewStore.setIsShowViewFile(true);
    viewStore.enableRefreshList();

    searchStore.setFolderTypeRoot(null);
    searchStore.setFolderId(null);

    return () => {
      fileActionStore.closeAllModals();
      searchStore.setIsSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="nk-fmg-body">
        <div className="nk-fmg-body-head d-lg-flex">
          <SearchInput></SearchInput>
        </div>
        <div className="nk-fmg-body-content">
          <Title
            pageName={title}
            multiViewMode={searchStore.isSearch}
          >
          </Title>
          {searchStore.isSearch ? (
            <div className="nk-fmg-listing nk-block">
              {viewStore.mode === 'grid' ? (
                <ViewGridMode data={folderData}></ViewGridMode>
              ) : (
                <ViewListMode data={folderData}></ViewListMode>
              )}
            </div>
          ) : (
            <div>
              <QuickAccessList data={PAGE_LIST}></QuickAccessList>
              <RecentList data={recentData}></RecentList>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
});

export default HomeList;
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Draggable from "react-draggable";
import { SplitPane } from "react-collapse-pane";

import SVGIcon from '../SVGIcon';
import { useStore } from '../../stores';
import {
  clearSelection,
  parseLocation,
  // checkConfidenceScore,
  moveCursorToEndInput,
  calculateFontSize,
  calcScaleLocation,
  calcScale,
  calcScaleData
} from '../../utils/functions';
import { formatNameFileFolder, BASE_64 } from '../../utils/formatters';
import { NULL_CHARACTER } from '../../constants/ocr';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  modalWrapper: {
    width: '1000px',
    height: '630px',
    marginBottom: '20px',
    position: 'absolute',
    top: '20px',
    borderRadius: '5px',
    '&:hover': {
      '& $pageControls': {
        opacity: 1
      }
    }
  },
  modalContent: {
    position: 'relative',
    borderRadius: 0,
    minWidth: '97vw'
  },
  fileDetail: {
    height: '83vh',
    display: 'flex',
    flexDirection: 'row'
  },
  pageControls: {
    position: 'absolute',
    bottom: '17.7%',
    left: '50%',
    background: '#fff',
    opacity: 0.6,
    transform: 'translateX(-50%)',
    transition: 'opacity ease-in-out 0.2s',
    boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
    borderRadius: '4px',
  },
  pageControlsButton: {
    width: '36px',
    height: '36px',
    background: '#fff',
    border: 0,
    font: 'inherit',
    fontSize: '1.2em',
    borderRadius: '4px',
    '&:enabled&:hover': {
      backgroundColor: '#e6e6e6'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  pageControlsSpan: {
    font: 'inherit',
    fontSize: '1.2em',
    padding: '0 0.5em',
  },
  popper: {
    zIndex: 1,
    borderRadius: '6px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
    '&[x-placement*="bottom"]': {
      '& .arrow': {
        width: 0,
        height: 0,
        borderLeft: '1em solid transparent',
        borderRight: '1em solid transparent',
        borderBottom: '1em solid #fff',
        marginTop: '-0.9em',
        '&:before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: 'transparent transparent white transparent',
        }
      }
    },
    '&[x-placement*="top"]': {
      '& .arrow': {
        bottom: 0,
        width: 0,
        height: 0,
        borderLeft: '1em solid transparent',
        borderRight: '1em solid transparent',
        borderTop: '1em solid #fff',
        marginBottom: '-0.9em',
        '&:before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: 'white transparent transparent transparent',
        }
      }
    },
    '&[x-placement*="right"]': {
      '& .arrow': {
        left: 0,
        width: 0,
        height: 0,
        borderTop: '1em solid transparent',
        borderBottom: '1em solid transparent',
        borderRight: '1em solid #fff',
        marginLeft: '-0.9em',
        '&: before': {
          borderwidth: '1em 1em 1em 0',
          bordercolor: 'transparent white transparent transparent',
        }
      }
    },
    '&[x-placement*="left"]': {
      '& .arrow': {
        right: 0,
        width: 0,
        height: 0,
        borderTop: '1em solid transparent',
        borderBottom: '1em solid transparent',
        borderLeft: '1em solid #fff',
        marginRight: '-0.9em',
        '&:before': {
          borderwidth: '1em 0 1em 1em',
          bordercolor: 'transparent transparent transparent white',
        }
      }
    },
    '& .arrow': {
      position: 'absolute',
      fontSize: '9px',
      width: '3em',
      height: '3em'
    },
    '& .popper-title': {
      padding: '8px 14px',
      margin: 0,
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '18px',
      backgroundColor: '#f7f7f7',
      borderBottom: '1px solid #ebebeb',
      borderRadius: '5px 5px 0 0'
    },
    '& .popper-content': {
      width: '450px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: '0 0 5px 5px',
      color: 'white'
    },
    '& .popper-input': {
      padding: '6px 12px',
      fontSize: '14px',
      color: '#555555',
      lineHeight: '30px',
      verticalAlign: 'middle',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      bordeRadius: '4px'
    }
  },
  contentLeft: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  contentRight: {
    position: 'relative',
    overflow: 'auto',
    width: '100%',
    height: '100%'
  },
  customFooter: {
    '& label.MuiFormControlLabel-root': {
      marginBottom: 'unset'
    },
    '& .g-3>li, .g-3>div': {
      paddingBottom: '0.1rem !important'
    }
  }
}));

const TARGET_WIDTH = 990;

const EditOCR = observer((props) => {
  const { fileActionStore, OCRStore, viewStore, searchStore } = useStore();
  const [dataOCR, setDataOCR] = useState({});
  const [dataOCROriginal, setDataOCROriginal] = useState({});
  const [currentEdit, setCurrentEdit] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDisablePrev, setIsDisablePrev] = useState(true);
  const [isDisableNext, setIsDisableNext] = useState(true);
  const classes = useStyles();
  const openModal = fileActionStore.isShowEditOCR;
  const [inputEdit, setInputEdit] = useState('');
  const [anchorElement, setAnchorElement] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [isOpenPopper, setIsOpenPopper] = useState(false);
  const [isMoveCursorToEnd, setIsMoveCursorToEnd] = useState(true);
  const [isEdited, setIsEdited] = useState(false);
  const [bgImage, setBgImage] = useState(null);
  const [originalWidthImg, setOriginalWidthImg] = useState(null);
  const [currentWidthImg, setCurrentWidthImg] = useState(TARGET_WIDTH);
  const [currentHeightImg, setCurrentHeightImg] = useState(null);

  const handleEditPopper = (event, key)  => {
    clearSelection();
    const indexArr = +key.replace(/[^0-9]/g, '');
    const data = dataOCR?.blocks[indexArr];
    let text = data && (data.text_update || data.text);
    if (text === NULL_CHARACTER) text = '';
    setCurrentEdit(key);
    setInputEdit(text);
    setAnchorElement(event.currentTarget);
    setIsOpenPopper(true);
    setIsMoveCursorToEnd(true);
  }

  const handleCloseEditPopper = (e) => {
    e.preventDefault();
    setCurrentEdit(null);
    setInputEdit('');
    setAnchorElement(null);
    setIsOpenPopper(false);
  }

  const handleArrowRef = node => {
    setArrowRef(node);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setShowModal('EditOCR', false);
    setNumPages(null);
    setPageNumber(1);
    setIsDisablePrev(true);
    setIsDisableNext(true);
  };

  const getOCR = async (page) => {
    page = page || pageNumber;
    let response = await OCRStore.getViewEditOCR(fileActionStore.info.id, page);
    if (response.success) {
      const data = response.data.ocr;
      data.blocks = data.blocks && JSON.parse(data.blocks);

      setDataOCR(data);
      setDataOCROriginal(JSON.parse(JSON.stringify(data)));
      setNumPages(response.data.totalPage);

      if (response.data.ocr.inputImage) {
        const imgUrl = BASE_64 + response.data.ocr.inputImage;
        getOriginalWidthImg(imgUrl);
        setBgImage(imgUrl);
      } else {
        getImage();
      }
    }
  }

  const getImage = async () => {
    await fileActionStore.handleShowFileImage(fileActionStore.info.id, false);
    setBgImage(fileActionStore.fileImageUrl);
    getOriginalWidthImg(fileActionStore.fileImageUrl);
  }

  const getOriginalWidthImg = async (imgUrl) => {
    let img = new Image();
    img.src = imgUrl;
    img.onload = function() {
      setCurrentHeightImg(img.height);
      setOriginalWidthImg(img.width);
    };
  }

  const handleUpdateBlock = (data) => {
    data.text_update = inputEdit || NULL_CHARACTER;
    data.isDeleted = !inputEdit;
    data.score_update = [1];
    data.isUpdated = true;
  }

  const handleUpdate = (e) => {
    const indexArr = +currentEdit.replace(/[^0-9]/g, '');
    const data = dataOCR?.blocks[indexArr];
    const dataOriginal = dataOCROriginal?.blocks[indexArr];

    handleUpdateBlock(data);
    handleUpdateBlock(dataOriginal);

    setIsEdited(true);
    setDataOCR(dataOCR);
    setDataOCROriginal(dataOCROriginal);
    handleCloseEditPopper(e);
  }

  const handleSubmitUpdate = async (e, type = 'sendNoti') => {
    let data = {
      ...dataOCROriginal,
      blocks: JSON.stringify(dataOCROriginal.blocks)
    }

    let params = {
      sendRequest: type === 'sendNoti',
      isUpdate: isEdited
    }

    const resultSubmit = await OCRStore.handleSubmitUpdateOCRFile(data, params);

    if (resultSubmit && resultSubmit.success) {
      if (searchStore.isSearch) searchStore.setAfterSearch(true)
      handleCloseModal(e);
      viewStore.enableRefreshList();
    }
  }

  const handleGoPrevious = (e) => {
    if (pageNumber > 1) {
      clearData();
      handleCloseEditPopper(e);

      let page = pageNumber - 1;
      setPageNumber(page);
      getOCR(page);
    }
  }

  const handleGoNext = (e) => {
    if (pageNumber < numPages) {
      clearData();
      handleCloseEditPopper(e);

      let page = pageNumber + 1;
      setPageNumber(page);
      getOCR(page);
    }
  }

  const checkDisabled = () => {
    if (pageNumber === 1) {
      setIsDisablePrev(true);
    } else {
      setIsDisablePrev(false);
    }

    if (pageNumber === numPages) {
      setIsDisableNext(true);
    } else {
      setIsDisableNext(false);
    }
  }

  const renderHTML = () => {
    const data = [];
    const blocks = dataOCR.blocks;

    if (Array.isArray(blocks) && blocks.length) {
      blocks.forEach((lineData, index) => {
        // const score = lineData.isUpdated ? lineData.score_update : lineData.score;
        const textBox = lineData.isUpdated ? lineData.text_update : lineData.text;

        // const isCheckedConfidence = checkConfidenceScore(score);
        let scaleLocation = lineData.bounding_box;
        if (!dataOCR.isScaled) {
          let result = calcScale(originalWidthImg, TARGET_WIDTH);
          let scaleHeight = calcScaleData(currentHeightImg, result.scale);

          setCurrentWidthImg(result.currentWidth);
          setCurrentHeightImg(scaleHeight);

          scaleLocation = calcScaleLocation(lineData.bounding_box, result.scale);
        }
        const location = parseLocation(scaleLocation);
        const fontSize = calculateFontSize(location);
        const key = 'line-' + index;

        data.push(
          <div
            key={'line-' + index}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              left: location.left,
              top: location.top,
              border: '1px solid green',
              minWidth: location.width,
              height: location.height,
              background: 'white',
              fontSize: fontSize.fontSize
            }}
            onClick={event => handleEditPopper(event, key)}
          >
            {/* {!lineData.isDeleted ?
              (
                <svg
                  width={`${location.width}`}
                  height={`${location.height}`}
                  viewBox={`0 0 ${location.width} ${location.height}`}
                  preserveAspectRatio="xMinYMid meet"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <text
                    x="2"
                    y={fontSize.y}
                    fontSize={fontSize.fontSize}
                    fill="black"
                  >
                    {textBox}
                  </text>
                </svg>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: textBox }} />
              )
            } */}
            <div dangerouslySetInnerHTML={{ __html: textBox }} />
          </div>
        );
      })
    }

    dataOCR.isScaled = true;

    if (dataOCR.vimcImage && dataOCR.vimcImage.length) {
      data.push(
        <div
          key='vimc-image'
          style={{
            cursor: 'default',
            position: 'absolute',
          }}
        >
          <img
            src={BASE_64 + dataOCR.vimcImage}
            alt="a"
          />
        </div>
      );
    }

    return data;
  }

  const clearData = () => {
    setDataOCR({});
    setDataOCROriginal({});
    setIsEdited(false);
    setOriginalWidthImg(null);
    setCurrentWidthImg(TARGET_WIDTH);
    setCurrentHeightImg(null);
    setBgImage(null);
  }

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (openModal) {
      checkDisabled();
      getOCR();
    }

    return () => {
      clearData();
      setIsOpenPopper(false);
    }
    // eslint-disable-next-line
  }, [openModal])

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div className={classes.modalWrapper} tabIndex="-1" role="dialog" id="edit-ocr" aria-modal="true">
                <div className="modal-dialog modal-md" role="document" style={{ maxWidth: '100%' }}>
                  <div className={`modal-content ${classes.modalContent}`}>
                    <div className="modal-header align-center">
                      <div className="nk-file-title">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <SVGIcon ext={fileActionStore.info.extension || 'folder'} hardMode="list"></SVGIcon>
                          </span>
                        </div>
                        <div className="nk-file-name">
                          <div className="nk-file-name-text">
                            <span className="title">
                              {formatNameFileFolder(fileActionStore.info)} {'>> Chỉnh lý OCR'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <a href="# " className="close" onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className={`nk-file-details ${classes.fileDetail}`} style={{ position: 'relative' }}>
                      {originalWidthImg ? (
                        <SplitPane split="vertical">
                          <div
                            className={`${classes.contentLeft}`}
                            style={{
                              minWidth: 1400,
                              backgroundImage: `url('${bgImage}')`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: `${currentWidthImg}px auto`,
                              height: currentHeightImg
                            }}
                          >
                            {anchorElement && (
                              <Popper
                                className={classes.popper}
                                placement="top"
                                open={isOpenPopper}
                                anchorEl={anchorElement}
                                disablePortal={true}
                                modifiers={{
                                  flip: {
                                    enabled: true,
                                  },
                                  arrow: {
                                    enabled: true,
                                    element: arrowRef,
                                  },
                                  preventOverflow: {
                                    enabled: "true",
                                    boundariesElement: 'scrollParent'
                                  },
                                }}
                              >
                                <ClickAwayListener onClickAway={handleCloseEditPopper}>
                                  <div>
                                    <span className="arrow" ref={handleArrowRef} />
                                    <h3 className="popper-title">Cập nhật</h3>
                                    <div className="popper-content">
                                      <textarea
                                        type="text"
                                        className="input-mail tagify popper-input"
                                        style={{ padding: '8px' }}
                                        placeholder="Vui lòng nhập nội dung"
                                        value={inputEdit}
                                        ref={el => { if (el && isMoveCursorToEnd) moveCursorToEndInput(el); }}
                                        onInput={e => { setInputEdit(e.target.value); setIsMoveCursorToEnd(false); }}
                                      />
                                      <div style={{ marginTop: '12px' }}>
                                        <ul className="btn-toolbar g-3 mt-3">
                                          <li>
                                            <button className="btn btn-sm btn-primary" onClick={handleUpdate}>
                                              <em className="icon ni ni-check-thick"></em>
                                            </button>
                                          </li>
                                          <li ref={(el) => el && el.style.setProperty('padding-left', '0.1rem', 'important')}>
                                            <button className="btn btn-sm btn-outline-light btn-white" onClick={handleCloseEditPopper}>
                                              <em className="icon ni ni-cross" style={{ fontWeight: 'bold' }}></em>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </ClickAwayListener>
                              </Popper>
                            )}
                            {renderHTML()}
                          </div>
                          <div className={classes.contentRight}>
                            <img src={bgImage ? bgImage : ''} alt={fileActionStore.info.displayName}></img>
                          </div>
                        </SplitPane>
                      ) : (
                        <div
                          className={`${classes.contentLeft}`}
                          style={{
                            width: TARGET_WIDTH
                          }}
                        >
                        </div>
                      )}
                    </div>
                    <div style={{ position: 'relative' }} className="flexFooter">
                      {numPages && (
                        <div className={classes.pageControls} id="rePageControls">
                          <button
                            type="button"
                            disabled={isDisablePrev}
                            className={classes.pageControlsButton}
                            onClick={handleGoPrevious}
                          >‹</button>
                          <span className={classes.pageControlsSpan}>{pageNumber} of {numPages}</span>
                          <button
                            type="button"
                            disabled={isDisableNext}
                            className={classes.pageControlsButton}
                            onClick={handleGoNext}
                          >›</button>
                        </div>
                      )}
                      <div className="modal-footer modal-footer-stretch bg-light">
                        <div className="modal-footer-between" style={{ justifyContent: 'flex-end' }}>
                          <div className={`g ${classes.customFooter}`}>
                            <ul className="btn-toolbar g-3">                           
                              <li>
                                <a href="# " className="btn btn-outline-light btn-white" onClick={handleCloseModal}> Bỏ qua </a>
                              </li>
                              <li>
                                <button
                                  className="btn btn-primary"
                                  onClick={e => handleSubmitUpdate(e, 'noSendNoti')}
                                  disabled={!isEdited}
                                >
                                  Lưu tạm
                                </button>
                              </li>
                              <li>
                                <button className="btn btn-primary" onClick={handleSubmitUpdate}> Gửi yêu cầu duyệt chỉnh lý </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
})

export default EditOCR;
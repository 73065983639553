import React from 'react';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Draggable from "react-draggable";

import SVGIcon from '../SVGIcon';
import { useStore } from '../../stores';
import { isViewedFileFolder, getFolderIcon } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250,
  },
  listIcon: {
    margin: '8px 16px',
    float: 'right',
    cursor: 'pointer',
    "&:hover": {
      background: '#c4c4c4',
      borderRadius: '12px'  
    }
  },
  listSubheaderItem: {
    padding: '0 16px'
  },
  listItem: {
    width: '100%',
    padding: '8px 16px'
  },
  listItemText: {
    display: 'inline-block',
    marginLeft: '10px'
  },
  subheader: {
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }
}));

const MoveFile = observer(() => {
  const { viewStore, fileActionStore, searchStore } = useStore();
  const [folderDesId, setFolderDesId] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);
  const classes = useStyles();

  const info = fileActionStore.info;
  const modalTitle = 'Di chuyển ' + (info.type ? 'file' : 'folder');
  const openModal = fileActionStore.isShowMove;
  const ERROR_NOT_PERMISSION = 'Bạn không có quyền di chuyển vào folder này';

  const getFolderTree = async (parentId) => {
    const result = await fileActionStore.getFolderTree(info.id, parentId);

    if (result && fileActionStore.currentFolderTree) {
      const breadcrumb = fileActionStore.currentFolderTree.breadCrumb;
      setCurrentFolder(breadcrumb[breadcrumb.length - 1]);
    }
  }

  const handleCloseModal = (e) => {
    e.preventDefault();

    fileActionStore.setShowModal('Move', false);
    fileActionStore.clearInfo();
    fileActionStore.clearFolderTree();
    setFolderDesId(null);
  };

  const handleClickSubmit = async (e) => {
    const resultSubmit = await fileActionStore.handleSubmitMove(folderDesId);

    if (resultSubmit && resultSubmit.success) {
      if (searchStore.isSearch) searchStore.setAfterSearch(true)
      handleCloseModal(e);
      viewStore.enableRefreshList();
    }
  }

  const handleListItemClick = (folder) => {
    let id = folder.id;

    // Trường hợp thư mục gốc
    if (!folder.parentId) {
      setFolderDesId(id);
      return
    }

    const permission = folder.permission1;
    if (!isViewedFileFolder(permission, ERROR_NOT_PERMISSION)) {
      id = null;
    }
    setFolderDesId(id);
  };

  const handleUpdateFolderTree = (folder, type = 'getParent') => {
    setFolderDesId(null);
    let id = folder.folderParentId;
    if (type !== 'getParent') {
      id = folder.id;
      const permission = folder.permission1;
      if (!isViewedFileFolder(permission, ERROR_NOT_PERMISSION)) return;
    }
    getFolderTree(id);
  }

  useEffect(() => {
    if (openModal && info) {
      getFolderTree(info.parentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, info]);


  const renderSubheader = () => {
    if (fileActionStore.currentFolderTree && currentFolder) {
      if (fileActionStore.currentFolderTree.folderParentId) {
        return (
          <ListItem className={classes.listSubheaderItem}>
            <Tooltip title="Nhấn vào đây để trở về thư mục cha">
              <span
                className={classes.subheader}
                onClick={() => handleUpdateFolderTree(fileActionStore.currentFolderTree)}
              >
                <em className="icon ni ni-arrow-left"></em> {currentFolder.name}
              </span>
            </Tooltip>
          </ListItem>
        )
      } else {
        return (
          <ListItem
            button
            className={classes.listSubheaderItem}
            key={currentFolder.id}
            selected={folderDesId === currentFolder.id}
            onClick={() => handleListItemClick(currentFolder)}
          >
            <Tooltip title="Nhấn vào đây để chọn thư mục gốc">
              <span className={classes.subheader}>
                {currentFolder.name}
              </span>
            </Tooltip>
          </ListItem>
        )
      }
    }

    return null;
  }

  const renderFileItem = () => {
    if (fileActionStore.currentFolderTree && fileActionStore.currentFolderTree.data) {
      if (fileActionStore.currentFolderTree.data.length) {
        return fileActionStore.currentFolderTree.data.map(folder => (
          <ListItem
            button
            style={{ padding: 0 }}
            key={folder.id}
            selected={folderDesId === folder.id}
          >
            <span
              className={classes.listItem}
              onClick={() => handleListItemClick(folder)}
            >
              <span className="nk-file-icon-type">
                <SVGIcon ext={getFolderIcon(folder)}></SVGIcon>
              </span>
              <ListItemText key={folder.id} className={classes.listItemText}>
                {folder.displayName}
              </ListItemText>
            </span>
            <span
              className={classes.listIcon}
              onClick={() => handleUpdateFolderTree(folder, 'folder')}
            >
              <em className="icon ni ni-arrow-right" style={{ padding: '5px' }}></em>
            </span>
          </ListItem>
        ));
      } else {
        return (
          <ListItem>
            <ListItemText primary="Không có thư mục"></ListItemText>
          </ListItem>
        );
      }
    }

    return (<ListItem></ListItem>);
  }

  const renderBreadcrumb = () => {
    const breadcrumb = [null, null, null];

    if (fileActionStore.currentFolderTree && fileActionStore.currentFolderTree.breadCrumb) {
      breadcrumb[0] = fileActionStore.currentFolderTree.breadCrumb.map(folder => (
        <li key={folder.id} className="breadcrumb-item">{folder.name}</li>
      ));
    }

    if (fileActionStore.currentFolderTree && fileActionStore.currentFolderTree.data && folderDesId) {
      if (folderDesId !== fileActionStore.currentFolderTree.folderId) {
        breadcrumb[1] = (
          <li key={folderDesId} className="breadcrumb-item">
            {fileActionStore.currentFolderTree.data.find(folder => folder.id === folderDesId)?.displayName}
          </li>
        );
      }
    } else {
      breadcrumb[1] = (<li key={0} className="breadcrumb-item">...</li>);
    }

    breadcrumb[2] = (
      <li key={1} className="breadcrumb-item">
        {info.extension ? info.displayName + '.' + info.extension : info.displayName}
      </li>
    );

    return breadcrumb;
  }

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
         <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="file-copy" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <h5 className="modal-title">{modalTitle}</h5>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-0 mt-n2">
                      <div
                        className="nk-file-share-input nk-file-share-input-to"
                        style={{ border: '1px solid #ccc', marginTop: '10px' }}
                      >
                        <div className={classes.root}>
                          <List
                            component="nav"
                            aria-label="main mailbox folders"
                            subheader={
                              <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                                style={{ padding: 0 }}
                              >
                                {renderSubheader()}
                              </ListSubheader>
                            }
                          >
                          </List>
                          <Divider />
                          <List
                            component="nav"
                            aria-label="secondary mailbox folder"
                            className={classes.list}
                          >
                            {renderFileItem()}
                          </List>
                        </div>
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <div style={{ fontWeight: 'bold' }}>Xem trước đường dẫn</div>
                        <ul className="breadcrumb breadcrumb-sm breadcrumb-alt breadcrumb-arrow">
                          {[...renderBreadcrumb()]}
                        </ul>
                      </div>
                      <div className="nk-modal-action justify-end">
                        <ul className="btn-toolbar g-4 align-center">
                          <li>
                            <a href="# " className="link link-primary" onClick={handleCloseModal}>Bỏ qua</a>
                          </li>
                          <li>
                            <button className="btn btn-primary" onClick={handleClickSubmit} disabled={!folderDesId}>Di chuyển</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
});

export default MoveFile;
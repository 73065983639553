import { makeAutoObservable } from "mobx";
import { toast } from 'react-toastify';
import axios from 'axios';

class UploadStore {
  folderId = '';
  listFileSelected = [];
  listFileUploaded = [];
  cancelTokenSources = {};

  getUploadKeyFunction = null;
  sendFileFunction = null;
  submitFileFunction = null;

  constructor() {
    makeAutoObservable(this);
  }

  setFolderId(id) {
    this.folderId = id;
  }

  setGetUploadKeyFunction(f) {
    this.getUploadKeyFunction = f;
  }

  setSendFileFunction(f) {
    this.sendFileFunction = f;
  }

  setSubmitFileFunction(f) {
    this.submitFileFunction = f;
  }

  updateSelectedFile(file) {
    this.listFileSelected = [...this.listFileSelected, ...[file]];
  }

  updateUploadedFile(fileId) {
    this.listFileUploaded = [...this.listFileUploaded, fileId];
  }

  deleteUploadedFile(index) {
    this.listFileSelected.splice(index, 1);
    this.listFileUploaded.splice(index, 1);
  }

  clearFiles() {
    this.listFileSelected = [];
    this.listFileUploaded = [];
  }

  addCancelTokenSource(index, tokenSource) {
    this.cancelTokenSources[index] = tokenSource;
  }

  cancelTokenSource(index) {
    this.cancelTokenSources[index].cancel();
    this.cancelTokenSources[index] = undefined;
  }

  async handleGetUploadKey(fileName) {
    const params = {
      fileName,
      folderId: this.folderId
    }

    const response = await this.getUploadKeyFunction(params);
    if (response && response.data) {
      return {
        success: true,
        data: response.data
      };
    } else {
      return { success: false };
    }
  }

  handleUploadFile(formData, onUploadProgress, index) {
    const cancelTokenSource = axios.CancelToken.source();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
      silentLoading: true,
      cancelToken: cancelTokenSource.token
    }

    this.addCancelTokenSource(index, cancelTokenSource);

    return this.sendFileFunction(formData, config);
  }

  async handleSubmitFile() {
    if (!this.listFileUploaded || !this.listFileUploaded.length) {
      toast.error('Vui lòng chọn file');
      return { success: false };
    }

    const params = {
      folderId: this.folderId,
      fileIds: this.listFileUploaded
    }

    const response = await this.submitFileFunction(params);
    if (response) {
      this.clearFiles();
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }
}

export default UploadStore;
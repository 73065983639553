import React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Draggable from "react-draggable";

import UploadFile from './upload/UploadFile';
import ListFile from './upload/ListFile';
import { useStore } from '../stores';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const ActionButtons = observer((props) => {
  const {
    showCreateFolder,
    buttonCreateTitle,
    inputCreatePlaceholder,
    showUpload,
    onCreateFolder
  } = props;

  const classes = useStyles();
  const [inputFolder, setInputFolder] = useState('');
  const { viewStore, fileActionStore, uploadStore } = useStore();
  const [isDisableBtnCreateFolder, setIsDisableBtnCreateFolder] = useState(true);

  const openModalCreateFolder = fileActionStore.isShowCreateFolder;
  const openModalUploadFile = fileActionStore.isShowUploadFile;

  const handleOpenCreateFolder = (e) => {
    e.preventDefault();

    fileActionStore.setShowModal('CreateFolder', true);
    setInputFolder('');
    checkDisableBtn();
  };

  const handleCloseCreateFolder = (e) => {
    e.preventDefault();

    fileActionStore.setShowModal('CreateFolder', false);
  };

  const handleCreateFolder = (e) => {
    onCreateFolder(inputFolder).then((response) => {
      toast.success(response.message);
      setInputFolder('');
      handleCloseCreateFolder(e);
    })
  }

  const handleOpenUploadFile = (e) => {
    e.preventDefault();

    uploadStore.clearFiles();
    fileActionStore.setShowModal('UploadFile', true);
  };

  const handleCloseUploadFile = (e) => {
    e.preventDefault();

    uploadStore.clearFiles();
    fileActionStore.setShowModal('UploadFile', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isDisableBtnCreateFolder) {
      handleCreateFolder(event);
    }
  }

  const checkDisableBtn = (value = null) => {
    if (!value) {
      setIsDisableBtnCreateFolder(true);
      return;
    }
    value = value.trim();
    setIsDisableBtnCreateFolder(!value.length);
  }

  const handleClickSubmit = async (e) => {
    const resultSubmit = await uploadStore.handleSubmitFile();

    if (resultSubmit && resultSubmit.success) {
      viewStore.enableRefreshList();
      handleCloseUploadFile(e);
    }
  }

  return (
    <div className="nk-fmg-actions">
      <ul className="nk-block-tools g-3">
        {showCreateFolder &&
          <li>
            <a
              href="# "
              className="btn btn-light"
              data-toggle="modal"
              onClick={handleOpenCreateFolder}
            >
              <em className="icon ni ni-plus"></em><span>{buttonCreateTitle}</span>
            </a>
          </li>
        }
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModalCreateFolder}
          onClose={handleCloseCreateFolder}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModalCreateFolder}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="file-copy" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <h5 className="modal-title">{buttonCreateTitle}</h5>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseCreateFolder}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-0 mt-n2">
                      <div className="nk-file-share-input nk-file-share-input-to" style={{ border: '1px solid #ccc', marginTop: '10px' }}>
                        <input
                          type="text"
                          className="input-mail tagify"
                          style={{ padding: '8px' }}
                          placeholder={inputCreatePlaceholder}
                          value={inputFolder}
                          ref={el => { if (el) el.focus(); }}
                          onInput={e => { setInputFolder(e.target.value); checkDisableBtn(e.target.value); }}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <div className="nk-modal-action justify-end">
                        <ul className="btn-toolbar g-4 align-center">
                          <li><a href="# " className="link link-primary" onClick={handleCloseCreateFolder}>Bỏ qua</a></li>
                          <li><button className="btn btn-primary" onClick={handleCreateFolder} disabled={isDisableBtnCreateFolder}> Thêm </button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
        {showUpload &&
          <li>
            <a
              href="# "
              className="btn btn-primary"
              onClick={handleOpenUploadFile}
            >
              <em className="icon ni ni-upload-cloud"></em><span>Upload file</span>
            </a>
          </li>
        }
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModalUploadFile}
          onClose={handleCloseUploadFile}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModalUploadFile}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="upload-file" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <h5 className="modal-title"> Upload File </h5>
                      <a href="# " className="close" onClick={handleCloseUploadFile}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-0">
                      <div className="nk-upload-form">
                        <UploadFile />
                      </div>
                      <ListFile />
                      <div className="nk-modal-action justify-end">
                        <ul className="btn-toolbar g-4 align-center">
                          <li><a href="# " className="link link-primary" onClick={handleCloseUploadFile}>Bỏ qua</a></li>
                          <li>
                            <button
                              className="btn btn-primary"
                              onClick={handleClickSubmit}
                              disabled={!uploadStore.listFileSelected || !uploadStore.listFileSelected.length}
                            >
                              Thêm file
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
});

ActionButtons.propTypes = {
  showCreateFolder: PropTypes.bool,
  buttonCreateTitle: PropTypes.string,
  inputCreatePlaceholder: PropTypes.string,
  showUpload: PropTypes.bool,
  onCreateFolder: PropTypes.func
};

ActionButtons.defaultProps = {
  showCreateFolder: true,
  buttonCreateTitle: 'Tạo folder',
  inputCreatePlaceholder: 'Nhập tên folder',
  showUpload: true,
  onCreateFolder: null
}

export default ActionButtons;
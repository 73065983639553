import React from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Draggable from "react-draggable";

import { useStore } from '../../stores';
import { formatNameFileFolder } from '../../utils/formatters';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const ApproveFile = observer(() => {
  const { viewStore, fileActionStore, searchStore } = useStore();
  const classes = useStyles();

  const info = fileActionStore.info;
  const modalTitle = `Duyệt ${info.type ? 'file' : 'folder'} mới`;
  const openModal = fileActionStore.isShowApprove;

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setShowModal('Approve', false);
  };

  const handleClickSubmit = async (e) => {
    handleCloseModal(e);

    const resultSubmit = await fileActionStore.handleSubmitApprove();

    if (resultSubmit && resultSubmit.success) {
      if (searchStore.isSearch) searchStore.setAfterSearch(true)
      viewStore.enableRefreshList();
      viewStore.enableRefreshCurrentInfo();
    }
  }

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="file-copy" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <h5 className="modal-title">{modalTitle}</h5>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-0 mt-n2">
                      <span> Bạn muốn duyệt mới {info.type ? 'file' : 'folder'} <b>{formatNameFileFolder(info)}</b>?</span>
                      <div className="nk-modal-action justify-end">
                        <ul className="btn-toolbar g-4 align-center">
                          <li><a href="# " className="link link-primary" onClick={handleCloseModal}>Bỏ qua</a></li>
                          <li><button className="btn btn-primary" onClick={handleClickSubmit}> Đồng ý </button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
})

export default ApproveFile;
import React from 'react';
import PropTypes from 'prop-types';

import QuickAccessFolder from './QuickAccessFolder';

QuickAccessList.propTypes = {
  data: PropTypes.array.isRequired
};

function QuickAccessList(props) {
  const { data } = props;

  return (
    <div className="nk-fmg-quick-list nk-block">
      <div className="nk-block-head-xs">
        <div className="nk-block-between g-2">
          <div className="nk-block-head-content">
            <h6 className="nk-block-title title">Truy cập nhanh</h6>
          </div>
        </div>
      </div>
      <div className="toggle-expand-content expanded" data-content="quick-access">
        <div className="nk-files nk-files-view-grid">
          <div className="nk-files-list">
            {data.map(d => <QuickAccessFolder key={d.id} info={d}></QuickAccessFolder>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickAccessList;
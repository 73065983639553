export const PAGE_LIST = [
  {
    id: 1,
    folderType: 0,
    displayName: 'Tài liệu của tôi',
    uri: '/my-documents'
  },
  {
    id: 2,
    folderType: 1,
    displayName: 'Dự án',
    uri: '/projects'
  },
  {
    id: 3,
    folderType: 2,
    displayName: 'Phòng ban',
    uri: '/departments'
  },
  {
    id: 4,
    displayName: 'Tài liệu chờ duyệt',
    uri: '/awaiting-approval'
  }
]

export const FOLDER_TYPE_ROOT = {
  'MyDocument': 0,
  'Projects': 1,
  'Departments': 2,
  'AwaitingApproval': 3
}

export const TYPE_DOCUMENT = {
  'FOLDER': 0,
  'FILE': 1
}
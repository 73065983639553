import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';

import { useStore } from '../stores';

import File from './File';
import Folder from './Folder';

ViewGridMode.propTypes = {
  data: PropTypes.array.isRequired
};

function ViewGridMode(props) {
  const { data } = props;
  const { viewStore } = useStore();

  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState(viewStore.sortState.setSortBy);

  const classSortBy = viewStore.sortState[sortBy] === 'ASC' ? 'dropdown-indicator-up' : 'dropdown-indicator-down';
  const openSortBy = Boolean(anchorEl);
  const listSortBy = {
    displayName: 'Tên',
    lastViewAt: 'Lần truy cập gần nhất'
  }

  const handleSortBy = (value = null) => {
    value = value || sortBy;
    setSortBy(value);
    viewStore.setSortList(value);
    viewStore.enableRefreshList();
  }

  const handleOpenSortBy = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortBy = () => {
    setAnchorEl(null);
  };

  return (
    <div className="nk-files nk-files-view-grid">
      <div className="nk-files-head">
        <div className="nk-file-item">
          <div className="nk-file-info">
            <span
              className="tb-head dropdown-toggle"
              aria-owns={openSortBy ? 'popover-sort-by' : undefined}
              aria-haspopup="true"
            >
              <span onClick={handleOpenSortBy}>{listSortBy[sortBy]}</span>
              <div className="dropdown-menu dropdown-menu-xs">
                <Popover
                  id="popover-sort-by"
                  open={openSortBy}
                  anchorEl={anchorEl}
                  onClose={handleCloseSortBy}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <ul className="link-list-opt no-bdr">
                  {
                    Object.keys(listSortBy).map(k => (
                      <li key={k} onClick={() => { handleSortBy(k); handleCloseSortBy(); }}>
                        <a href="# " className={k === sortBy ? 'active' : ''}>
                          <span style={{ marginRight: '15px' }}> {listSortBy[k]} </span>
                        </a>
                      </li>
                    ))
                  }
                  </ul>
                </Popover>
              </div>
            </span>
            <span className={classSortBy} style={{ cursor: 'pointer' }} onClick={() => handleSortBy()}></span>
          </div>
        </div>
      </div>
      <div className="nk-files-list">
        {data.map(d => {
          if (d.type) {
            return (
              <File
                key={d.id}
                info={d}
              >
              </File>
            )
          } else {
            return (
              <Folder
                key={d.id}
                info={d}
              >
              </Folder>
            )
          }
        })}
      </div>
    </div>
  );
}

export default ViewGridMode;
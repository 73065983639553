import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../stores';
import ListItem from './ListItem';

const ListFile = observer((props) => {
  const { uploadStore } = useStore();

  const handleClickDeleteFile = (index) => {
    uploadStore.deleteUploadedFile(index);
  }

  return (
    <div>
      <h6 className="title">Các file đã được upload</h6>
      <div className="nk-upload-list" style={{ maxHeight: '370px', overflow: 'auto' }}>
        {uploadStore.listFileSelected.map((file, index) => (
          <ListItem
            key={index}
            name={file.name}
            size={file.size}
            onClickDelete={() => handleClickDeleteFile(index)}
          >
          </ListItem>
        ))}
      </div>
    </div>
  );
});

export default ListFile;
export const PERMISSION_ALL = 'All';
export const PERMISSION_READ = 'Read';

export const FULL_PERMISSIONS = [
  'Read',
  'ApproveFile',
  'ApproveFolder',
  'ViewProperties',
  'EditProperties',
  'Delete',
  'ViewPermission',
  'EditPermission',
  'ChangeOwner',
  'Move'
]

export const DEFINE_PERMISSIONS = {
  'permission0': 'All',
  'permission1': 'Read',
  'permission2': 'ApproveFile',
  'permission3': 'ApproveFolder',
  'permission4': 'ViewProperties',
  'permission5': 'EditProperties',
  'permission6': 'Delete',
  'permission7': 'ViewPermission',
  'permission8': 'EditPermission',
  'permission9': 'ChangeOwner',
  'permission10': 'Move'
}

export const DEFINE_LIST_PERMISSIONS = [
  { id: 0, code: 'All', name: 'Toàn quyền' },
  { id: 1, code: 'Read', name: '1. Đọc folder/Đọc file' },
  { id: 2, code: 'ApproveFile', name: '2. Duyệt file mới/OCR và chỉnh lý' },
  { id: 3, code: 'ApproveFolder', name: '3. Duyệt folder mới/Duyệt chỉnh lý OCR' },
  { id: 4, code: 'ViewProperties', name: '4. Xem thuộc tính' },
  { id: 5, code: 'EditProperties', name: '5. Sửa thuộc tính' },
  { id: 6, code: 'Delete', name: '6. Xóa' },
  { id: 7, code: 'ViewPermission', name: '7. Xem phân quyền' },
  { id: 8, code: 'EditPermission', name: '8. Sửa phân quyền' },
  { id: 9, code: 'ChangeOwner', name: '9. Giật quyền' },
  { id: 10, code: 'Move', name: '10. Di chuyển folder/file' }
]

export const DEFINE_LIST_CHECK_ALL = {
  checkedAll: false,
  checkedRead: false,
  checkedApproveFile: false,
  checkedApproveFolder: false,
  checkedViewProperties: false,
  checkedEditProperties: false,
  checkedDelete: false,
  checkedViewPermission: false,
  checkedEditPermission: false,
  checkedChangeOwner: false,
  checkedMove: false
}

export const API_AUTHORIZED_BY_KEY = [
  '/document/file/upload'
]

export const getKeyPermission = (permisison) => {
  const findKey = Object.keys(DEFINE_PERMISSIONS).find(k => DEFINE_PERMISSIONS[k] === permisison);
  return findKey;
}
export const API_URL_USER_DETAIL = '/api/user/detail';
export const API_URL_DOCUMENT = '/document';
export const API_URL_DOWNLOAD_FILE = '/document/download';
export const API_URL_HOMEPAGE_RECENT_LIST = '/document/recent/dashboard';
export const API_URL_AWAITING_APPROVAL_LIST = '/document/waiting_approval';
export const API_URL_CREATE_FOLDER = '/document/folder';
export const API_URL_UPLOAD_KEY = '/document/file/key';
export const API_URL_UPLOAD_FILE = '/document/file/upload';
export const API_URL_UPLOAD_FILE_CONFIRM = '/document/upload/confirm';
export const API_URL_RENAME = '/document/rename';
export const API_URL_MOVE = '/document/move';
export const API_URL_APPROVE = '/document/approve';
export const API_URL_DOCUMENT_DETAIL = '/document/detail/';
export const API_URL_VIEW_LOG = '/document/log/';
export const API_URL_TAG = '/document/tag';
export const API_URL_CHANGE_OWNER = '/document/update/owner';
export const API_URL_CHECK_USER_IS_EXISTED = '/api/user/';
export const API_URL_SEARCH_USER = '/api/user/search';
export const API_URL_DOCUMENT_OCR = '/document/ocr/';
export const API_URL_APPROVE_OCR = '/document/ocr/approve/';
export const API_URL_CANCEL_OCR = '/document/ocr/cancel/';
export const API_URL_UPDATE_OCR = '/document/ocr/content';
export const API_URL_VIEW_EDIT_OCR = '/document/ocr/view-edit/';
export const API_URL_PERMISSION = '/document/permission';
export const API_URL_SEARCH = '/document/search';
export const API_URL_NOTI_REGISTER_DEVICE = '/notification/register-device';
export const API_URL_NOTI_LIST = '/notification/list';
export const API_URL_NOTI_SEEN = '/notification/seen/';
export const API_URL_CANCEL_REQUEST_OCR = '/document/ocr/cancel-request-ocr/';
export const API_URL_NOTI_SEEN_ALL = '/notification/seen/all';
export const API_URL_DOCUMENT_INFO = '/document/info/';

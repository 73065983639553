import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import { PAGE_LIST } from '../../constants/pages';
import FileActionPopup from '../FileActionPopup';
import SVGIcon from '../SVGIcon';
import { formatDate, formatBytes } from '../../utils/formatters';
import { isFileApproved, isViewedFileFolder, getFolderIcon } from '../../utils/functions';

RecentFolder.propTypes = {
  info: PropTypes.object.isRequired
};

const useStyles = makeStyles((theme) => ({
  blur: {
    opacity: '0.3'
  },
}));

function RecentFolder(props) {
  const { info } = props;
  const classes = useStyles();
  const history = useHistory();
  info.type = 0;

  const page = PAGE_LIST.find(page => page.folderType === info.folderTypeRoot);
  const rootUri = page ? `${page.uri}/folder/${info.id}` : '# ';
  const folderIcon = getFolderIcon(info);

  const handleClickFolder = (e) => {
    e.preventDefault();

    if (!isViewedFileFolder(info.permission1)) return;

    const url = rootUri || '/';
    history.push(url);
  }

  return (
    <div className="nk-file-item nk-file">
      <div className="nk-file-info">
        <div className="nk-file-title">
          <div className={`nk-file-icon ${!isFileApproved(info.isApproved, info.folderTypeRoot) ? classes.blur : ''}`}>
            <span className="nk-file-icon-type">
              <SVGIcon ext={folderIcon}></SVGIcon>
            </span>
          </div>
          <div className="nk-file-name">
            <div className="nk-file-name-text">
              <Link
                className="title"
                to={rootUri}
                onClick={handleClickFolder}
              >
                <span>{info.displayName}</span>
              </Link>
            </div>
          </div>
        </div>
        <ul className="nk-file-desc">
          <li className="date">{formatDate(info.lastViewAt)}</li>
          <li className="size">{formatBytes(info.size)}</li>
          <li className="members">{info.totalMember || 0} Members</li>
        </ul>
      </div>
      <div className="nk-file-actions">
        <FileActionPopup info={info}></FileActionPopup>
      </div>
    </div>
  );
}

export default RecentFolder;
import { makeAutoObservable } from "mobx";
import { toast } from 'react-toastify';

class OCRStore {
  getOCRFunction = null;
  submitCreateOCRFunction = null;
  submitApproveOCRFunction = null;
  submitCancelOCRFunction = null;
  submitUpdateOCRFunction = null;
  submitCancelRequestOCRFunction = null;

  constructor() {
    makeAutoObservable(this);
  }

  setGetOCRFunction(f) {
    this.getOCRFunction = f;
  }

  setGetViewEditOCRFunction(f) {
    this.getViewEditOCRFunction = f;
  }

  setSubmitCreateOCRFunction(f) {
    this.submitCreateOCRFunction = f;
  }

  setSubmitApproveOCRFunction(f) {
    this.submitApproveOCRFunction = f;
  }

  setSubmitCancelOCRFunction(f) {
    this.submitCancelOCRFunction = f;
  }

  setSubmitUpdateOCRFunction(f) {
    this.submitUpdateOCRFunction = f;
  }

  setSubmitCancelRequestOCRFunction(f) {
    this.submitCancelRequestOCRFunction = f;
  }

  async handleSubmitCreateOCR(id) {
    const response = await this.submitCreateOCRFunction(id);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async getOCR(id, pageNumber = 1) {
    const response = await this.getOCRFunction(id, pageNumber);
    if (response) {
      return {
        data: response.data,
        success: true
      };
    } else {
      return { success: false };
    }
  }

  async getViewEditOCR(id, pageNumber = 1) {
    const response = await this.getViewEditOCRFunction(id, pageNumber);
    if (response) {
      return {
        data: response.data,
        success: true
      };
    } else {
      return { success: false };
    }
  }

  async handleSubmitApproveOCRFile(id) {
    const response = await this.submitApproveOCRFunction(id);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitCancelOCRFile(id) {
    const response = await this.submitCancelOCRFunction(id);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitUpdateOCRFile(data, params) {
    let parseParams = '';
    Object.keys(params).forEach((key, ind) => {
      parseParams += (ind > 0 ? '&' : '') + `${key}=` + params[key];
    })

    const response = await this.submitUpdateOCRFunction(data, parseParams);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitCancelRequestOCRFile(id, data) {
    const response = await this.submitCancelRequestOCRFunction(id, data);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

}

export default OCRStore;
import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useStore } from '../stores';
import ViewModeButtons from './ViewModeButtons';
import FileActionPopup from './FileActionPopup';

function NextIcon() {
  return (
    <svg className=" a-s-fa-Ha-pa" width="24px" height="24px" viewBox="0 0 24 24" focusable="false" fill="#000000">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  );
}

const Title = observer((props) => {
  const { pageName, breadcrumb, multiViewMode } = props;
  const { viewStore, searchStore, fileActionStore } = useStore();
  const isShowBreadcrumb = !searchStore.getTitle;
  const { id } = useParams();
  const [currentInfo, setCurrentInfo] = useState({});

  useEffect(() => {
    async function getDocInfo() {
      let response = await fileActionStore.getDocumentInfo(id);
      if (response.success) {
        setCurrentInfo({
          ...fileActionStore.currentInfo,
          ...response.data
        });
      }
    }

    if (id) {
      setTimeout(() => {
        getDocInfo();
      }, 200);
    }

    return () => {
      setCurrentInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, viewStore.refreshCurrentInfo]);

  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between position-relative">
        <div className="nk-block-head-content">
          {breadcrumb && breadcrumb.length && isShowBreadcrumb ? (
            <Breadcrumbs separator={<NextIcon />} maxItems={8} aria-label="breadcrumb">
              {breadcrumb.slice(0, breadcrumb.length - 1).map((item, index) => {
                let uri;
                if (index === 0) {
                  uri = viewStore.pageName ? `/${viewStore.pageName}` : '# ';
                } else {
                  uri = viewStore.pageName ? `/${viewStore.pageName}/folder/${item.id}` : '# ';
                }

                return (
                  <Link
                    color="inherit"
                    key={item.id}
                    to={uri}
                  >
                    <span>{item.name}</span>
                  </Link>
                )
              })}
                <Typography color="textPrimary" component={'span'} className="nk-folder-breadcrumb">
                  {breadcrumb[breadcrumb.length - 1].name}
                  {currentInfo && (
                    <FileActionPopup info={currentInfo} icon={'ni-caret-down-fill'}></FileActionPopup> 
                  )}
                </Typography>
            </Breadcrumbs>
          ) : (
            <h3 className="nk-block-title page-title">{pageName}</h3>
          )}
        </div>
        <div className="search-wrap px-2 d-lg-none" data-search="search">
          <div className="search-content">
            <a href="# " className="search-back btn btn-icon toggle-search" data-target="search">
              <em className="icon ni ni-arrow-left"></em>
            </a>
            <input
              type="text"
              className="form-control border-transparent form-focus-none"
              placeholder="Search by user or message"
            />
            <button className="search-submit btn btn-icon">
              <em className="icon ni ni-search"></em>
            </button>
          </div>
        </div>
        {multiViewMode &&
          <ViewModeButtons></ViewModeButtons>
        }
      </div>
    </div>
  );
});

Title.propTypes = {
  pageName: PropTypes.string,
  breadcrumb: PropTypes.array,
  multiViewMode: PropTypes.bool
};

Title.defaultProps = {
  pageName: '',
  breadcrumb: [],
  multiViewMode: false
}

export default Title;
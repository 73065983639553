import React from 'react';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { useKeycloak } from '@react-keycloak/web';
import { observer } from 'mobx-react-lite';

import { useStore } from '../stores';
import { formatDatetime, toLowerCase } from '../utils/formatters';
import { getUrlNotification, isImageExt } from '../utils/functions';
import { deleteTokenFirebase } from '../firebase/config-firebase';
import { TYPE_DOCUMENT } from '../constants/pages';

const useStyles = makeStyles(() => ({
  statusDot: {
    marginLeft: '-6px',
    marginRight: '3px'
  }
}));

const Header = observer(() => {
  const { userStore, viewStore, notificationStore, fileActionStore } = useStore();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const classes = useStyles();
  const notifications = notificationStore.splitNotifications;

  const handleClickSidebar = (e) => {
    e.preventDefault();
    viewStore.setIsShowSidebar();
  }

  const handleMarkAllAsRead = async (e) => {
    e.preventDefault();

    if (notificationStore.totalNotifications < 1) return;

    const result = await notificationStore.submitSeenAllNotification();

    if (result?.success) {
      notificationStore.enableRefreshListNoti();
    }
  }

  const handleClickViewAll = (e) => {
    notificationStore.getListNotification();
  }

  const handleClickNotification = async (popupState, item) => {
    const result = await notificationStore.submitSeenNotification(item.id);

    if (result?.success) {
      notificationStore.enableRefreshListNoti();
    }

    if (!item.folderId) return; // trường hợp đã bị xóa

    const documentInfo = await fileActionStore.getDocumentInfo(item.folderId);
    if (documentInfo?.success) {
      const ext = documentInfo.data.extension;

      fileActionStore.setInfo(documentInfo.data);
      fileActionStore.setCurrentInfo(documentInfo.data);

      if (item.typeDocument === TYPE_DOCUMENT['FILE']) {
        if (toLowerCase(ext) === 'pdf') {
          fileActionStore.handleShowFilePDF(item.folderId);
        } else if (isImageExt(ext)) {
          fileActionStore.handleShowFileImage(item.folderId);
        } else {
          fileActionStore.setShowModal('Image', true)
        }
      } else {
        const url = getUrlNotification(item);
        if (url && url.length) {
          // Fix bug bị gọi list folder 2 lần khi đổi sang folder khác folder hiện tại
          if (item.folderId === viewStore.folderId) {
            viewStore.enableRefreshList();
          }
  
          history.push(url);
        }
      }
    }

    popupState.close();
  }

  const handleLogout = async (e) => {
    e.preventDefault();

    await deleteTokenFirebase();
    keycloak.logout();
  }

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              href="# "
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
              onClick={handleClickSidebar}
            >
              <em className="icon ni ni-menu"></em>
            </a>
          </div>
          <Link to="/">
            <div className="nk-header-app-name">
              <div className="nk-header-app-logo">
                <img
                  src="/images/logo180.png"
                  alt="VIMC Logo"
                />
              </div>
              <div className="nk-header-app-info">
                <span className="lead-text">VIMC Cloud</span>
              </div>
            </div>
          </Link>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li style={{ display: 'none' }}>{userStore.name}</li> {/* use this line to trigger re-render in PopupState*/}
              <li className="dropdown notification-dropdown" style={{ cursor: 'pointer' }}>
                <PopupState variant="popover" popupId="notification-popup">
                  {(popupState) => (
                    <div>
                      <a
                        onClick={e => e.preventDefault()}
                        {...bindTrigger(popupState)}
                        className="dropdown-toggle nk-quick-nav-icon"
                        data-toggle="dropdown"
                        style={{ height: '30px' }}
                      >
                        <Tooltip title="Thông báo">
                          <div className="icon-status">
                            <em className="icon ni ni-bell"></em>
                            {notificationStore.totalNotifications > 0 && (
                              <span className="badge-notification">
                                {notificationStore.totalNotifications}
                              </span>
                            )}
                          </div>
                        </Tooltip>
                      </a>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        disableScrollLock={true}
                        classes={{ paper: "notification-popup" }}
                      >
                        {notifications && notifications.length > 0 ? (
                          <>
                            <div className="dropdown-head dropdown-menu-s1">
                              <span className="sub-title nk-dropdown-title">Thông báo</span>
                              <a href="# " onClick={handleMarkAllAsRead}>Đánh dấu tất cả đã đọc</a>
                            </div>
                            <div className="dropdown-body">
                              <div className="nk-notification">
                                {notifications.map((item) => (
                                  <div
                                    key={item.id}
                                    className="nk-notification-item dropdown-inner"
                                    style={{
                                      fontWeight: item.isViewed ? '' : 'bold',
                                      background: item.isViewed ? '#eaeaea' : ''
                                    }}
                                    onClick={() => handleClickNotification(popupState, item)}
                                  >
                                    <div className={`nk-notification-icon ${classes.statusDot}`}>
                                      <em
                                        className="icon ni ni-dot"
                                        style={{
                                          fontSize: '28px',
                                          color: item.isViewed ? '#a8a8a8' : '#00b2ff'
                                        }}
                                      ></em>
                                    </div>
                                    <div className="nk-notification-content">
                                      <div className="nk-notification-text">
                                        {item.description}
                                      </div>
                                      <div className="nk-notification-time">
                                        {formatDatetime(item.receivedTime)}
                                      </div>
                                    </div>
                                  </div>
                                  ))
                                }
                              </div>
                            </div>
                            {notificationStore.notifications.length > 3 && (
                              <div
                                className="dropdown-foot center"
                                onClick={handleClickViewAll}
                              >
                                <a href="# " onClick={e => e.preventDefault()}>
                                  {notificationStore.isShowViewAll ? 'Thu gọn' : 'Tất cả'}
                                </a>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="dropdown-foot">
                            <i>Bạn không có thông báo mới</i>
                          </div>
                        )}
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </li>
              <li className="dropdown user-dropdown">
                <PopupState variant="popover" popupId="account-popup">
                  {(popupState) => (
                    <div>
                      <a
                        onClick={e => e.preventDefault()}
                        {...bindTrigger(popupState)}
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <div className="user-toggle">
                          <Tooltip title={userStore.name || ''}>
                            <div className="user-avatar sm">
                              <em className="icon ni ni-user-alt"></em>
                            </div>
                          </Tooltip>
                          <div className="user-info d-none d-md-block">
                            <div className="user-name dropdown-indicator">
                              {userStore.name ? `Xin chào, ${userStore.name}` : ''}
                            </div>
                          </div>
                        </div>
                      </a>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        disableScrollLock={true}
                      >
                        <div style={{
                          minWidth: '280px',
                          maxWidth: '280px'
                        }}>
                          <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block appearName">
                            <div className="user-card">
                              <div className="user-avatar">
                                <em className="icon ni ni-user-alt"></em>
                              </div>
                              <div className="user-info">
                                <span className="lead-text">{userStore.name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="dropdown-inner">
                            <ul className="link-list">
                              {!!keycloak.authenticated &&
                                <li>
                                  <a href="# " onClick={handleLogout}>
                                    <em className="icon ni ni-signout"></em>
                                    <span> Đăng xuất </span>
                                  </a>
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Header;
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
  Input,
  MenuItem
} from '@material-ui/core';
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  }
}));

function getStyles(id, list) {
  if (list.indexOf(id) === -1) {
    return {
      fontWeight: 'unset',
      background: ''
    };
  } else {
    return {
      fontWeight: 500,
      background: '#dadada'
    }
  }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 400,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const SelectCustom = (props) => {
  const {
    id,
    value,
    list,
    multiple,
    style,
    error,
    showTextError,
    textError,
    disabledSelect,
    disabledItem,
    onChangeSelected
  } = props;

  const [selected, setSelected] = useState(value);
  const classes = useStyles();
  const isShowTextError = showTextError && error;

  const handleChangeSelected = (event) => {
    setSelected(event.target.value);
  }

  return (
    <div>
      <FormControl className={classes.formControl} style={style} error={error}>
        <Select
          id="mutiple-name"
          multiple={multiple}
          value={selected}
          onChange={(e) => { handleChangeSelected(e); onChangeSelected(e) }}
          input={<Input />}
          MenuProps={MenuProps}
          disabled={disabledSelect}
        >
          {list.map((item) => (
            <MenuItem
              key={item[id]}
              value={item[id]}
              style={getStyles(item[id], selected)}
              disabled={disabledItem}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {isShowTextError && <FormHelperText>{textError}</FormHelperText>}
      </FormControl>
    </div>
  );
}

SelectCustom.propTypes = {
  id: PropTypes.string,
  value: PropTypes.array,
  list: PropTypes.array,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  error: PropTypes.bool,
  showTextError: PropTypes.bool,
  textError: PropTypes.string,
  disabledSelect: PropTypes.bool,
  disabledItem: PropTypes.bool,
  onChangeSelected: PropTypes.func
}

SelectCustom.defaultProps = {
  id: 'id',
  value: [],
  list: [],
  multiple: true,
  style: { width: '180px' },
  error: false,
  showTextError: true,
  textError: 'Vui lòng chọn quyền',
  disabledSelect: false,
  disabledItem: false,
  onChangeSelected: null
}

export default SelectCustom;
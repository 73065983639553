import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames/bind';

import { useStore } from '../stores';

const ViewModeButtons = observer(() => {
  const { viewStore } = useStore();

  return (
    <div className="nk-block-head-content d-none d-lg-block">
      <ul className="nk-block-tools g-3">
        <li onClick={() => viewStore.setViewMode('grid')}>
          <a href="# " className={classNames({ 'nk-switch-icon': true, 'active': viewStore.mode === 'grid' })}>
            <em className="icon ni ni-view-grid3-wd"></em>
          </a>
        </li>
        <li onClick={() => viewStore.setViewMode('list')}>
          <a href="# " className={classNames({ 'nk-switch-icon': true, 'active': viewStore.mode === 'list' })}>
            <em className="icon ni ni-view-row-wd"></em>
          </a>
        </li>
      </ul>
    </div>
  );
});

export default ViewModeButtons;
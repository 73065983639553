import React from 'react';
import logo from '../assets/images/vimc-logo.png';

function LoadingApp() {
  return (
    <div className="loading-app">
      <div className="content">
        <div className="logo">
          <img src={logo} alt="VIMC Logo" />
          <span>Cloud</span>
        </div>
        <div className="loading-text">Loading...</div>
      </div>
    </div>
  );
}

export default LoadingApp;
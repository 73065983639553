import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Draggable from "react-draggable";

import SVGIcon from '../SVGIcon';
import { useStore } from '../../stores';
import {
  parseLocation,
  // checkConfidenceScore,
  calculateFontSize,
  calcScaleLocation,
  calcScale,
  calcScaleData
} from '../../utils/functions';
import { formatNameFileFolder, BASE_64 } from '../../utils/formatters';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  modalWrapper: {
    width: '1000px',
    height: '630px',
    marginBottom: '20px',
    position: 'absolute',
    top: '20px',
    borderRadius: '5px',
    '&:hover': {
      '& $pageControls': {
        opacity: 1
      }
    }
  },
  modalContent: {
    position: 'relative',
    borderRadius: 0
  },
  fileDetail: {
    height: '65vh',
    overflow: 'auto',
    '& .content-view-ocr': {
      position: 'relative',
      minWidth: '1400px',
      height: '100%'
    }
  },
  pageControls: {
    position: 'absolute',
    bottom: '2.2%',
    left: '50%',
    background: '#fff',
    opacity: 0.6,
    transform: 'translateX(-50%)',
    transition: 'opacity ease-in-out 0.2s',
    boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
    borderRadius: '4px',
  },
  pageControlsButton: {
    width: '36px',
    height: '36px',
    background: '#fff',
    border: 0,
    font: 'inherit',
    fontSize: '1.2em',
    borderRadius: '4px',
    '&:enabled&:hover': {
      backgroundColor: '#e6e6e6'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  pageControlsSpan: {
    font: 'inherit',
    fontSize: '1.2em',
    padding: '0 0.5em',
  }
}));

const TARGET_WIDTH = 990;

const ViewOCR = observer((props) => {
  const { fileActionStore, OCRStore } = useStore();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDisablePrev, setIsDisablePrev] = useState(true);
  const [isDisableNext, setIsDisableNext] = useState(true);
  const classes = useStyles();
  const openModal = fileActionStore.isShowOCR;
  const [dataOCR, setDataOCR] = useState({});
  const [bgImage, setBgImage] = useState(null);
  const [originalWidthImg, setOriginalWidthImg] = useState(null);
  const [currentWidthImg, setCurrentWidthImg] = useState(TARGET_WIDTH);
  const [currentHeightImg, setCurrentHeightImg] = useState(null);

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setShowModal('OCR', false);
    setNumPages(null);
    setPageNumber(1);
    setIsDisablePrev(true);
    setIsDisableNext(true);
  };

  const getOCR = async (page) => {
    page = page || pageNumber;
    let response = await OCRStore.getOCR(fileActionStore.info.id, page);
    if (response.success) {
      const data = response.data.ocr;
      data.blocks = data.blocks && JSON.parse(data.blocks);

      setDataOCR(data);
      setNumPages(response.data.totalPage);

      if (response.data.ocr.inputImage) {
        const imgUrl = BASE_64 + response.data.ocr.inputImage;
        getOriginalWidthImg(imgUrl);
        setBgImage(imgUrl);
      } else {
        getImage();
      }
    }
  }

  const getImage = async () => {
    await fileActionStore.handleShowFileImage(fileActionStore.info.id, false);
    setBgImage(fileActionStore.fileImageUrl);
    getOriginalWidthImg(fileActionStore.fileImageUrl);
  }

  const getOriginalWidthImg = async (imgUrl) => {
    let img = new Image();
    img.src = imgUrl;
    img.onload = function() {
      setCurrentHeightImg(img.height);
      setOriginalWidthImg(img.width);
    };
  }

  const handleGoPrevious = () => {
    if (pageNumber > 1) {
      let page = pageNumber - 1;
      setPageNumber(page);
      getOCR(page);
    }
  }

  const handleGoNext = () => {
    if (pageNumber < numPages) {
      let page = pageNumber + 1;
      setPageNumber(page);
      getOCR(page);
    }
  }

  const checkDisabled = () => {
    if (pageNumber === 1) {
      setIsDisablePrev(true);
    } else {
      setIsDisablePrev(false);
    }

    if (pageNumber === numPages) {
      setIsDisableNext(true);
    } else {
      setIsDisableNext(false);
    }
  }

  const renderHTML = () => {
    const data = [];
    const blocks = dataOCR.blocks;

    if (Array.isArray(blocks) && blocks.length) {
      blocks.forEach((lineData, index) => {
        // const score = lineData.isUpdated ? lineData.score_update : lineData.score;
        const textBox = lineData.isUpdated ? lineData.text_update : lineData.text;

        // const isCheckedConfidence = checkConfidenceScore(score);
        let scaleLocation = lineData.bounding_box;
        if (!dataOCR.isScaled) {
          let result = calcScale(originalWidthImg, TARGET_WIDTH);
          let scaleHeight = calcScaleData(currentHeightImg, result.scale);

          setCurrentWidthImg(result.currentWidth);
          setCurrentHeightImg(scaleHeight);

          scaleLocation = calcScaleLocation(lineData.bounding_box, result.scale);
        }
        const location = parseLocation(scaleLocation);
        const fontSize = calculateFontSize(location);

        data.push(
          <div
            key={'line-' + index}
            style={{
              cursor: 'default',
              position: 'absolute',
              left: location.left,
              top: location.top,
              border: !lineData.isDeleted ? '1px solid green' : '',
              minWidth: location.width,
              height: location.height,
              background: !lineData.isDeleted ? 'white' : '',
              zIndex: !lineData.isDeleted ? 10 : 0,
              fontSize: fontSize.fontSize
            }}
          >
            {/* {!lineData.isDeleted ?
              (
                <svg
                  width={`${location.width}`}
                  height={`${location.height}`}
                  viewBox={`0 0 ${location.width} ${location.height}`}
                  preserveAspectRatio="xMinYMid meet"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <text
                    x="2"
                    y={fontSize.y}
                    fontSize={fontSize.fontSize}
                    fill="black"
                  >
                    {textBox}
                  </text>
                </svg>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: textBox }} />
              )
            } */}
            <div dangerouslySetInnerHTML={{ __html: textBox }} />
          </div>
        )
      });
    }

    dataOCR.isScaled = true;

    if (dataOCR.vimcImage && dataOCR.vimcImage.length) {
      data.push(
        <div
          key='vimc-image'
          style={{
            cursor: 'default',
            position: 'absolute',
          }}
        >
          <img
            src={BASE_64 + dataOCR.vimcImage}
            alt="a"
          />
        </div>
      );
    }

    return data;
  }

  const clearData = () => {
    setDataOCR({});
    setOriginalWidthImg(null);
    setCurrentWidthImg(TARGET_WIDTH);
    setCurrentHeightImg(null);
    setBgImage(null);
  }

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (openModal) {
      checkDisabled();
      getOCR();
    }

    return () => {
      clearData();
    }
    // eslint-disable-next-line
  }, [openModal])

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div className={classes.modalWrapper} tabIndex="-1" role="dialog" id="view-ocr" aria-modal="true">
                <div className="modal-dialog modal-md" role="document" style={{ maxWidth: '80vw' }}>
                  <div className={`modal-content ${classes.modalContent}`}>
                    <div className="modal-header align-center">
                      <div className="nk-file-title">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <SVGIcon ext={fileActionStore.info.extension || 'folder'} hardMode="list"></SVGIcon>
                          </span>
                        </div>
                        <div className="nk-file-name">
                          <div className="nk-file-name-text">
                            <span className="title">
                              {formatNameFileFolder(fileActionStore.info)} {'>> Nội dung OCR'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <a href="# " className="close" onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className={`nk-file-details ${classes.fileDetail}`} style={{ position: 'relative' }}>
                      {originalWidthImg ? (
                        <div
                          className="content-view-ocr"
                          style={{
                            width: currentWidthImg,
                            backgroundImage: `url('${bgImage}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: `${currentWidthImg}px auto`,
                            height: currentHeightImg
                          }}
                        >
                          {renderHTML()}
                        </div>
                      ) : (
                        <div
                          className="content-view-ocr"
                          style={{
                            width: TARGET_WIDTH
                          }}
                        >
                        </div>
                      )}
                    </div>
                    {numPages && (
                      <div className={classes.pageControls}>
                        <button
                          type="button"
                          disabled={isDisablePrev}
                          className={classes.pageControlsButton}
                          onClick={handleGoPrevious}
                        >‹</button>
                        <span className={classes.pageControlsSpan}>{pageNumber} of {numPages}</span>
                        <button
                          type="button"
                          disabled={isDisableNext}
                          className={classes.pageControlsButton}
                          onClick={handleGoNext}
                        >›</button>
                      </div>
                    )}
                    <div className="modal-footer modal-footer-stretch bg-light">
                      <div className="modal-footer-between" style={{ justifyContent: 'flex-end' }}>
                        <div className="g">
                          <ul className="btn-toolbar g-3">
                            <li><a href="# " className="btn btn-outline-light btn-white" onClick={handleCloseModal}> Đóng </a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
})

export default ViewOCR;
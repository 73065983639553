import React from 'react';
import PropTypes from 'prop-types';
import { formatBytes } from '../../utils/formatters';
import SVGIcon from '../SVGIcon';

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  progress: PropTypes.number,
  onClickDelete: PropTypes.func
};

ListItem.defaultProps = {
  progress: null,
  onClickDelete: null
}

function ListItem(props) {
  const { name, size, progress, onClickDelete } = props;
  const showDownload = progress && progress <= 100;

  const splitName = name.split('.');
  const extension = splitName[splitName.length - 1];

  const titleListItem = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '280px'
  }

  return (
    <div className="nk-upload-item">
      <div className="nk-upload-icon">
        <SVGIcon ext={extension}></SVGIcon>
      </div>
      {showDownload ? (
        <>
          <div className="nk-upload-info">
            <div className="nk-upload-title">
              <span className="title" style={{ ...titleListItem, maxWidth: '250px' }} title={name}> {name} </span>
              <span className="meta">{progress}% </span>
            </div>
            <div className="nk-upload-progress">
              <div className="progress progress-sm">
                <div className="progress-bar progress-bar-striped progress-bar-animated" data-progress={progress} style={{ width: progress + '%' }}></div>
              </div>
            </div>
          </div>
          <div className="nk-upload-action">
            <a href="# " className="btn btn-icon btn-trigger" onClick={onClickDelete}>
              <em className="icon ni ni-cross"></em>
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="nk-upload-info">
            <div className="nk-upload-title">
              <span className="title" style={titleListItem} title={name}> {name} </span>
            </div>
            <div className="nk-upload-size"> {formatBytes(size)} </div>
          </div>
          <div className="nk-upload-action">
            <a href="# " className="btn btn-icon btn-trigger" onClick={onClickDelete}>
              <em className="icon ni ni-trash"></em>
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default ListItem;
import { runInAction, makeAutoObservable } from "mobx";

const SORT_BY = "SORT_BY";
const SORT_ORDER = "SORT_ORDER";
class ViewStore {
  mode = 'list';
  pageName = '';
  folderId = '';
  refreshList = true;
  refreshCurrentInfo = false;
  isLoading = false;
  sortBy = 'displayName';
  sortOrder = 'ASC';
  sortState = {
    displayName: 'ASC',
    lastViewAt: 'DESC',
    setSortBy: 'displayName'
  }
  isShowBreadcrumb = false;
  isShowHighlight = {};
  isShowSidebar = false;
  isRefreshListPermission = false;
  isShowViewFile = false;
  isShowCheckbox = false;
  isSelectedCheckbox = {};
  isSelectedAll = false;
  isCheckSelectedAll = false;

  constructor() {
    makeAutoObservable(this);
  }

  setViewMode(mode) {
    runInAction(() => {
      this.mode = mode;
    });
  }

  setPageName(page) {
    runInAction(() => {
      this.pageName = page;
    });
  }

  setFolderId(id) {
    runInAction(() => {
      this.folderId = id;
    });
  }

  enableRefreshList() {
    runInAction(() => {
      this.refreshList = true;
    });
  }

  enableRefreshCurrentInfo() {
    runInAction(() => {
      this.refreshCurrentInfo = true;
    });
  }

  enableLoading() {
    runInAction(() => {
      this.isLoading = true;
    });
  }

  disableLoading() {
    runInAction(() => {
      this.isLoading = false;
    });
  }

  disableRefreshList() {
    runInAction(() => {
      this.refreshList = false;
    });
  }

  disableRefreshCurrentInfo() {
    runInAction(() => {
      this.refreshCurrentInfo = false;
    });
  }

  setSortList(sortBy, sortOrder = null, isSortOrder = true) {
    runInAction(() => {
      let order = sortOrder;
      if (isSortOrder) {
        order = (sortOrder || this.sortState[sortBy]) === 'ASC' ? 'DESC' : 'ASC';
      }
      this.sortBy = sortBy;
      this.sortOrder = order;
      this.sortState[sortBy] = order;
      this.sortState.setSortBy = sortBy;
      if (sortBy === 'displayName') {
        this.sortState.lastViewAt = 'DESC';
      } else {
        this.sortState.displayName = 'DESC';
      }
      this.setDefaultSortList(sortBy, order);
    });
  }

  setDefaultSortList(sortBy, sortOrder) {
    localStorage.setItem(SORT_BY, sortBy);
    localStorage.setItem(SORT_ORDER, sortOrder);
  }

  getDefaultSortList(sortBy, sortOrder) {
    let getSortBy = sortBy;
    let getSortOrder = sortOrder;
    if (!getSortBy && !getSortOrder) {
      getSortBy = localStorage.getItem(SORT_BY) || this.sortBy;
      getSortOrder = localStorage.getItem(SORT_ORDER) || this.sortOrder;
    }
    this.setSortList(getSortBy, getSortOrder, false);
  }

  setIsShowBreadcrumb(show) {
    runInAction(() => {
      this.isShowBreadcrumb = show;
    });
  }

  setIsShowHighlight(id, show) {
    runInAction(() => {
      this.isShowHighlight[id] = show;
    });
  }

  setIsShowSidebar(show) {
    runInAction(() => {
      this.isShowSidebar = show || !this.isShowSidebar;
    });
  }

  setIsRefreshListPermission(refresh) {
    runInAction(() => {
      this.isRefreshListPermission = refresh;
    });
  }

  setIsShowViewFile(show) {
    runInAction(() => {
      this.isShowViewFile = show;
    });
  }

  setIsShowCheckbox(show) {
    runInAction(() => {
      this.isShowCheckbox = show;
    });
  }

  setIsSelectedCheckbox(id, check, objData = null) {
    runInAction(() => {
      if (!objData) {
        this.isSelectedCheckbox[id] = check;
      } else {
        this.isSelectedCheckbox = objData;
      }
      this.setIsCheckSelectedAll(true);
    });
  }

  setIsSelectedAll(check) {
    runInAction(() => {
      this.isSelectedAll = check;
    });
  }

  setIsCheckSelectedAll(check) {
    runInAction(() => {
      this.isCheckSelectedAll = check;
    });
  }

}

export default ViewStore;
import { toast } from 'react-toastify';

import { MIME_TYPES, MIME_TYPES_OCR } from '../constants/mimeTypes';
import { OCR_STATUS } from '../constants/ocr';
import { PAGE_LIST } from '../constants/pages';
import { ERROR_MESSAGE } from '../constants/messages';
import { toLowerCase, capitalizeFirstLetter } from '../utils/formatters';
import { getKeyPermission } from '../constants/permission';

export const isImageExt = (ext) => {
  ext = toLowerCase(ext);
  return MIME_TYPES[ext] && MIME_TYPES[ext].indexOf('image/') >= 0;
}

export const isFileApproved = (isApproved, rootType) => {
  if (rootType === 0) return true;
  return !!isApproved;
}

export const isClickableFile = (ext) => {
  ext = toLowerCase(ext);
  return ext === 'pdf' || isImageExt(ext);
}

export const clearSelection = () => {
  if (document.selection && document.selection.empty) {
    document.selection.empty();
  } else if (window.getSelection) {
    var sel = window.getSelection();
    sel.removeAllRanges();
  }
}

export const checkIsShowActionOCR = (action, ocrStatus, extension = null) => {
  let arrCheck = [];
  switch (action) {
    case 'OCR':
      return OCR_STATUS['NOT_YET_OCR'] === ocrStatus;
    case 'ViewOCR':
      arrCheck = [
        OCR_STATUS['DONE_OCR'],
        OCR_STATUS['WAITING_APPROVE'],
        OCR_STATUS['DONE']
      ];
      return arrCheck.includes(ocrStatus);
    case 'EditOCR':
      return OCR_STATUS['DONE_OCR'] === ocrStatus;
    case 'ApproveOCR':
      return OCR_STATUS['WAITING_APPROVE'] === ocrStatus;
    case 'CancelRequestOCR':
      return OCR_STATUS['WAITING_APPROVE'] === ocrStatus;
    case 'CancelOCR':
      return OCR_STATUS['DONE'] === ocrStatus;
    case 'MenuOCR':
      extension = extension && extension.length && extension.toLowerCase();
      return MIME_TYPES_OCR.includes(extension);
    default: break;
  }
}

export const parseLocation = (location) => {
  if (!(Array.isArray(location) && location.length)) return {};
  const getPosTopLeft = location[0]?.toString()?.split(',') || [];
  const getPosBotRight = location[2]?.toString()?.split(',') || [];
  const top = +getPosTopLeft[1];
  const left = +getPosTopLeft[0];
  const bot = +getPosBotRight[1];
  const right = +getPosBotRight[0];
  const height = Math.ceil(bot-top);
  const width = Math.ceil(right-left);
  return { top, left, bot, right, height, width };
}

export const checkConfidenceScore = (confidence) => {
  if (!(Array.isArray(confidence) && confidence.length)) return false;
  // độ chính xác 90%
  const CONFIDENCE = 0.9;
  // đếm các phần tử < 0.9
  let count = confidence.reduce((total, score) => {
    return total += (score < CONFIDENCE) ? 1 : 0;
  }, 0)
  // tính tỉ lệ các phần tử < 0.9 trên tổng số
  let result = (count / confidence.length) < CONFIDENCE;
  return result;
}

export const moveCursorToEndInput = (input) => {
  // save old value as we need to clear it, clear value, re-apply
  let getValue = input.value;
  input.value = '';
  input.value = getValue;

  // focus input, textarea
  input.focus();
}

export const arraysEqual = (a, b) => {
  return Array.isArray(a)
    && Array.isArray(b)
    && a.length === b.length && a.every(val => b.indexOf(val) !== -1);
}

export const duplicateArray = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export const findValueSelected = (a, b) => {
  if (!(Array.isArray(a) && Array.isArray(b))) return '';

  const maxArr = a.length > b.length ? a : b;
  const minArr = a.length > b.length ? b : a;
  const indValSelected = maxArr.findIndex((item, ind) => (minArr.indexOf(item) === -1 && ind));

  return maxArr[indValSelected];
}

export const splitArray = (arr, num) => {
  if (!arr) return [];
  return arr.slice(0, num);
}

export const getUrlNotification = (info) => {
  let folderUri;

  if (!info.folderId) {
    return null;
  }

  if (info.folderType) {
    const page = PAGE_LIST.find(page => page.folderType === info.folderType);
    if (!info.folderParentId) {
      folderUri = page ? `${page.uri}` : '';
    } else {
      folderUri = page ? `${page.uri}/folder/${info.folderId}` : '';
    }
  }

  return folderUri;
}

export const isViewedFileFolder = (permission, textError = null) => {
  if (!permission) {
    if (!textError) toast.error(ERROR_MESSAGE['403'].message);
    else toast.error(textError);
    return false;
  }
  return true;
}

export const getFolderIcon = (folder) => {
  let icon = 'folder';
  if (!folder || folder.isOwner) return icon;
  if (!folder.permission1) {
    icon = 'folderSecure';
  }
  else if (folder.userPermissions?.length) {
    icon = 'folderShared';
  }

  return icon;
}

export const cutFirstCharacter = (char) => {
  if (!char) return '';
  return char.split('')[0];
}

export const cutNameToSymbol = (name) => {
  if (!name) return '';

  let result = '';
  let arrName = name.split(' ');
  if (arrName.length < 2) return cutFirstCharacter(name);

  let firstName = cutFirstCharacter(arrName[0]);
  let lastName = cutFirstCharacter(arrName[arrName.length - 1]);
  result += firstName + lastName;

  return result;
}

export const calculateFontSize = (data) => {
  let result = {
    y: '55%',
    fontSize: data.height
  };

  const arrProperties = [
    { height: 7, y: '55%', scaleDown: 0 },
    { height: 8, y: '55%', scaleDown: 2 },
    { height: 10, y: '55%', scaleDown: 3 },
    { height: 15, y: '55%', scaleDown: 5 },
    { height: 20, y: '65%', scaleDown: 8 },
    { height: 25, y: '65%', scaleDown: 10 },
    { height: 30, y: '65%', scaleDown: 12 },
    { height: 32, y: '65%', scaleDown: 15 },
    { height: 36, y: '65%', scaleDown: 16 },
    { height: 40, y: '65%', scaleDown: 17 },
    { height: 42, y: '65%', scaleDown: 18 },
    { height: 45, y: '65%', scaleDown: 19 },
    { height: 49, y: '65%', scaleDown: 20 },
    { height: 51, y: '65%', scaleDown: 22 },
    { height: 54, y: '65%', scaleDown: 24 },
    { height: 55, y: '65%', scaleDown: 25 },
    { height: 59, y: '65%', scaleDown: 27 },
    { height: 63, y: '65%', scaleDown: 28 },
    { height: 65, y: '65%', scaleDown: 29 },
    { height: 70, y: '65%', scaleDown: 32 },
    { height: 75, y: '65%', scaleDown: 36 },
    { height: 78, y: '65%', scaleDown: 38 },
    { height: 80, y: '65%', scaleDown: 40 },
    { height: 85, y: '65%', scaleDown: 45 },
    { height: 90, y: '65%', scaleDown: 50 },
    { height: 95, y: '65%', scaleDown: 55 },
    { height: 100, y: '65%', scaleDown: 60 },
    { height: 105, y: '65%', scaleDown: 65 },
    { height: 110, y: '65%', scaleDown: 70 },
    { height: 115, y: '65%', scaleDown: 75 }
  ]

  arrProperties.forEach(item => {
    if (data.height >= item.height) {
      result.y = item.y;
      result.fontSize = data.height - item.scaleDown;
    }
  });

  return result;
}

export const calcScaleLocation = (data, scale) => {
  data?.forEach(loc => {
    loc[0] = Math.ceil(loc[0] * +scale);
    loc[1] = Math.ceil(loc[1] * +scale);
  })

  return data;
}

export const calcScale = (originalWidth, targetWidth) => {
  let currentWidth = originalWidth > targetWidth ? targetWidth : originalWidth;
  let scale = mathRound(currentWidth / originalWidth, 2);
  return {
    currentWidth,
    scale: scale || 1
  }
}

export const mathRound = (number, e) => {
  if (!isFinite(number)) return number;
  const decimal = Math.pow(10, e);
  return Math.round((number)*decimal)/decimal;
}

export const calcScaleData = (data, scale) => {
  if (!isFinite(data) || !isFinite(scale)) return data;
  return mathRound(+data * +scale, 2);
}

export const checkDisableMenu = (menu, info) => {
  let getKey = '';
  let menuParentPermission = ['ApproveFile', 'ApproveFolder'];

  switch (menu) {
    case 'Download':
    case 'ViewOCR':
      getKey = getKeyPermission('Read');
      break;

    case 'ApproveFile':
    case 'ConfirmOCR':
    case 'EditOCR':
      getKey = getKeyPermission('ApproveFile');
      break;

    case 'ApproveFolder':
    case 'ApproveOCR':
    case 'CancelOCR':
    case 'CancelRequestOCR':
      getKey = getKeyPermission('ApproveFolder');
      break;

    case 'Detail':
      getKey = getKeyPermission('ViewProperties');
      break;

    case 'Rename':
    case 'UpdateTag':
      getKey = getKeyPermission('EditProperties');
      break;

    case 'Delete':
      getKey = getKeyPermission('Delete');
      break;

    case 'Permission':
      getKey = getKeyPermission('ViewPermission');
      break;

    case 'AddPermission':
    case 'UpdatePermission':
    case 'DeletePermission':
      getKey = getKeyPermission('EditPermission');
      break;

    case 'ChangeOwner':
      getKey = getKeyPermission('ChangeOwner');
      break;

    case 'Move':
      getKey = getKeyPermission('Move');
      break;

    default: return false;
  }

  if (menuParentPermission.includes(menu)) {
    let key = 'parent' + capitalizeFirstLetter(getKey);
    return !info[key];
  }

  return !info[getKey];
}

import { runInAction, makeAutoObservable } from "mobx";
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

import { MIME_TYPES, MIME_TYPE_DEFAULT } from '../constants/mimeTypes';
import { toLowerCase } from '../utils/formatters';

class FileActionStore {
  info = {};
  filePDF = '';
  filePDFUrl = '';
  fileImageUrl = '';
  infoPermission = {};
  currentInfo = {};

  isShowCreateFolder = false;
  isShowUploadFile = false;
  isShowRename = false;
  isShowMove = false;
  isShowDelete = false;
  isShowDetail = false;
  isShowChangeOwner = false;
  isShowViewLog = false;
  isShowUpdateTag = false;
  isShowPDF = false;
  isShowImage = false;
  isShowApprove = false;
  isShowConfirmOCR = false;
  isShowApproveOCR = false;
  isShowPermission = false;
  isShowOCR = false;
  isShowEditOCR = false;
  isShowCancelOCR = false;
  isShowDeletePermission = false;
  isShowCancelRequestOCR = false;
  isShowApproveMultiple = false;

  currentFolderTree = null;
  submitRenameFunction = null;
  submitMoveFunction = null;
  submitDeleteFunction = null;
  getFolderTreeFunction = null;
  getFileFunction = null;
  downloadFileFunction = null;
  submitApproveFunction = null;
  getDetailFunction = null;
  submitChangeOwnerFunction = null;
  getViewLogFunction = null;
  getTagFunction = null;
  submitUpdateTagFunction = null;
  checkUserIsExistedFunction = null;
  suggestExistingUserFunction = null;
  submitUpdatePermissionFunction = null;
  submitCreatePermissionFunction = null;
  getPermissionFunction = null;
  submitDeletePermissionFunction = null;
  getDocumentInfoFunction = null;
  submitDocumentApproveFunction = null;

  constructor() {
    makeAutoObservable(this);
  }

  setInfo(info) {
    this.info = info;
  }

  setInfoPermission(info) {
    this.infoPermission = info;
  }

  setCurrentInfo(info) {
    this.currentInfo = info;
  }

  setShowModal = (type, show) => {
    this['isShow' + type] = show;
  };
  
  setSubmitRenameFunction(f) {
    this.submitRenameFunction = f;
  }

  setSubmitMoveFunction(f) {
    this.submitMoveFunction = f;
  }

  setSubmitDeleteFunction(f) {
    this.submitDeleteFunction = f;
  }

  setSubmitApproveFunction(f) {
    this.submitApproveFunction = f;
  }

  setGetDetailFunction(f) {
    this.getDetailFunction = f;
  }

  setSubmitChangeOwnerFunction(f) {
    this.submitChangeOwnerFunction = f;
  }

  setGetViewLogFunction(f) {
    this.getViewLogFunction = f;
  }

  setGetFolderTreeFunction(f) {
    this.getFolderTreeFunction = f;
  }

  setGetFileFunction(f) {
    this.getFileFunction = f;
  }

  setDownloadFileFunction(f) {
    this.downloadFileFunction = f;
  }

  setGetTagFunction(f) {
    this.getTagFunction = f;
  }

  setSubmitUpdateTagFunction(f) {
    this.submitUpdateTagFunction = f;
  }

  setCheckUserIsExistedFunction(f) {
    this.checkUserIsExistedFunction = f;
  }

  setSuggestExistingUserFunction(f) {
    this.suggestExistingUserFunction = f;
  }

  setSubmitUpdatePermissionFunction(f) {
    this.submitUpdatePermissionFunction = f;
  }

  setSubmitCreatePermissionFunction(f) {
    this.submitCreatePermissionFunction = f;
  }

  setSubmitDeletePermissionFunction(f) {
    this.submitDeletePermissionFunction = f;
  }

  setGetPermissionFunction(f) {
    this.getPermissionFunction = f;
  }

  setGetDocumentInfoFunction(f) {
    this.getDocumentInfoFunction = f;
  }

  setSubmitDocumentApproveFunction(f) {
    this.submitDocumentApproveFunction = f;
  }

  setFileImageUrl(url) {
    runInAction(() => {
      this.fileImageUrl = url;
    })
  }

  clearInfo() {
    this.info = {};
    this.currentInfo = {};
  }

  clearFolderTree() {
    this.currentFolderTree = null;
  }

  closeAllModals() {
    this.isShowCreateFolder = false;
    this.isShowUploadFile = false;
    this.isShowRename = false;
    this.isShowMove = false;
    this.isShowDelete = false;
    this.isShowDetail = false;
    this.isShowChangeOwner = false;
    this.isShowViewLog = false;
    this.isShowUpdateTag = false;
    this.isShowPDF = false;
    this.isShowImage = false;
    this.isShowApprove = false;
    this.isShowPermission = false;
    this.isShowConfirmOCR = false;
    this.isShowApproveOCR = false;
    this.isShowOCR = false;
    this.isShowEditOCR = false;
    this.isShowCancelOCR = false;
    this.isShowDeletePermission = false;
    this.isShowCancelRequestOCR = false;
    this.isShowApproveMultiple = false;

    this.clearInfo();
    this.clearFolderTree();
  }

  closeModalViewFile() {
    this.isShowPDF = false;
    this.isShowImage = false;
  }

  async handleSubmitRename(name) {
    const params = {
      id: this.info.id,
      name: name
    }

    const response = await this.submitRenameFunction(params);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitMove(folderDesId) {
    const params = {
      itemSrcId: this.info.id,
      folderDesId
    }

    const response = await this.submitMoveFunction(params);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitDelete() {
    const response = await this.submitDeleteFunction(this.info.id);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitApprove() {
    const response = await this.submitApproveFunction(this.info.id);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async getDetail() {
    const response = await this.getDetailFunction(this.info.id);
    if (response) {
      return {
        data: response,
        success: true
      };
    } else {
      return { success: false };
    }
  }

  async getFolderTree(id, rootId) {
    const params = {
      folderId: rootId,
      sortBy: '',
      sortOrder: '',
      type: 0
    }

    const response = await this.getFolderTreeFunction(params);
    if (response) {
      runInAction(() => {
        this.currentFolderTree = response;

        if (response.data) {
          this.currentFolderTree.data = response.data.filter(folder => folder.id !== id);
        }
      });
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleDownloadFile(id) {
    let extension = toLowerCase(this.info.extension);

    if (!id) {
      toast.error('Không có file id');
      return { success: false };
    }

    if (!this.info || !this.info.displayName || !extension) {
      toast.error('Thông tin file không hợp lệ');
      return { success: false };
    }

    const fileData = await this.downloadFileFunction(id);

    if (!fileData) {
      return { success: false };
    }

    const mimeType = MIME_TYPES[extension] || MIME_TYPE_DEFAULT;
    const file = new Blob([fileData], { type: mimeType });
    const fileName = `${this.info.displayName}.${this.info.extension}`;

    saveAs(file, fileName);

    return { success: true };
  }

  async handleShowFilePDF(id) {
    let extension = toLowerCase(this.info.extension);

    if (!id) {
      toast.error('Không có file id');
      return { success: false };
    }

    if (!this.info || !this.info.displayName || !extension) {
      toast.error('Thông tin file không hợp lệ');
      return { success: false };
    }

    const fileData = await this.getFileFunction(id);

    if (!fileData) {
      return { success: false };
    }

    const file = new Blob([fileData], { type: MIME_TYPES['pdf'] });
    const fileURL = URL.createObjectURL(file);
    runInAction(() => {
      this.filePDFUrl = fileURL;
    });

    const reader = new FileReader();
    reader.readAsDataURL(file); 
    reader.onloadend = () => {
      var base64data = reader.result;
      runInAction(() => {
        this.filePDF = base64data;
        this.isShowPDF = true;
      });
    }

    return { success: true };
  }

  async handleShowFileImage(id, isShowImage = true) {
    let extension = toLowerCase(this.info.extension);

    if (!id) {
      toast.error('Không có file id');
      return { success: false };
    }

    if (!this.info || !extension || MIME_TYPES[extension].indexOf('image/') < 0) {
      toast.error('Thông tin file không hợp lệ');
      return { success: false };
    }

    const fileData = await this.getFileFunction(id);

    if (!fileData) {
      return { success: false };
    }

    const file = new Blob([fileData], { type: MIME_TYPES[extension] });
    const fileURL = URL.createObjectURL(file);
    runInAction(() => {
      this.fileImageUrl = fileURL;
      this.isShowImage = isShowImage;
    });

    return { success: true };
  }

  async handleSubmitChangeOwner(data) {
    const params = {
      id: this.info.id,
      userNameOrMail: data
    }

    const response = await this.submitChangeOwnerFunction(params);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async getViewLog() {
    const response = await this.getViewLogFunction(this.info.id);
    if (response && response.length) {
      return {
        data: response,
        success: true
      };
    } else {
      return { success: false };
    }
  }

  async getTag() {
    const response = await this.getTagFunction(this.info.id);
    if (response) {
      return {
        data: response,
        success: true
      };
    } else {
      return { success: false };
    }
  }

  async handleSubmitUpdateTag(tag) {
    const params = {
      id: this.info.id,
      tags: tag
    }

    const response = await this.submitUpdateTagFunction(params);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async checkUserIsExisted(params) {
    params = params.length ? params.trim() : '';
    const response = await this.checkUserIsExistedFunction(params);
    if (response && response.isExisted) {
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async suggestExistingUser(params) {
    params = params.length ? params.trim() : '';

    if (this.suggestExistingUserFunction) {
      const response = await this.suggestExistingUserFunction(params);
      if (response) {
        return {
          data: response.data,
          success: true
        };
      } else {
        return { success: false };
      }
    }
  }

  async handleSubmitUpdatePermission(params) {
    const response = await this.submitUpdatePermissionFunction(params);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitCreatePermission(params) {
    const response = await this.submitCreatePermissionFunction(this.info.id, params);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async handleSubmitDeletePermission() {
    const response = await this.submitDeletePermissionFunction(this.infoPermission.permissionId);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async getPermission() {
    const response = await this.getPermissionFunction(this.info.id);
    if (response) {
      return {
        data: response.data,
        success: true
      };
    } else {
      return { success: false };
    }
  }

  async getDocumentInfo(id) {
    const response = await this.getDocumentInfoFunction(id);
    if (response) {
      return {
        data: response,
        success: true
      };
    } else {
      return { success: false };
    }
  }

  async handleSubmitDocumentApprove(data) {
    if (!data?.ids?.length) {
      toast.error('Vui lòng chọn file/folder cần duyệt');
      return { success: false };
    }

    const response = await this.submitDocumentApproveFunction(data);
    if (response) {
      toast.success(response.message);
      return { success: true };
    } else {
      return { success: false };
    }
  }
}

export default FileActionStore;
import './App.css';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

import AppRouter from './routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
});

function App() {
  const keycloakProviderInitConfig = {
    onLoad: "login-required"
  }

  function onKeycloakEvent(event, error) {
    if (error) console.log(error);
    console.log('onKeycloakEvent', event);
  }

  function onKeycloakTokens(tokens) {
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <AppRouter></AppRouter>
    </ReactKeycloakProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, useHistory, useParams } from "react-router-dom";

import { useStore } from '../../stores';
import { formatNameFileFolder } from '../../utils/formatters';
import FileActionPopup from '../FileActionPopup';
import { PAGE_LIST } from '../../constants/pages';

// in case of trouble with cdn, replace it with local file located at /js/pdf.worker.js (version 2.6.347)
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalWrapper: {
    width: '100%',
    height: 'calc(100vh - 63px)',
    marginBottom: '20px',
    position: 'absolute',
    top: '63px',
    overflowY: 'scroll',
    '&:hover': {
      '& $pageControls': {
        opacity: 1
      }
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#6e6e6e'
    },
    '&::-webkit-scrollbar': {
      width: '12px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#F5F5F5'
    }
  },
  modalDialog: {
    width: '1000px',
    maxWidth: 'unset',
    margin: '0 auto !important'
  },
  modalHeader: {
    width: '100%',
    position: 'fixed',
    top: '0px',
    left: '0px',
    zIndex: '1',
    background: '#e7e7e7',
    fontWeight: 'bold',
    borderRadius: 0
  },
  modalContent: {
    position: 'relative',
    borderRadius: 0
  },
  fileDetail: {
    minHeight: 'calc(100vh - 63px)'
  },
  pageControls: {
    position: 'absolute',
    bottom: '2%',
    left: '50%',
    background: '#fff',
    opacity: 0.6,
    transform: 'translateX(-50%)',
    transition: 'opacity ease-in-out 0.2s',
    boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
    borderRadius: '4px',
  },
  pageControlsButton: {
    width: '44px',
    height: '44px',
    background: '#fff',
    border: 0,
    font: 'inherit',
    fontSize: '1.2em',
    borderRadius: '4px',
    '&:enabled&:hover': {
      backgroundColor: '#e6e6e6'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  pageControlsSpan: {
    font: 'inherit',
    fontSize: '1.2em',
    padding: '0 0.5em',
  },
  externalLink: {
    display: 'inline-block',
    marginLeft: '8px'
  }
}));

function NextIcon() {
  return (
    <svg className=" a-s-fa-Ha-pa" width="24px" height="24px" viewBox="0 0 24 24" focusable="false" fill="#000000">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  );
}

const ViewPDF = observer((props) => {
  const { fileActionStore } = useStore();
  const [numPages, setNumPages] = useState(null);
  const [info, setInfo] = useState({});
  const [breadcrumb, setBreadcrumb] = useState({});

  const classes = useStyles();
  const history = useHistory();
  const getParam = useParams();

  const idFolder = getParam.id || '';
  const id = fileActionStore.info.id;
  const openModal = fileActionStore.isShowPDF;

  const handleCloseModal = (e) => {
    e.preventDefault();

    fileActionStore.setShowModal('PDF', false);
    setNumPages(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const handleClickBreadcrumb = (e) => {
    e.preventDefault();

    if (breadcrumb.id === idFolder) {
      handleCloseModal(e);
    } else {
      history.push(breadcrumb.uri);
    }
  }

  useEffect(() => {
    async function getDocInfo() {
      let response = await fileActionStore.getDocumentInfo(id);
      if (response.success) {
        const page = PAGE_LIST.find(page => page.folderType === response.data?.folderTypeRoot);

        if (page && response.data?.breadCrumb?.length) {
          let res_data_breadcrumb = response.data.breadCrumb;
          res_data_breadcrumb.forEach((item, index) => {
            item.uri = index === 0 ? page.uri : `${page.uri}/folder/${item.id}`;
          })
          setBreadcrumb(res_data_breadcrumb[res_data_breadcrumb.length - 1]);
        }

        setInfo({ ...fileActionStore.info, ...response.data });
      }
    }

    if (openModal && id) {
      getDocInfo();
    }

    return () => {
      setInfo({});
    }
    // eslint-disable-next-line
  }, [openModal, id])

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <>
              <div className={`modal-header align-center border-bottom-0 ${classes.modalHeader}`}>
                <div className="nk-file-title">
                  <Link
                    style={{ color: '#526484' }}
                    key={breadcrumb.id}
                    to={breadcrumb.uri || '# '}
                    onClick={handleClickBreadcrumb}
                  >
                    <span>{breadcrumb.name}</span>
                  </Link>
                  <NextIcon />
                  {formatNameFileFolder(fileActionStore.info)}
                  <FileActionPopup info={info} icon={'ni-caret-down-fill'}></FileActionPopup>
                  {fileActionStore.filePDFUrl && (
                    <a
                      href={fileActionStore.filePDFUrl}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.externalLink}
                    >
                      <Tooltip title="Mở file trong tab mới">
                        <em className="icon ni ni-external-alt" style={{ fontWeight: 'bold' }}></em>
                      </Tooltip>
                    </a>
                  )}
                </div>
                <a href="# " className="close" onClick={handleCloseModal}>
                  <em className="icon ni ni-cross-sm"></em>
                </a>
              </div>
              <div className={classes.modalWrapper} tabIndex="-1" role="dialog" id="file-details" aria-modal="true" onClick={handleCloseModal}>
                <div className={`modal-dialog modal-md ${classes.modalDialog}`} role="document">
                  <div className={`modal-content view-pdf ${classes.modalContent}`}>
                    <div className={`nk-file-details ${classes.fileDetail}`} onClick={handleChildClick}>
                      {fileActionStore.filePDF && (
                        <Document
                          file={fileActionStore.filePDF}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          {numPages && (
                            Array.apply(null, Array(numPages)).map((v, i) => (
                              <Page key={i} pageNumber={i + 1} width={1000} />
                            ))
                          )}
                        </Document>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Fade>
        </Modal>
      </ul>
    </div>
  );
})

export default ViewPDF;
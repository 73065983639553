import { runInAction, makeAutoObservable } from "mobx";
import { splitArray } from "../utils/functions";
class NotificationStore {
  isSendToken = false;
  tokenFirebase = null;
  refreshListNoti = true;
  isShowViewAll = false;
  notifications = [];
  splitNotifications = [];
  totalNotifications = 0;

  getNotificationFunction = null;
  seenNotificationFunction = null;
  seenAllNotificationFunction = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsSendToken(send) {
    this.isSendToken = send;
  }

  setTokenFirebase(token) {
    this.tokenFirebase = token;
  }

  enableRefreshListNoti() {
    runInAction(() => {
      this.refreshListNoti = true;
    });
  }

  disableRefreshListNoti() {
    runInAction(() => {
      this.refreshListNoti = false;
    });
  }

  setListNotification(list) {
    const hasNoti = list && Array.isArray(list);

    runInAction(() => {
      this.notifications = hasNoti ? list : [];
      this.totalNotifications = hasNoti ? list.filter(t => !t.isViewed).length : 0;
    });
  }

  getSplitNotification() {
    this.splitNotifications = splitArray(this.notifications, 3);
  }

  getAllNotification() {
    this.splitNotifications = this.notifications;
  }

  getListNotification(type = 'click') {
    if (type === 'click') this.isShowViewAll = !this.isShowViewAll;
    this.isShowViewAll ? this.getAllNotification() : this.getSplitNotification();
  }

  setGetNotificationFunction(f) {
    runInAction(() => {
      this.getNotificationFunction = f;
    })
  }

  setSeenNotificationFunction(f) {
    runInAction(() => {
      this.seenNotificationFunction = f;
    })
  }

  setSeenAllNotificationFunction(f) {
    runInAction(() => {
      this.seenAllNotificationFunction = f;
    })
  }

  async getNotificationList() {
    this.setListNotification([]);

    const response = await this.getNotificationFunction();

    if (response) {
      this.setListNotification(response.data);
      this.getListNotification('noClick');
    }

    this.disableRefreshListNoti();
  }

  async submitSeenNotification(id) {
    const response = await this.seenNotificationFunction(id);

    if (response) {
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async submitSeenAllNotification() {
    const response = await this.seenAllNotificationFunction();

    if (response) {
      return { success: true };
    } else {
      return { success: false };
    }
  }

}

export default NotificationStore;
import { createContext, useContext } from 'react';
import UserStore from './user.store';
import ViewStore from './view.store';
import UploadStore from './upload.store';
import FileActionStore from './file-action.store';
import OCRStore from './ocr.store';
import SearchStore from './search.store';
import NotificationStore from './notification.store';

class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.viewStore = new ViewStore(this);
    this.uploadStore = new UploadStore(this);
    this.fileActionStore = new FileActionStore(this);
    this.OCRStore = new OCRStore(this);
    this.searchStore = new SearchStore(this);
    this.notificationStore = new NotificationStore(this);
  }
}

export const rootStore = new RootStore();

export const RootStoreContext = createContext(null);

export const Provider = RootStoreContext.Provider;

export function useStore() {
  const store = useContext(RootStoreContext);

  if (!store) {
    throw new Error("Store is not available");
  }

  return store;
}
import React from 'react';
import { Link } from "react-router-dom";

const PageNotFound = (() => {
  return (
    <div className="nk-main">
      <div className="nk-wrap nk-wrap-nosidebar">
        <div className="nk-content">
          <div className="nk-block nk-block-middle wide-md mx-auto">
            <div className="nk-block-content nk-error-ld text-center">
              <img className="nk-error-gfx" src="/images/error-404.svg" alt="" />
              <div className="wide-xs mx-auto">
              <h3 className="nk-error-title">Không tìm thấy trang</h3>
              <p className="nk-error-text">
                Chúng tôi rất xin lỗi vì sự bất tiện này. Có vẻ như bạn đang cố truy cập một trang đã bị xóa hoặc không tồn tại.
              </p>
              <Link
                className="btn btn-lg btn-primary mt-2"
                to="/"
              >
                <span>Quay lại trang chủ</span>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PageNotFound;
import firebase from 'firebase/app';
import 'firebase/messaging';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(firebaseConfig);

export const isFCMSupported = firebase.messaging.isSupported();

const messaging = isFCMSupported ? firebase.messaging() : null;

export const onMessageListener = callback => messaging.onMessage(payload => callback(payload));

export const requestPermission = async () => {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  }

  if (Notification.permission === "default") {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        console.log('Notification permission was granted');
      }
    });
  }
}

export const getTokenFirebase = async () => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VALID_PUBLIC_KEY });
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }

  return currentToken;
};

export const deleteTokenFirebase = async () => {
  try {
    const result = await messaging.deleteToken();
    console.log("Token was deleted.", result);
  } catch (error) {
    console.log("An error occurred while delete token.", error);
  }
}

export default firebase;
import React from 'react';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useDebounce from '../../hooks/debound';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Draggable from "react-draggable";
import { Autocomplete } from '@material-ui/lab';

import { useStore } from '../../stores';
import SVGIcon from '../SVGIcon';
import { formatNameFileFolder } from '../../utils/formatters';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertCustom: {
    padding: '0.5rem 3.25rem',
    marginTop: '0.5rem',
    marginBottom: '0rem'
  }
}));

const ChangeOwner = observer((props) => {
  const { viewStore, fileActionStore } = useStore();
  const [errorMessage, setErrorMessage] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [suggestExistingUsers, setSuggestExistingUsers] = useState([]);
  const debouncedSearchTerm = useDebounce(inputEmail, 600);

  const classes = useStyles();

  const info = fileActionStore.info;
  const openModal = fileActionStore.isShowChangeOwner;

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setShowModal('ChangeOwner', false)
  };

  const handleClickReturn = (e) => {
    fileActionStore.setShowModal('Detail', true);
    handleCloseModal(e);
  };

  const handleClickSubmit = async (e) => {
    let value = inputEmail.length ? inputEmail.trim() : '';
    const resultSubmit = await fileActionStore.handleSubmitChangeOwner(value);

    if (resultSubmit?.success) {
      viewStore.enableRefreshList();
      handleCloseModal(e);
    }
  }

  const handleSuggestExistingUser = async (value) => {
    if (!value) {
      setSuggestExistingUsers([{ username: '' }]);
      return { success: false }
    }

    const resultSubmit = await fileActionStore.suggestExistingUser(value);

    let originData = [];
    if (resultSubmit && resultSubmit.data) {
      originData = resultSubmit.data.map(item => `${item.username} - ${item.email}`)
    }

    const suggestData = inputEmail ? originData.filter(item => inputEmail !== item.value) : originData;
    setSuggestExistingUsers(suggestData);

    return resultSubmit;
  }

  // const handleCheckIsExisted = async (value) => {
  //   if (value) {
  //     const resultSubmit = await fileActionStore.checkUserIsExisted(value);

  //     if (resultSubmit && !resultSubmit.success) {
  //       setErrorMessage('Tài khoản này không tồn tại trong hệ thống');
  //     }
  //   }
  // }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Khi có attribute aria-activedescendant thì là user đang chọn option từ gợi ý
      if (inputEmail?.length && !event.target.getAttribute('aria-activedescendant')) {
        handleClickSubmit(event);
      }
    }
  }

  useEffect(() => {
    if (openModal) {
      setInputEmail('');
      setErrorMessage('');
      setSuggestExistingUsers([]);
    }
  }, [openModal])

  useEffect(() => {
    setErrorMessage('');
    if (debouncedSearchTerm) {
      // handleCheckIsExisted(debouncedSearchTerm);
      handleSuggestExistingUser(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
         <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="file-copy" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <div className="nk-file-title">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <SVGIcon ext={info.extension || 'folder'}></SVGIcon>
                          </span>
                        </div>
                        <div className="nk-file-name">
                          <div className="nk-file-name-text">
                            <span className="title">
                              {formatNameFileFolder(info)} {'>> Đổi sở hữu'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-0 mt-n2">
                      <Autocomplete
                        id="input-email"
                        freeSolo
                        style={{
                          width: '452px',
                          lineHeight: '47px',
                          marginTop: '10px',
                          marginBottom: 0
                        }}
                        options={suggestExistingUsers}
                        onChange={(event, selected) => {
                          if (selected) {
                            const selectedEmail = selected.split(' - ')[1]?.trim() || '';
                            setInputEmail(selectedEmail);
                          }
                        }}
                        renderInput={(params) => {
                          params.inputProps.onKeyDown = handleKeyDown;
                          return (
                            <TextField
                              {...params}
                              placeholder="Email hoặc tên đăng nhập"
                              size="small"
                              variant="outlined"
                              style={{ marginBottom: '10px' }}
                              onInput={e => setInputEmail(e.target.value)}
                            />
                          );
                        }}
                      />
                      {errorMessage && (
                        <div className={`alert alert-icon alert-danger ${classes.alertCustom}`} role="alert">
                          <em className="icon ni ni-alert-circle" style={{ top: '0.6rem'}}></em>
                          {errorMessage}
                        </div>
                      )}
                      <div className="nk-modal-action d-flex">
                        <div className="align-center mr-auto">
                          <a href="# " className="link link-light" onClick={handleClickReturn}>
                            <em className="icon ni ni-curve-down-left"></em>
                            Quay lại
                          </a>
                        </div>
                        <div>
                          <ul className="btn-toolbar g-4 align-center">
                            <li><a href="# " className="link link-primary" onClick={handleCloseModal}> Bỏ qua </a></li>
                            <li><button className="btn btn-primary" onClick={handleClickSubmit} disabled={!inputEmail?.length}> Đồng ý </button></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
});

export default ChangeOwner;
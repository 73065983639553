import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

const SVGIcon = observer((props) => {
  const { ext } = props;
  const extension = ext ? ext.toLowerCase() : ext;

  const imageIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
      <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#755de0' }} />
      <path d="M27.2223,43H44.7086s2.325-.2815.7357-1.897l-5.6034-5.4985s-1.5115-1.7913-3.3357.7933L33.56,40.4707a.6887.6887,0,0,1-1.0186.0486l-1.9-1.6393s-1.3291-1.5866-2.4758,0c-.6561.9079-2.0261,2.8489-2.0261,2.8489S25.4268,43,27.2223,43Z" style={{ fill: '#fff' }} />
      <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#b5b3ff' }} />
    </svg>
  );

  let component;
  switch (extension) {
    case 'folder':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72 72">
          <path fill="#6C87FE"
            d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10	C60,29.9,58.9,31,57.5,31z">
          </path>
          <path fill="#8AA3FF"
            d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z">
          </path>
          <path display="none" fill="#8AA3FF"
            d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z">
          </path>
          <path fill="#798BFF" d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z">
          </path>
        </svg>
      );
      break;
    case 'foldersecure':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72 72">
          <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" fill="#6c87fe"/>
          <path fill="#8aa3ff"
            d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z" />
          <path fill="#798bff"
            d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z" />
          <path fill="#4b66bc"
            d="M42.2227,40H41.5V37.4443a5.5,5.5,0,0,0-11,0V40h-.7227A2.8013,2.8013,0,0,0,27,42.8184v6.3633A2.8013,2.8013,0,0,0,29.7773,52H42.2227A2.8013,2.8013,0,0,0,45,49.1816V42.8184A2.8013,2.8013,0,0,0,42.2227,40ZM36,48a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,36,48Zm3.5-8h-7V37.4443a3.5,3.5,0,0,1,7,0Z" />
        </svg>
      );
      break;
    case 'foldershared':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72 72">
          <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" fill="#6c87fe" />
          <path fill="#8aa3ff"
            d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z" />
          <path fill="#798bff"
            d="M7.7454,58.9807C9.9352,61.3864,12.4882,61.0163,14,61H59a6.3373,6.3373,0,0,0,5.2562-2.0193Z" />
          <path fill="#4b66bc"
            d="M29.6309,37.36a3.0236,3.0236,0,0,1-.86-2.39A4.3748,4.3748,0,0,1,32.9961,31h.0078a4.36,4.36,0,0,1,4.22,3.9121,3.0532,3.0532,0,0,1-.8545,2.4482A4.4158,4.4158,0,0,1,33.23,38.53c-.0771,0-.1533-.002-.23-.0049A4.519,4.519,0,0,1,29.6309,37.36Zm13.8359,2.7549a1,1,0,1,0-.9336,1.77c.7207.38,1.4658,2.126,1.4668,4.39V48a1,1,0,0,0,2,0V46.2744C45.999,43.3447,45.0049,40.9268,43.4668,40.1152ZM40.165,37.3965c-.1445.084-.29.168-.4316.2549a1,1,0,0,0,.5215,1.8535.9887.9887,0,0,0,.52-.1465c.1289-.0781.2607-.1543.3916-.23a4.2311,4.2311,0,0,0,2.1465-2.124.9839.9839,0,0,0,.0313-.1045A3.8411,3.8411,0,0,0,40.5,32.5352a1,1,0,0,0-.4922,1.9395,1.8773,1.8773,0,0,1,1.4,1.9092A2.835,2.835,0,0,1,40.165,37.3965ZM36.5,41h-7c-2.5234,0-4.5,2.7822-4.5,6.333V48.5a.8355.8355,0,0,0,.0588.2914.9731.9731,0,0,0,.3508.4946C26.4646,50.2812,29.4614,51,33,51s6.5353-.7187,7.59-1.7139a.9726.9726,0,0,0,.3509-.4949A.8361.8361,0,0,0,41,48.5V47.333C41,43.7822,39.0234,41,36.5,41Z" />
        </svg>
      );
      break;
    case 'folder_v2':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72 72">
          <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" fill="#f29611" />
          <path fill="#ffb32c"
            d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z" />
          <path fill="#f2a222"
            d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z" />
        </svg>
      );
      break;
    case 'foldersecure_v2':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72 72">
          <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" style={{ fill: '#f29611' }} />
          <path d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z" style={{ fill: '#ffb32c' }} />
          <path d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z" style={{ fill: '#f2a222' }} />
          <path d="M42.2227,40H41.5V37.4443a5.5,5.5,0,0,0-11,0V40h-.7227A2.8013,2.8013,0,0,0,27,42.8184v6.3633A2.8013,2.8013,0,0,0,29.7773,52H42.2227A2.8013,2.8013,0,0,0,45,49.1816V42.8184A2.8013,2.8013,0,0,0,42.2227,40ZM36,48a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,36,48Zm3.5-8h-7V37.4443a3.5,3.5,0,0,1,7,0Z" style={{ fill: '#c67424' }} />
        </svg>
      );
      break;
    case 'foldershared_v2':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72 72">
          <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" style={{ fill: '#f29611' }} />
          <path d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z" style={{ fill: '#ffb32c' }} />
          <path d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z" style={{ fill: '#f2a222' }} />
          <path d="M29.6309,37.36a3.0236,3.0236,0,0,1-.86-2.39A4.3748,4.3748,0,0,1,32.9961,31h.0078a4.36,4.36,0,0,1,4.22,3.9121,3.0532,3.0532,0,0,1-.8545,2.4482A4.4158,4.4158,0,0,1,33.23,38.53c-.0771,0-.1533-.002-.23-.0049A4.519,4.519,0,0,1,29.6309,37.36ZM43.4668,40.1a1,1,0,1,0-.9336,1.77c.7207.38,1.4658,2.126,1.4668,4.39v1.7256a1,1,0,0,0,2,0V46.26C45.999,43.33,45.0049,40.9119,43.4668,40.1ZM40.165,37.3816c-.1445.084-.29.168-.4316.2549a1,1,0,0,0,.5215,1.8535.9887.9887,0,0,0,.52-.1465c.1289-.0781.2607-.1543.3916-.23a4.2311,4.2311,0,0,0,2.1465-2.124.9839.9839,0,0,0,.0313-.1045A3.8411,3.8411,0,0,0,40.5,32.52a1,1,0,1,0-.4922,1.9395,1.8773,1.8773,0,0,1,1.4,1.9092A2.835,2.835,0,0,1,40.165,37.3816ZM36.5,41h-7c-2.5234,0-4.5,2.7822-4.5,6.333V48.5a.8355.8355,0,0,0,.0588.2914.9731.9731,0,0,0,.3508.4946C26.4646,50.2812,29.4614,51,33,51s6.5353-.7187,7.59-1.7139a.9726.9726,0,0,0,.3509-.4949A.8361.8361,0,0,0,41,48.5V47.333C41,43.7822,39.0234,41,36.5,41Z" style={{ fill: '#c67424' }} />
        </svg>
      );
      break;
    case 'txt':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#7e95c4' }}>
          </path>
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#b7ccea' }}></path>
          <rect x="27" y="31" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="35" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="39" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="43" width="14" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="47" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
        </svg>
      );
      break;
    case 'xls':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#36c684' }}>
          </path>
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#95e5bd' }}></path>
          <path
            d="M42,31H30a3.0033,3.0033,0,0,0-3,3V45a3.0033,3.0033,0,0,0,3,3H42a3.0033,3.0033,0,0,0,3-3V34A3.0033,3.0033,0,0,0,42,31ZM29,38h6v3H29Zm8,0h6v3H37Zm6-4v2H37V33h5A1.001,1.001,0,0,1,43,34ZM30,33h5v3H29V34A1.001,1.001,0,0,1,30,33ZM29,45V43h6v3H30A1.001,1.001,0,0,1,29,45Zm13,1H37V43h6v2A1.001,1.001,0,0,1,42,46Z"
            style={{ fill: '#fff' }}>
          </path>
        </svg>
      );
      break;
    case 'xlsx':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#36c684' }}>
          </path>
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#95e5bd' }}></path>
          <path
            d="M42,31H30a3.0033,3.0033,0,0,0-3,3V45a3.0033,3.0033,0,0,0,3,3H42a3.0033,3.0033,0,0,0,3-3V34A3.0033,3.0033,0,0,0,42,31ZM29,38h6v3H29Zm8,0h6v3H37Zm6-4v2H37V33h5A1.001,1.001,0,0,1,43,34ZM30,33h5v3H29V34A1.001,1.001,0,0,1,30,33ZM29,45V43h6v3H30A1.001,1.001,0,0,1,29,45Zm13,1H37V43h6v2A1.001,1.001,0,0,1,42,46Z"
            style={{ fill: '#fff' }}>
          </path>
        </svg>
      );
      break;
    case 'ppt':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#f25168' }}>
          </path>
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#ff9fb6' }}></path>
          <path
            d="M44.1405,46H27.8595A1.86,1.86,0,0,1,26,44.1405V34.8595A1.86,1.86,0,0,1,27.8595,33H44.14A1.86,1.86,0,0,1,46,34.86v9.2808A1.86,1.86,0,0,1,44.1405,46ZM29.1454,44H42.8546A1.1454,1.1454,0,0,0,44,42.8546V36.1454A1.1454,1.1454,0,0,0,42.8546,35H29.1454A1.1454,1.1454,0,0,0,28,36.1454v6.7093A1.1454,1.1454,0,0,0,29.1454,44Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M36.4218,34.268a.7112.7112,0,0,1-.5048-.2093l-2.1431-2.1428a.7143.7143,0,0,1,1.01-1.01l2.1428,2.1431a.7142.7142,0,0,1-.5051,1.2192Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M36.4218,34.268a.7142.7142,0,0,1-.5048-1.2192L38.06,30.9057a.7141.7141,0,0,1,1.01,1.01l-2.1426,2.1428A.7113.7113,0,0,1,36.4218,34.268Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M32.1356,49.268a.7054.7054,0,0,1-.3665-.102.7145.7145,0,0,1-.2451-.98l2.1431-3.5713a.7142.7142,0,0,1,1.2247.735l-2.1426,3.5711A.7144.7144,0,0,1,32.1356,49.268Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M40.7083,49.268a.7138.7138,0,0,1-.6129-.3463L37.9526,45.35a.7143.7143,0,0,1,1.225-.735L41.32,48.1866a.7137.7137,0,0,1-.6121,1.0814Z"
            style={{ fill: '#fff' }}></path>
          <path d="M35.12,37H30.9a.5007.5007,0,1,1,0-1h4.22a.5007.5007,0,1,1,0,1Z" style={{ fill: '#fff' }}></path>
          <path d="M41.9758,43H37.5471a.5056.5056,0,1,1,0-1.0065h4.4286a.5056.5056,0,1,1,0,1.0065Z"
            style={{ fill: '#fff' }}></path>
          <path d="M38.14,40H33.9775a.5.5,0,1,1,0-1H38.14a.5.5,0,1,1,0,1Z" style={{ fill: '#fff' }}></path>
        </svg>
      );
      break;
    case 'pptx':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#f25168' }}>
          </path>
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#ff9fb6' }}></path>
          <path
            d="M44.1405,46H27.8595A1.86,1.86,0,0,1,26,44.1405V34.8595A1.86,1.86,0,0,1,27.8595,33H44.14A1.86,1.86,0,0,1,46,34.86v9.2808A1.86,1.86,0,0,1,44.1405,46ZM29.1454,44H42.8546A1.1454,1.1454,0,0,0,44,42.8546V36.1454A1.1454,1.1454,0,0,0,42.8546,35H29.1454A1.1454,1.1454,0,0,0,28,36.1454v6.7093A1.1454,1.1454,0,0,0,29.1454,44Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M36.4218,34.268a.7112.7112,0,0,1-.5048-.2093l-2.1431-2.1428a.7143.7143,0,0,1,1.01-1.01l2.1428,2.1431a.7142.7142,0,0,1-.5051,1.2192Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M36.4218,34.268a.7142.7142,0,0,1-.5048-1.2192L38.06,30.9057a.7141.7141,0,0,1,1.01,1.01l-2.1426,2.1428A.7113.7113,0,0,1,36.4218,34.268Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M32.1356,49.268a.7054.7054,0,0,1-.3665-.102.7145.7145,0,0,1-.2451-.98l2.1431-3.5713a.7142.7142,0,0,1,1.2247.735l-2.1426,3.5711A.7144.7144,0,0,1,32.1356,49.268Z"
            style={{ fill: '#fff' }}></path>
          <path
            d="M40.7083,49.268a.7138.7138,0,0,1-.6129-.3463L37.9526,45.35a.7143.7143,0,0,1,1.225-.735L41.32,48.1866a.7137.7137,0,0,1-.6121,1.0814Z"
            style={{ fill: '#fff' }}></path>
          <path d="M35.12,37H30.9a.5007.5007,0,1,1,0-1h4.22a.5007.5007,0,1,1,0,1Z" style={{ fill: '#fff' }}></path>
          <path d="M41.9758,43H37.5471a.5056.5056,0,1,1,0-1.0065h4.4286a.5056.5056,0,1,1,0,1.0065Z"
            style={{ fill: '#fff' }}></path>
          <path d="M38.14,40H33.9775a.5.5,0,1,1,0-1H38.14a.5.5,0,1,1,0,1Z" style={{ fill: '#fff' }}></path>
        </svg>
      );
      break;
    case 'doc':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 72 72">
          <g>
            <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
              style={{ fill: '#599def' }}></path>
            <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#c2e1ff' }}></path>
            <rect x="27" y="31" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
            <rect x="27" y="36" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
            <rect x="27" y="41" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
            <rect x="27" y="46" width="12" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          </g>
        </svg>
      );
      break;
    case 'docx':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 72 72">
          <g>
            <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
              style={{ fill: '#599def' }}></path>
            <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#c2e1ff' }}></path>
            <rect x="27" y="31" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
            <rect x="27" y="36" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
            <rect x="27" y="41" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
            <rect x="27" y="46" width="12" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          </g>
        </svg>
      );
      break;
    case 'zip':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <g>
            <rect x="16" y="14" width="40" height="44" rx="6" ry="6" style={{ fill: '#7e95c4' }}></rect>
            <rect x="32" y="17" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="22" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="27" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="32" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="37" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <path d="M35,14h2a0,0,0,0,1,0,0V43a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V14A0,0,0,0,1,35,14Z"
              style={{ fill: '#fff' }}></path>
            <path
              d="M38.0024,42H33.9976A1.9976,1.9976,0,0,0,32,43.9976v2.0047A1.9976,1.9976,0,0,0,33.9976,48h4.0047A1.9976,1.9976,0,0,0,40,46.0024V43.9976A1.9976,1.9976,0,0,0,38.0024,42Zm-.0053,4H34V44h4Z"
              style={{ fill: '#fff' }}></path>
          </g>
        </svg>
      );
      break;
    case 'rar':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <g>
            <rect x="16" y="14" width="40" height="44" rx="6" ry="6" style={{ fill: '#7e95c4' }}></rect>
            <rect x="32" y="17" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="22" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="27" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="32" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <rect x="32" y="37" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}>
            </rect>
            <path d="M35,14h2a0,0,0,0,1,0,0V43a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V14A0,0,0,0,1,35,14Z"
              style={{ fill: '#fff' }}></path>
            <path
              d="M38.0024,42H33.9976A1.9976,1.9976,0,0,0,32,43.9976v2.0047A1.9976,1.9976,0,0,0,33.9976,48h4.0047A1.9976,1.9976,0,0,0,40,46.0024V43.9976A1.9976,1.9976,0,0,0,38.0024,42Zm-.0053,4H34V44h4Z"
              style={{ fill: '#fff' }}></path>
          </g>
        </svg>
      );
      break;
    case 'psd':
      component = imageIcon;
      break;
    case 'png':
      component = imageIcon;
      break;
    case 'gif':
      component = imageIcon;
      break;
    case 'jpg':
      component = imageIcon;
      break;
    case 'jpeg':
      component = imageIcon;
      break;
    case 'pjpeg':
      component = imageIcon;
      break;
    case 'tiff':
      component = imageIcon;
      break;
    case 'bmp':
      component = imageIcon;
      break;
    case 'emf':
      component = imageIcon;
      break;
    case 'wmf':
      component = imageIcon;
      break;
    case 'pdf':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#f26b6b' }} />
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#f4c9c9' }} />
          <path d="M46.3342,44.5381a4.326,4.326,0,0,0-2.5278-1.4289,22.436,22.436,0,0,0-4.5619-.3828A19.3561,19.3561,0,0,1,35.82,37.9536a56.5075,56.5075,0,0,0,1.3745-6.0858,2.339,2.339,0,0,0-.4613-1.8444,1.9429,1.9429,0,0,0-1.5162-.753h-.0014a1.6846,1.6846,0,0,0-1.3893.6966c-1.1493,1.5257-.3638,5.219-.1941,5.9457a12.6118,12.6118,0,0,0,.7236,2.1477,33.3221,33.3221,0,0,1-2.49,6.1052,20.3467,20.3467,0,0,0-5.9787,3.4413,2.5681,2.5681,0,0,0-.8861,1.8265,1.8025,1.8025,0,0,0,.6345,1.3056,2.0613,2.0613,0,0,0,1.3942.5313,2.2436,2.2436,0,0,0,1.4592-.5459,20.0678,20.0678,0,0,0,4.2893-5.3578,20.8384,20.8384,0,0,1,5.939-1.1858A33.75,33.75,0,0,0,42.96,47.7858,2.6392,2.6392,0,0,0,46.376,47.55,2.08,2.08,0,0,0,46.3342,44.5381ZM27.6194,49.6234a.8344.8344,0,0,1-1.0847.0413.4208.4208,0,0,1-.1666-.2695c-.0018-.0657.0271-.3147.4408-.736a18.0382,18.0382,0,0,1,3.7608-2.368A17.26,17.26,0,0,1,27.6194,49.6234ZM34.9023,30.848a.343.343,0,0,1,.3144-.1514.6008.6008,0,0,1,.4649.2389.853.853,0,0,1,.1683.6722v0c-.1638.92-.4235,2.381-.8523,4.1168-.0125-.05-.0249-.1-.037-.1506C34.6053,34.0508,34.3523,31.5779,34.9023,30.848ZM33.7231,43.5507a34.9732,34.9732,0,0,0,1.52-3.7664,21.2484,21.2484,0,0,0,2.2242,3.05A21.8571,21.8571,0,0,0,33.7231,43.5507Zm11.7054,2.97a1.3085,1.3085,0,0,1-1.6943.0887,33.2027,33.2027,0,0,1-3.0038-2.43,20.9677,20.9677,0,0,1,2.8346.3335,2.97,2.97,0,0,1,1.7406.9647C45.8377,46.1115,45.6013,46.3483,45.4285,46.5212Z" style={{ fill: '#fff' }} />
        </svg>
      );
      break;
    case 'mp4':
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#f74141' }}>
          </path>
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#ffa9a9' }}></path>
          <path
            d="M46,46.5v-13A3.5042,3.5042,0,0,0,42.5,30h-13A3.5042,3.5042,0,0,0,26,33.5v13A3.5042,3.5042,0,0,0,29.5,50h13A3.5042,3.5042,0,0,0,46,46.5ZM40,45v3H37V45Zm-3-2V37h7v6Zm0-8V32h3v3Zm-2-3v3H32V32Zm0,5v6H28V37Zm0,8v3H32V45Zm7.5,3H42V45h2v1.5A1.5016,1.5016,0,0,1,42.5,48ZM44,33.5V35H42V32h.5A1.5016,1.5016,0,0,1,44,33.5ZM29.5,32H30v3H28V33.5A1.5016,1.5016,0,0,1,29.5,32ZM28,46.5V45h2v3h-.5A1.5016,1.5016,0,0,1,28,46.5Z"
            style={{ fill: '#fff' }}></path>
        </svg>
      );
      break;

    default:
      component = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ fill: '#7e95c4' }}>
          </path>
          <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{ fill: '#b7ccea' }}></path>
          <rect x="27" y="31" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="35" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="39" width="18" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="43" width="14" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
          <rect x="27" y="47" width="8" height="2" rx="1" ry="1" style={{ fill: '#fff' }}></rect>
        </svg>
      );
  }

  return component;
});

SVGIcon.propTypes = {
  ext: PropTypes.string.isRequired
};

export default SVGIcon;
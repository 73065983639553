import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from "react-router-dom";
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { useStore } from '../stores';
import { PAGE_LIST } from '../constants/pages';
import FileActionPopup from './FileActionPopup';
import SVGIcon from './SVGIcon';
import { formatDate, formatViewedDatetime, formatBytes, backgroundSymbol } from '../utils/formatters';
import { isFileApproved, isViewedFileFolder, getFolderIcon, cutNameToSymbol } from '../utils/functions';

function NextIcon() {
  return (
    <svg className=" a-s-fa-Ha-pa" width="20px" height="20px" viewBox="0 0 24 24" focusable="false" fill="#989898">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  );
}

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '170px'
  },
  blur: {
    opacity: '0.3'
  },
  customBreadcrumb: {
    '& .breadcrumb-item, & .breadcrumb-title': {
      fontWeight: 500,
      color: '#364a63',
      lineHeight: 1.4
    }
  }
}));

const Folder = observer((props) => {
  const { info } = props;
  const { viewStore, searchStore } = useStore();
  const classes = useStyles();
  const history = useHistory();

  const classHighlight = viewStore.isShowHighlight[info.id] ? 'highlight-item': '';
  const folderIcon = getFolderIcon(info);

  let folderUri;
  if (info.folderTypeRoot) {
    const page = PAGE_LIST.find(page => page.folderType === info.folderTypeRoot);
    folderUri = page ? `${page.uri}/folder/${info.id}` : '';
  }
  
  if (!folderUri && viewStore.pageName) {
    folderUri = `/${viewStore.pageName}/folder/${info.id}`;
  }

  const renderDisplayName = () => {
    return (
      <Tooltip title={info.displayName}>
        <span>{info.displayName}</span>
      </Tooltip>
    )
  }

  const handleClickFolder = (e) => {
    e.preventDefault();

    if (!isViewedFileFolder(info.permission1)) return;

    const url = folderUri || '/';
    searchStore.setSearched(true)
    history.push(url);
  }

  const handleChangeCheckbox = (event) => {
    viewStore.setIsSelectedCheckbox(info.id, event.target.checked);
  };

  if (viewStore.mode === 'grid') {
    return (
      <div className="nk-file-item nk-file">
        <div className="nk-file-info">
          <div className="nk-file-title">
            <div className={`nk-file-icon ${!isFileApproved(info.isApproved, info.folderTypeRoot) ? classes.blur : ''}`}>
              <Link
                className="nk-file-icon-link"
                to={{ pathname: folderUri || '/# ' }}
                onClick={handleClickFolder}
              >
                <span className="nk-file-icon-type">
                  <SVGIcon ext={folderIcon}></SVGIcon>
                </span>
              </Link>
            </div>
            <div className="nk-file-name">
              <div className="nk-file-name-text">
                <Link
                  className={`title ${classes.titleStyle}`}
                  to={{ pathname: folderUri || '/# ' }}
                  onClick={handleClickFolder}
                >
                  <Tooltip title={info.displayName}>
                    <span>{info.displayName}</span>
                  </Tooltip>
                </Link>
              </div>
            </div>
          </div>
          <ul className="nk-file-desc">
            <li className="date">{formatDate(info.lastViewAt)}</li>
            <li className="size">{formatBytes(info.size)}</li>
            <li className="members">{info.members ? info.members.length : ''}</li>
          </ul>
        </div>
        <div className="nk-file-actions">
          <FileActionPopup info={info}></FileActionPopup>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`nk-file-item nk-file ${classHighlight}`}>
        <div className="nk-file-info">
          <div
            className="nk-file-title"
            style={{ marginLeft: viewStore.isShowCheckbox ? '-15px' : '' }}
          >
            {viewStore.isShowCheckbox && (
              <span>
                <Checkbox
                  checked={viewStore.isSelectedCheckbox[info.id] || false}
                  onChange={handleChangeCheckbox}
                  color="primary"
                />
              </span>
            )}
            <div className={`nk-file-icon ${!isFileApproved(info.isApproved, info.folderTypeRoot) ? classes.blur : ''}`}>
              <span className="nk-file-icon-type">
                <SVGIcon ext={folderIcon}></SVGIcon>
              </span>
            </div>
            <div className="nk-file-name">
              <div className="nk-file-name-text">
                <Link
                  className="title"
                  to={{ pathname: folderUri || '/# ' }}
                  onClick={handleClickFolder}
                >
                  {viewStore.isShowBreadcrumb && (
                    info.breadCrumb && info.breadCrumb.length > 0 ? (
                      <Breadcrumbs
                        separator={<NextIcon />}
                        maxItems={5}
                        itemsBeforeCollapse={3}
                        aria-label="breadcrumb"
                        className={`breadcrumb-alt ${classes.customBreadcrumb}`}
                      >
                        {info.breadCrumb?.map((item, index) => (
                          <Typography color="textSecondary" key={item.id} className="breadcrumb-item">
                            {item.name}
                          </Typography>
                        ))}
                        <Typography color="textPrimary" className="breadcrumb-title">
                          {renderDisplayName()}
                        </Typography>
                      </Breadcrumbs>
                    ) : (
                      renderDisplayName()
                    )
                  )}
                  {!viewStore.isShowBreadcrumb && renderDisplayName()}
                </Link>
              </div>
              {!isFileApproved(info.isApproved, info.folderTypeRoot) && (
                <div className="tb-sub" style={{ marginTop: '-10px' }}>
                  Chờ duyệt folder mới
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="nk-file-meta">
          <div className="tb-lead">{formatViewedDatetime(info.lastViewAt)}</div>
        </div>
        <div className="nk-file-members">
          {info.userPermissions && info.userPermissions.length > 0 ? (
            <div className="user-avatar-group tb-lead">
              {info.userPermissions.map((item, index) => (
                index <= 3 && (
                  <div key={index} className={`user-avatar xs ${backgroundSymbol(index)}`}>
                    {index < 3 && (
                      <Tooltip title={item.fullName}>
                        <span> {cutNameToSymbol(item.fullName)} </span>
                      </Tooltip>
                    )}
                    {index === 3 && <span> +{info.userPermissions.length - 3} </span>}
                  </div>
                )
              ))}
            </div>
          ) : (
            <div className="tb-lead"></div>
          )}
        </div>
        <div className="nk-file-actions">
          <FileActionPopup info={info}></FileActionPopup>
        </div>
      </div>
    )
  }
});

Folder.propTypes = {
  info: PropTypes.object.isRequired
};

export default Folder;
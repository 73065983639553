import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Draggable from "react-draggable";

import { useStore } from '../../stores';
import { formatNameFileFolder } from '../../utils/formatters';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const UpdateTag = observer(() => {
  const { viewStore, fileActionStore } = useStore();
  const [inputTag, setInputTag] = useState([]);
  const valueRefTag = useRef(null);
  const classes = useStyles();

  const info = fileActionStore.info;
  const modalTitle = 'Tag (' + formatNameFileFolder(info) + ')';
  const openModal = fileActionStore.isShowUpdateTag;

  useEffect(() => {
    async function getTag() {
      let response = await fileActionStore.getTag();
      if (response.success && response?.data?.data.length) {
        setInputTag(response?.data?.data);
      }
    }

    if (openModal) {
      getTag();
    }

    return () => {
      setInputTag([]);
    }
    // eslint-disable-next-line
  }, [openModal])

  const handleCloseModal = (e) => {
    e.preventDefault();

    fileActionStore.setShowModal('UpdateTag', false)
  };

  const updateTags = (event, type = 'keydown') => {
    const value = (type === 'submit') ? valueRefTag.current.value : event.target.value;
    let newInputTag = [...inputTag];
    let newValue = value.length > 0 && value.trim();

    if (newValue.length > 0) {
      newInputTag = [...newInputTag, newValue];
    }
    setInputTag(newInputTag);

    if (type === 'submit') {
      return newInputTag;
    }
  };

  const handleKeyDown = event => {
    switch (event.key) {
      case ',':
      case 'Enter': {
        event.preventDefault();
        event.stopPropagation();
        updateTags(event);
        break;
      }
      default: break;
    }
  };

  const handleClickSubmit = async (e) => {
    const tags = updateTags(e, 'submit');
    const resultSubmit = await fileActionStore.handleSubmitUpdateTag(tags);

    if (resultSubmit && resultSubmit.success) {
      handleCloseModal(e);
      viewStore.enableRefreshList();
    }
  }

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="update-tag" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <h5 className="modal-title">{modalTitle}</h5>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-2 mt-n2">
                      <div>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={inputTag}
                          value={inputTag}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip color="primary" variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                          }
                          onChange={(event, newValue) => setInputTag(newValue)}
                          filterSelectedOptions
                          renderInput={(params) => {
                            params.inputProps.onKeyDown = handleKeyDown;
                            return (
                              <TextField
                                {...params}
                                autoFocus={true}
                                variant="outlined"
                                placeholder="Nhập tag"
                                inputRef={valueRefTag}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="nk-modal-action justify-end">
                        <ul className="btn-toolbar g-4 align-center">
                          <li><a href="# " className="link link-primary" onClick={handleCloseModal}>Bỏ qua</a></li>
                          <li><button className="btn btn-primary" onClick={handleClickSubmit}> Đồng ý </button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
});

export default UpdateTag;
import React from 'react';
import { useStore } from '../stores';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { isFileApproved, checkIsShowActionOCR, checkDisableMenu } from '../utils/functions';

const FileActionPopup = observer((props) => {
  const { info, icon } = props;
  const { fileActionStore, viewStore } = useStore();

  const handleShowModal = (e, type, popupState) => {
    e.preventDefault();

    fileActionStore.setInfo(info);
    fileActionStore.setShowModal(type, true);

    fileActionStore.closeModalViewFile();
    popupState.close();
  }

  const handleActionDownload = (e, id, popupState) => {
    e.preventDefault();

    fileActionStore.setInfo(info);
    fileActionStore.handleDownloadFile(id);

    popupState.close();
  }

  const handleShowHightlight = (popupState) => {
    viewStore.setIsShowHighlight(info.id, !popupState.isOpen);
  }

  return (
    <PopupState variant="popover" popupId="file-actions-popup">
      {(popupState) => (
        <div
          className={`dropdown ${popupState.isOpen ? 'show' : ''}`}
          onClick={() => handleShowHightlight(popupState)}
        >
          <a
            onClick={e => e.preventDefault()}
            {...bindTrigger(popupState)}
            className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
            data-toggle="dropdown"
          >
            <em className={`icon ni ${icon}`}></em>
          </a>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableScrollLock={true}
          >
            <div>
              <ul className="link-list-plain no-bdr">
                <li>
                  <a
                    href="# "
                    disabled={checkDisableMenu('Detail', info)}
                    onClick={(e) => handleShowModal(e, 'Detail', popupState)}
                  >
                    <em className="icon ni ni-eye"></em>
                    <span>Chi tiết</span>
                  </a>
                </li>
                {info.folderTypeRoot !== 0 && (
                  <li>
                    <a
                      href="# "
                      disabled={
                        checkDisableMenu(`Approve${info.type ? 'File' : 'Folder'}`, info)
                        || isFileApproved(info.isApproved, info.folderTypeRoot)
                      }
                      onClick={(e) => handleShowModal(e, 'Approve', popupState)}
                    >
                      <em className="icon ni ni-share"></em>
                      <span>{`Duyệt ${info.type ? 'file' : 'folder'} mới`}</span>
                    </a>
                  </li>
                )}
                {info.type === 1 && ( // actions for file only
                  <>
                    <li>
                      <a
                        href="# "
                        disabled={checkDisableMenu('Download', info)}
                        onClick={(e) => handleActionDownload(e, info.id, popupState)}
                      >
                        <em className="icon ni ni-download"></em>
                        <span>Download</span>
                      </a>
                    </li>
                    {checkIsShowActionOCR('MenuOCR', info.ocrStatus, info.extension) && (
                      <>
                        {info.folderTypeRoot !== 0 && (
                          <li style={{ borderTop: '1px solid #ccc', height: '5px' }}> &nbsp;</li>
                        )}
                        {info.folderTypeRoot !== 0 && (
                          <li>
                            <a
                              href="# "
                              disabled={
                                checkDisableMenu('ConfirmOCR', info)
                                || !isFileApproved(info.isApproved, info.folderTypeRoot)
                                || !checkIsShowActionOCR('OCR', info.ocrStatus)
                              }
                              onClick={(e) => handleShowModal(e, 'ConfirmOCR', popupState)}
                            >
                              <em className="icon ni ni-python"></em>
                              <span>OCR</span>
                            </a>
                          </li>
                        )}
                        {info.folderTypeRoot !== 0 && (
                          <li>
                            <a
                              href="# "
                              disabled={
                                checkDisableMenu('ViewOCR', info)
                                || !checkIsShowActionOCR('ViewOCR', info.ocrStatus)
                              }
                              onClick={(e) => handleShowModal(e, 'OCR', popupState)}
                            >
                              <em className="icon ni ni-file"></em>
                              <span>Nội dung OCR</span>
                            </a>
                          </li>
                        )}
                        {info.folderTypeRoot !== 0 && (
                          <li>
                            <a
                              href="# "
                              disabled={
                                checkDisableMenu('EditOCR', info)
                                || !checkIsShowActionOCR('EditOCR', info.ocrStatus)
                              }
                              onClick={(e) => handleShowModal(e, 'EditOCR', popupState)}
                            >
                              <em className="icon ni ni-html5"></em>
                              <span>Chỉnh lý OCR</span>
                            </a>
                          </li>
                        )}
                        {info.folderTypeRoot !== 0 && checkIsShowActionOCR('CancelRequestOCR', info.ocrStatus) && (
                          <li>
                            <a
                              href="# "
                              disabled={checkDisableMenu('CancelRequestOCR', info)}
                              onClick={(e) => handleShowModal(e, 'CancelRequestOCR', popupState)}
                            >
                              <em className="icon ni ni-file-remove-fill"></em>
                              <span>Từ chối duyệt chỉnh lý</span>
                            </a>
                          </li>
                        )}
                        {info.folderTypeRoot !== 0 && (
                          checkIsShowActionOCR('CancelOCR', info.ocrStatus) ?
                            (
                              <li>
                                <a
                                  href="# "
                                  disabled={checkDisableMenu('CancelOCR', info)}
                                  onClick={(e) => handleShowModal(e, 'CancelOCR', popupState)}
                                >
                                  <em className="icon ni ni-file-remove-fill"></em>
                                  <span>Hủy duyệt chỉnh lý</span>
                                </a>
                              </li>
                            ) : (
                              <li>
                                <a
                                  href="# "
                                  disabled={
                                    checkDisableMenu('ApproveOCR', info)
                                    || !checkIsShowActionOCR('ApproveOCR', info.ocrStatus)
                                  }
                                  onClick={(e) => handleShowModal(e, 'ApproveOCR', popupState)}
                                >
                                  <em className="icon ni ni-file-check-fill"></em>
                                  <span>Duyệt chỉnh lý</span>
                                </a>
                              </li>
                            )
                        )}
                      </>
                    )}
                  </>
                )}
                <li style={{ borderTop: '1px solid #ccc', height: '5px' }}> &nbsp;</li>
                {info.folderTypeRoot !== 0 && (
                  <li>
                    <a
                      href="# "
                      disabled={checkDisableMenu('Permission', info)}
                      onClick={(e) => handleShowModal(e, 'Permission', popupState)}
                    >
                      <em className="icon ni ni-lock"></em>
                      <span>Phân quyền</span>
                    </a>
                  </li>
                )}
                <li>
                  <a
                    href="# "
                    disabled={checkDisableMenu('UpdateTag', info)}
                    onClick={(e) => handleShowModal(e, 'UpdateTag', popupState)}
                  >
                    <em className="icon ni ni-tag"></em>
                    <span>Tag</span>
                  </a>
                </li>
                <li>
                  <a
                    href="# "
                    disabled={checkDisableMenu('Move', info)}
                    onClick={(e) => handleShowModal(e, 'Move', popupState)}
                  >
                    <em className="icon ni ni-move"></em>
                    <span>Di chuyển</span>
                  </a>
                </li>
                <li>
                  <a
                    href="# "
                    disabled={checkDisableMenu('Rename', info)}
                    onClick={(e) => handleShowModal(e, 'Rename', popupState)}
                  >
                    <em className="icon ni ni-pen"></em>
                    <span>Đổi tên</span>
                  </a>
                </li>
                <li>
                  <a
                    href="# "
                    disabled={checkDisableMenu('Delete', info)}
                    onClick={(e) => handleShowModal(e, 'Delete', popupState)}
                  >
                    <em className="icon ni ni-trash"></em>
                    <span>Xóa</span>
                  </a>
                </li>
              </ul>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
});

FileActionPopup.propTypes = {
  info: PropTypes.object.isRequired,
  icon: PropTypes.string
}

FileActionPopup.defaultProps = {
  icon: 'ni-more-h'
}

export default FileActionPopup;
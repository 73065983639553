export const MIME_TYPE_DEFAULT = 'application/octet-stream';
export const MIME_TYPES = {
  'png': 'image/png',
  'gif': 'image/gif',
  'jpg': 'image/jpg',
  'jpeg': 'image/jpeg',
  'pjpeg': 'image/pjpeg',
  'tiff': 'image/tiff',
  'bmp': 'image/bmp',
  'emf': 'image/x-emf',
  'wmf': 'image/x-wmf',
  'pdf': 'application/pdf',
  'mp4': 'video/mp4',
  'avi': 'video/avi',
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'ppt': 'application/vnd.ms-powerpoint',
  'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'zip': 'application/x-zip-compressed',
  'gzip': 'application/x-gzip-compressed'
};
export const MIME_TYPES_OCR = ['pdf', 'png', 'jpg', 'jpeg', 'bmp'];
import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";

import LoadingApp from '../../components/LoadingApp';

import { useStore } from '../../stores';
import useAxios from '../../hooks/axios';
import * as ConstantAPI from '../../constants/api';
import { PAGE_LIST, TYPE_DOCUMENT } from '../../constants/pages';

const ViewNode = (() => {
  const axios = useAxios();
  const history = useHistory();
  const { notificationStore } = useStore();
  const params = queryString.parse(window.location.search);

  const getNotification = () => {
    return axios.get(ConstantAPI.API_URL_NOTI_LIST, { silentLoading: true });
  }

  const redirectPageNotFound = () => {
    history.push('/page-not-found');
  }

  const checkParams = () => {
    /* params
    nodeId: id file/folder
    type: file or folder
    folderType: xác định thuộc folder nào
    parentId: id folder cha
    */
    if (!params.nodeId || !params.folderType || !params.type) {
      redirectPageNotFound();
    }

    if (+params.type === TYPE_DOCUMENT['FOLDER']) {
      const page = PAGE_LIST.find(page => page.folderType === +params.folderType);
      if (!page) redirectPageNotFound();

      let uri = page.uri;
      if (params.parentId && params.parentId !== 'null') {
        uri = `${page.uri}/folder/${params.nodeId}`;
      }

      history.push(uri);
    } else {
      history.push(`/?id=${params.nodeId}`);
    }
  }

  useEffect(() => {
    if (axios) {
      notificationStore.setGetNotificationFunction(getNotification);
      checkParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  return (<LoadingApp />);
})

export default ViewNode;
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import SVGIcon from '../SVGIcon';

QuickAccessFolder.propTypes = {
  info: PropTypes.object.isRequired
};

function QuickAccessFolder(props) {
  const { info } = props;

  return (
    <div className="nk-file-item nk-file">
      <div className="nk-file-info">
        <Link to={{ pathname: info.uri }} className="nk-file-link">
          <div className="nk-file-title">
            <div className="nk-file-icon">
              <span className="nk-file-icon-type">
                <SVGIcon ext={'folder'}></SVGIcon>
              </span>
            </div>
            <div className="nk-file-name">
              <div className="nk-file-name-text">
                <span className="title">{info.displayName}</span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default QuickAccessFolder;
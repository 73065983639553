import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Draggable from "react-draggable";
import Tooltip from '@material-ui/core/Tooltip';

import { useStore } from '../../stores';
import SVGIcon from '../SVGIcon';
import { formatDatetime, formatNameFileFolder, backgroundSymbol } from '../../utils/formatters';
import { cutNameToSymbol, checkDisableMenu } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const DetailFile = observer((props) => {
  const { fileActionStore } = useStore();
  const [info, setInfo] = useState({});
  const classes = useStyles();
  const openModal = fileActionStore.isShowDetail;
  const infoDetail =  fileActionStore.info

  const handleShowModal = (e, type) => {
    fileActionStore.setShowModal(type, true)
    handleCloseModal(e);
  }

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setShowModal('Detail', false)
  };

  useEffect(() => {
    async function getDetail() {
      let response = await fileActionStore.getDetail();
      if (response.success) {
        setInfo(response.data);
      }
    }

    if (openModal) {
      getDetail();
    }

    return () => {
      setInfo({});
    }
    // eslint-disable-next-line
  }, [openModal])

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault() }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="file-details" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <div className="nk-file-title">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <SVGIcon ext={info.extension || 'folder'} hardMode="list"></SVGIcon>
                          </span>
                        </div>
                        <div className="nk-file-name">
                          <div className="nk-file-name-text">
                            <span className="title"> {formatNameFileFolder(info)} </span>
                          </div>
                        </div>
                      </div>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-0 mt-n2">
                      <div className="nk-file-details">
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Loại </div>
                          <div className="nk-file-details-col"> {info.extension ? `File (*.${info.extension})` : 'Folder'} </div>
                        </div>
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Đường dẫn </div>
                          <div className="nk-file-details-col">
                            <ul className="breadcrumb breadcrumb-sm breadcrumb-alt breadcrumb-arrow">
                              {info.breadCrumb?.map((item, index) => (
                                <li key={index} className="breadcrumb-item"> {item.name} </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Sở hữu </div>
                          <div className="nk-file-details-col">
                            <span> {info.ownerByName || ''} </span>
                            {infoDetail.folderTypeRoot !== 0  && (
                              <span>
                                <a
                                  href="# "
                                  disabled={checkDisableMenu('ChangeOwner', fileActionStore.info)}
                                  onClick={(e) => handleShowModal(e, 'ChangeOwner')}
                                >
                                  (Đổi sở hữu)
                                </a>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Phân quyền </div>
                          <div className="nk-file-details-col">
                            {info.userPermissions && info.userPermissions.length > 0 ? (
                              <div className="user-avatar-group">
                                {info.userPermissions.map((item, index) => (
                                  index <= 3 && (
                                    <div key={index} className={`user-avatar xs ${backgroundSymbol(index)}`}>
                                      {index < 3 && (
                                        <Tooltip title={item.fullName}>
                                          <span> {cutNameToSymbol(item.fullName)} </span>
                                        </Tooltip>
                                      )}
                                      {index === 3 && <span> +{info.userPermissions.length - 3} </span>}
                                    </div>
                                  )
                                ))}
                              </div>
                            ) : (
                              <div className="tb-lead">Only me</div>
                            )}
                          </div>
                        </div>
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Cập nhật lúc </div>
                          <div className="nk-file-details-col">
                            {formatDatetime(info.updatedAt)}
                            {info.updateByName ? ` <${info.updateByName}>` : ''}
                          </div>
                        </div>
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Truy cập lúc </div>
                          <div className="nk-file-details-col">
                            {formatDatetime(info.lastViewAt)}
                            {info.lastViewByName ? ` <${info.lastViewByName}>` : ''}
                          </div>
                        </div>
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Ngày tạo </div>
                          <div className="nk-file-details-col">
                            {formatDatetime(info.createdAt)}
                            {info.createByName ? ` <${info.createByName}>` : ''}
                          </div>
                        </div>
                        <div className="nk-file-details-row">
                          <div className="nk-file-details-col"> Tags </div>
                          <div className="nk-file-details-col">
                            {info.tag?.length ? info.tag.toString().replace(/,/g, ', ') : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer modal-footer-stretch bg-light">
                      <div className="modal-footer-between">
                        <div className="g">
                          <a href="# " className="link link-primary" onClick={(e) => handleShowModal(e, 'ViewLog')}> Xem log </a>
                        </div>
                        <div className="g">
                          <ul className="btn-toolbar g-3">
                            <li><a href="# " className="btn btn-outline-light btn-white" onClick={handleCloseModal}> Đóng </a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
})

export default DetailFile;
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { useStore } from '../stores';
import FileActionPopup from './FileActionPopup';
import SVGIcon from './SVGIcon';
import { formatDate, formatViewedDatetime, formatBytes, formatOCRState, formatNameFileFolder, backgroundSymbol, toLowerCase } from '../utils/formatters';
import { isImageExt, isFileApproved, isViewedFileFolder, cutNameToSymbol } from '../utils/functions';
import { OCR_STATUS } from '../constants/ocr';

function NextIcon() {
  return (
    <svg className=" a-s-fa-Ha-pa" width="20px" height="20px" viewBox="0 0 24 24" focusable="false" fill="#989898">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  );
}

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '170px'
  },
  thumbnailWrapper: {
    width: '72px',
    height: '72px',
    lineHeight: '72px',
    textAlign: 'center'
  },
  blur: {
    opacity: '0.3'
  },
  unClickable: {
    cursor: 'default'
  },
  unClickableText: {
    cursor: 'text'
  },
  iconCheck: {
    background: '#39cfa1',
    borderRadius: '50%',
    color: '#fff',
    marginLeft: '10px'
  },
  customBreadcrumb: {
    '& .breadcrumb-item, & .breadcrumb-title': {
      fontWeight: 500,
      color: '#364a63',
      lineHeight: 1.4
    }
  }
}));

const URL_DEFAULT_THUMBNAIL = '/images/no-thumbnail.jpg';

const File = observer((props) => {
  const { info } = props;
  const { viewStore, fileActionStore } = useStore();
  const classes = useStyles();
  const classHighlight = viewStore.isShowHighlight[info.id] ? 'highlight-item': '';

  const handleViewFile = (e) => {
    e.preventDefault();

    if (!isViewedFileFolder(info.permission1)) return;

    if (toLowerCase(info.extension) === 'pdf') {
      fileActionStore.setInfo(info);
      fileActionStore.handleShowFilePDF(info.id);
    }
    else if (isImageExt(info.extension)) {
      fileActionStore.setInfo(info);
      fileActionStore.handleShowFileImage(info.id);
    } else {
      fileActionStore.setInfo(info);
      fileActionStore.setShowModal('Image', true)
    }
  }

  const renderDisplayName = () => {
    return (
      <Tooltip title={formatNameFileFolder(info)}>
        <span>
          {formatNameFileFolder(info)}
          {(OCR_STATUS['DONE'] === info.ocrStatus) && (
            <em className={`ni ni-check ${classes.iconCheck}`}></em>
          )}
        </span>
      </Tooltip>
    )
  }

  const handleChangeCheckbox = (event) => {
    viewStore.setIsSelectedCheckbox(info.id, event.target.checked);
  };

  if (viewStore.mode === 'grid') {
    return (
      <div className="nk-file-item nk-file">
        <div className="nk-file-info">
          <div className="nk-file-title">
            <div className={`nk-file-icon ${!isFileApproved(info.isApproved, info.folderTypeRoot) ? classes.blur : ''}`}>
              <a
                href="# "
                className={`nk-file-icon-link`}
                onClick={handleViewFile}
              >
                <span className="nk-file-icon-type" style={{ height: '88px' }}>
                  {isImageExt(info.extension) && info.thumbnail ? (
                    <div className={classes.thumbnailWrapper}>
                      <img
                        src={info.thumbnail ? info.thumbnail : URL_DEFAULT_THUMBNAIL}
                        alt={info.displayName}
                        style={{ maxWidth: '72px', maxHeight: '72px' }}
                        onError={e => { e.target.onerror = null; e.target.src = URL_DEFAULT_THUMBNAIL }}
                      />
                    </div>
                  ) : (
                    <SVGIcon ext={info.extension}></SVGIcon>
                  )}
                </span>
              </a>
            </div>
            <div className="nk-file-name">
              <div className="nk-file-name-text">
                <a
                  href="# "
                  className={`title ${classes.titleStyle}`}
                  onClick={handleViewFile}
                >
                  <Tooltip title={formatNameFileFolder(info)}>
                    <span>{formatNameFileFolder(info)}</span>
                  </Tooltip>
                </a>
              </div>
            </div>
          </div>
          <ul className="nk-file-desc">
            <li className="date">{formatDate(info.lastViewAt)}</li>
            <li className="size">{formatBytes(info.size)}</li>
            <li className="members">{info.members ? info.members.length : ''}</li>
          </ul>
        </div>
        <div className="nk-file-actions">
          <FileActionPopup info={info}></FileActionPopup>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`nk-file-item nk-file ${classHighlight}`}>
        <div className="nk-file-info">
          <div
            className="nk-file-title"
            style={{ marginLeft: viewStore.isShowCheckbox ? '-15px' : '' }}
          >
            {viewStore.isShowCheckbox && (
              <span>
                <Checkbox
                  checked={viewStore.isSelectedCheckbox[info.id] || false}
                  onChange={handleChangeCheckbox}
                  color="primary"
                />
              </span>
            )}
            <div className={`nk-file-icon ${!isFileApproved(info.isApproved, info.folderTypeRoot) ? classes.blur : ''}`}>
              <span className="nk-file-icon-type" style={{ textAlign: 'center' }}>
                <SVGIcon ext={info.extension}></SVGIcon>
              </span>
            </div>
            <div className="nk-file-name">
              <div className="nk-file-name-text">
                <a
                  href="# "
                  className={`title`}
                  onClick={handleViewFile}
                >
                  {viewStore.isShowBreadcrumb && (
                    info.breadCrumb && info.breadCrumb.length > 0 ? (
                      <Breadcrumbs
                        separator={<NextIcon />}
                        maxItems={5}
                        itemsBeforeCollapse={3}
                        aria-label="breadcrumb"
                        className={`breadcrumb-alt ${classes.customBreadcrumb}`}
                      >
                        {info.breadCrumb?.map((item, index) => (
                          <Typography color="textSecondary" key={item.id} className="breadcrumb-item">
                            {item.name}
                          </Typography>
                        ))}
                        <Typography color="textPrimary" className="breadcrumb-title">
                          {renderDisplayName()}
                        </Typography>
                      </Breadcrumbs>
                    ) : (
                      renderDisplayName()
                    )
                  )}
                  {!viewStore.isShowBreadcrumb && renderDisplayName()}
                </a>
              </div>
              {!isFileApproved(info.isApproved, info.folderTypeRoot) && (
                <div className="tb-sub" style={{ marginTop: '-10px' }}>
                  Chờ duyệt file mới
                </div>
              )}
              {OCR_STATUS['NOT_YET_OCR'] !== info.ocrStatus && (
                <div className="tb-sub" style={{ marginTop: '-10px' }}>
                  {formatOCRState(info.ocrStatus)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="nk-file-meta">
          <div className="tb-lead">{formatViewedDatetime(info.lastViewAt)}</div>
        </div>
        <div className="nk-file-members">
          {info.userPermissions && info.userPermissions.length > 0 ? (
            <div className="user-avatar-group tb-lead">
              {info.userPermissions.map((item, index) => (
                index <= 3 && (
                  <div key={index} className={`user-avatar xs ${backgroundSymbol(index)}`}>
                    {index < 3 && (
                      <Tooltip title={item.fullName}>
                        <span> {cutNameToSymbol(item.fullName)} </span>
                      </Tooltip>
                    )}
                    {index === 3 && <span> +{info.userPermissions.length - 3} </span>}
                  </div>
                )
              ))}
            </div>
          ) : (
            <div className="tb-lead"></div>
          )}
        </div>
        <div className="nk-file-actions">
          <FileActionPopup info={info}></FileActionPopup>
        </div>
      </div>
    )
  }
});

File.propTypes = {
  info: PropTypes.object.isRequired
};

export default File;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import ListItem from './ListItem';

import { useStore } from '../../stores';

UploadFile.propTypes = {
  isMultiple: PropTypes.bool
};

UploadFile.defaultProps = {
  isMultiple: true
};

function UploadFile(props) {
  const { isMultiple } = props;
  const { uploadStore } = useStore();
  const [isDisabled, setIsDisabled] = useState(false);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const MAX_SIZE = 1 * 1024 * 1024 * 1024;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: isDisabled,
    maxSize: MAX_SIZE,
    onDrop: (files, fileRejections) => {
      setIsDisabled(true);

      const fileInfoMapped = files.map(file => ({ percentage: 0, name: file.name, size: file.size, isShowUpload: true }));
      const _progressInfos = [...fileInfoMapped];

      setMessage([]);

      let countFinished = 0;
      files.forEach(async (file, idx) => {
        const uploadKeyResponse = await uploadStore.handleGetUploadKey(file.name);

        const formData = new FormData();
        formData.append('key', uploadKeyResponse?.data);
        formData.append('folderId', uploadStore.folderId);
        formData.append('file', file);

        return uploadStore.handleUploadFile(formData, (progressEvent) => {
          let percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
          _progressInfos[idx].percentage = percent >= 100 ? 100 : percent;
          setProgressInfos({ val: _progressInfos });
        }, idx)
          .then((response) => {
            _progressInfos[idx].percentage = 0;
            _progressInfos[idx].isShowUpload = false;
            setProgressInfos({ val: _progressInfos });

            countFinished++;
            if (countFinished === files.length) setIsDisabled(false);

            uploadStore.updateSelectedFile(file);
            uploadStore.updateUploadedFile(response.fileId);
          })
          .catch((error) => {
            console.log(error);
            setIsDisabled(false);

            _progressInfos[idx].percentage = 0;
            _progressInfos[idx].isShowUpload = false;
            setProgressInfos({ val: _progressInfos });

            if (!axios.isCancel(error)) {
              setMessage((prevMessage) => ([
              ...prevMessage,
              "Không thể upload file: " + file.name
            ]));
            }
          })
      });

      fileRejections.forEach((file) => {
        setIsDisabled(false);
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setMessage((prevMessage) => ([
              ...prevMessage,
              '- ' + file.file?.name + ' có dung lượng lớn hơn 1GB'
            ]));
          }
        });
      });
    }
  });

  const handleClickCancelUploadFile = (index) => {
    uploadStore.cancelTokenSource(index);
  }

  return (
    <div>
      <div
        className="upload-zone small bg-lighter dropzone dz-clickable"
        style={{ border: isDragActive ? '1px dashed #725afd' : '1px dashed #e5e9f2', padding: '0px' }}
      >
        <div
          className="dz-message"
          style={{ lineHeight: '85px', margin: '0px' }}
        >
          <div {...getRootProps({ className: 'dz-message-text' })}>
            <input {...getInputProps()} multiple={isMultiple} />
            {isDragActive ? (
              <span>Thả file tại đây</span>
            ) : (
              <div>
                <span>Kéo và thả</span> file vào đây hoặc <span> duyệt file </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {progressInfos && progressInfos.val.length > 0 && (
          progressInfos.val.map((item, index) => (
            item.isShowUpload &&
            <ListItem
              key={index}
              name={item.name}
              size={item.size}
              progress={item.percentage}
              onClickDelete={() => handleClickCancelUploadFile(index)}
            >
            </ListItem>
          ))
        )}
        {message.length > 0 && (
          <div className="alert alert-danger" role="alert" style={{ marginTop: '15px', maxHeight: '215px', overflow: 'auto' }}>
            <ul>
              {message.map((item, index) => {
                return <li key={index}> {item} </li>;
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadFile;
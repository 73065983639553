import React from 'react';
import PropTypes from 'prop-types';
import RecentFolder from './RecentFolder';
import RecentFile from './RecentFile';

RecentList.propTypes = {
  data: PropTypes.object.isRequired
};

function RecentList(props) {
  const { data } = props;

  return (
    <div className="nk-fmg-listing nk-block">
      <div className="nk-block-head-xs">
        <div className="nk-block-between g-2">
          <div className="nk-block-head-content">
            <h6 className="nk-block-title title">Tài liệu mở gần đây</h6>
          </div>
        </div>
      </div>
      <div className="toggle-expand-content expanded" data-content="recent-files">
        <div className="nk-files nk-files-view-group">
          <div className="nk-files-group">
            <h6 className="title">Folder</h6>
            <div className="nk-files-list">
              {data.folders && data.folders.map(d => <RecentFolder key={d.id} info={d}></RecentFolder>)}
            </div>
          </div>
          <div className="nk-files-group">
            <h6 className="title">Files</h6>
            <div className="nk-files-list">
              {data.files &&  data.files.map(d => <RecentFile key={d.id} info={d}></RecentFile>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentList;
export const ERROR_MESSAGE = {
  '403': {
    code: 'ERROR_NOT_PERMISSION',
    message: 'Bạn không có quyền thực hiện thao tác này'
  },
  '-1': {
    code: 'ERROR_ID_NOT_EXISTED',
    message: 'Dữ liệu không tồn tại'
  },
  '-2': {
    code: 'ERROR_FOLDER_NOT_EXISTED',
    message: 'Thư mục không tồn tại'
  },
  '-3': {
    code: 'ERROR_FOLDER_CAN_NOT_UPLOAD_FILE',
    message: 'Không thể upload file tại thư mục này'
  },
  '-4': {
    code: 'ERROR_FOLDER_ALREADY_EXISTED',
    message: 'Thư mục đã tồn tại'
  },
  '-5': {
    code: 'ERROR_FILE_UPLOAD_INVALID',
    message: 'File upload không hợp lệ'
  },
  '-6': {
    code: 'ERROR_FILE_NOT_EXISTED',
    message: 'File không tồn tại'
  },
  '-7': {
    code: 'ERROR_FILE_ALREADY_EXISTED',
    message: 'File đã tồn tại'
  },
  '-8': {
    code: 'ERROR_FILE_NAME_ALREADY_EXISTED',
    message: 'Tên file đã tồn tại'
  },
  '-9': {
    code: 'ERROR_FILE_STORAGE_NOT_FOUND',
    message: 'File storage không tồn tại'
  },
  '-10': {
    code: 'ERROR_FILE_WRONG_FORMAT',
    message: 'File không đúng định dạng'
  },
  '-11': {
    code: 'ERROR_FILE_DUPLICATED',
    message: 'File bị trùng lặp'
  },
  '-12': {
    code: 'ERROR_FOLDER_DUPLICATED',
    message: 'Thư mục bị trùng lặp'
  },
  '-13': {
    code: 'ERROR_SAVE_LOG',
    message: 'Lưu log thất bại'
  },
  '-14': {
    code: 'ERROR_UPDATE_TAG',
    message: 'Cập nhật log thất bại'
  },
  '-15': {
    code: 'ERROR_FOLDER_CAN_NOT_WRITE_TAG',
    message: 'Không thể tạo tag ở thư mục này'
  },
  '-16': {
    code: 'ERROR_ACTION_NOT_EXISTED',
    message: 'Hành động không tồn tại'
  },
  '-17': {
    code: 'ERROR_CAN_NOT_DELETE_FOLDER_ROOT',
    message: 'Không thể xóa thư mục gốc'
  },
  '-18': {
    code: 'ERROR_GET_TAG_FAILED',
    message: 'Lấy thông tin tag thất bại'
  },
  '-19': {
    code: 'ERROR_DOWNLOAD_EXPIRED_TOKEN',
    message: 'Phiên đăng nhập hết hạn'
  },
  '-20': {
    code: 'ERROR_FOLDER_CAN_NOT_RENAME',
    message: 'Không thể cập nhật tên cho thư mục này'
  },
  '-21': {
    code: 'ERROR_UPLOAD_FILE',
    message: 'Có lỗi xảy ra khi upload file'
  },
  '-22': {
    code: 'ERROR_USER_NOT_EXISTED',
    message: 'Người dùng không tồn tại'
  },
  '-23': {
    code: 'ERROR_CAN_NOT_CREATE_FOLDER',
    message: 'Không thể tạo thư mục tại đây'
  },
  '-24': {
    code: 'ERROR_FOLDER_CAN_NOT_MOVE_FILE',
    message: 'Không thể di chuyển file đến thư mục này'
  },
  '-25': {
    code: 'ERROR_FOLDER_CAN_NOT_MOVE',
    message: 'Không thể di chuyển file/folder đến đây'
  },
  '-26': {
    code: 'ERROR_ITEM_NOT_EXISTED',
    message: 'Item không tồn tại'
  },
  '-27': {
    code: 'ERROR_ITEM_HAVE_BEEN_APPROVED',
    message: 'Item đã được duyệt'
  },
  '-28': {
    code: 'ERROR_FAIL_TO_APPROVE_DOCUMENT',
    message: 'Có lỗi xảy ra khi duyệt tài liệu'
  },
  '-29': {
    code: 'ERROR_FILE_NAME',
    message: 'Vui lòng nhập tên file'
  },
  '-30': {
    code: 'FILE_STORAGE_NOT_FOUND',
    message: 'File storage không tồn tại'
  },
  '-31': {
    code: 'CREATE_OCR_FAIL',
    message: 'Có lỗi xảy ra khi khởi tạo OCR'
  },
  '-32': {
    code: 'CAN_NOT_READ_FILE',
    message: 'Không thể đọc file'
  },
  '-33': {
    code: 'ERROR_CAN_NOT_OCR_FILE',
    message: 'File này không thể OCR'
  },
  '-34': {
    code: 'ERROR_UPDATE_STATUS_OCR',
    message: 'Lỗi khi cập nhật trạng thái OCR'
  },
  '-35': {
    code: 'ERROR_SAVE_OCR',
    message: 'Lưu OCR thất bại'
  },
  '-36': {
    code: 'ERROR_UPDATE_CONTENT_OCR',
    message: 'Cập nhật nội dung OCR thất bại'
  },
  '-37': {
    code: 'ERROR_UPDATE_APPROVE_OCR',
    message: 'Duyệt chỉnh lý thất bại'
  },
  '-38': {
    code: 'ERROR_FILE_OCR_NOT_FOUND',
    message: 'Tệp chưa được OCR'
  },
  '-39': {
    code: 'ERROR_FILE_OCR_NOT_APPROVE',
    message: 'File không ở trạng thái chờ duyệt'
  },
  '-40': {
    code: 'ERROR_OCR_IS_EMPTY',
    message: 'OCR trống'
  },
  '-41': {
    code: 'ERROR_OCR_IS_PROCESSING',
    message: 'File đang xử lý OCR'
  },
  '-42': {
    code: 'ERROR_FILE_NOT_BE_OCR',
    message: 'File không thể OCR'
  },
  '-43': {
    code: 'ERROR_CAN_NOT_UPDATE_OCR_CONTENT',
    message: 'Không thể cập nhật nội dung OCR'
  },
  '-44': {
    code: 'ERROR_FILE_OCR_CANCEL_APPROVE',
    message: 'File không ở trạng thái đã OCR'
  },
  '-45': {
    code: 'ERROR_UPDATE_OWNER_MY_FOLDER',
    message: 'Tài liệu của tôi không thể thay đổi chủ sở hữu'
  },
  '-46': {
    code: 'ERROR_UPDATE_PERMISSION',
    message: 'Cập nhật quyền thất bại'
  },
  '-47': {
    code: 'ERROR_USER_ID_INVALID',
    message: 'Tài khoản không hợp lệ'
  },
  '-48': {
    code: 'ERROR_MY_FOLDER_OCR',
    message: 'Tài liệu của tôi không thể OCR'
  },
  '-49': {
    code: 'ERROR_UNAPPROVED_OCR',
    message: 'Tài liệu chưa được duyệt'
  },
  '-50': {
    code: 'ERROR_USER_PERMISSION_ALREADY_EXISTED',
    message: 'Quyền của người dùng đã tồn tại'
  },
  '-51': {
    code: 'ERROR_CAN_NOT_ADD_PERMISSION_FOR_USER',
    message: 'Không thể thêm quyền cho tài khoản này'
  },
  '-52': {
    code: 'ERROR_USERID_AND_TOKEN_ALREADY_EXISTED',
    message: 'UserId và token đã tồn tại'
  },
  '-53': {
    code: 'ERROR_FILE_COPY',
    message: 'Sao chép tệp vào thư mục không thành công'
  },
  '-54': {
    code: 'ERROR_FILE_OCR_NOT_OCR_DONE',
    message: 'Tệp không ở trạng thái hoàn tất'
  },
  '-55': {
    code: 'ERROR_FILE_OCR_NOT_WAITING_APPROVE',
    message: 'Tệp không ở trạng thái chờ phê duyệt'
  },
  '-56': {
    code: 'ERROR_FILE_CAN_NOT_OCR',
    message: 'Tệp không thể ocr'
  },
  '-57': {
    code: 'ERROR_KEYWORD_NOT_BE_EMPTY',
    message: 'Vui lòng nhập từ khóa để tìm kiếm'
  },
  '-58': {
    code: 'ERROR_MOVE_DOCUMENT',
    message: 'Không thể di chuyển đến thư mục con'
  },
  '-59': {
    code: 'ERROR_UPLOAD_FILE_MAX',
    message: 'Số lượng file upload không được nhiều hơn 10'
  },
  '-60': {
    code: 'KEY_UPLOAD_INVALID',
    message: 'Không thể upload file do lỗi xác thực'
  }
}
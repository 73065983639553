import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { observer } from 'mobx-react-lite';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import LoadingApp from '../components/LoadingApp';
import HomeList from '../pages/Home/HomeList';
import MyDocumentList from '../pages/MyDocument/MyDocumentList';
import MyDocumentFolder from '../pages/MyDocument/MyDocumentFolder';
import ProjectList from '../pages/Project/ProjectList';
import ProjectFolder from '../pages/Project/ProjectFolder';
import DepartmentList from '../pages/Department/DepartmentList';
import DepartmentFolder from '../pages/Department/DepartmentFolder';
import AwaitingApprovalList from '../pages/AwaitingApproval/AwaitingApprovalList';
import AwaitingApprovalFolder from '../pages/AwaitingApproval/AwaitingApprovalFolder';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import ViewNode from '../pages/ViewNode/ViewNode';

import { useStore } from '../stores';
import { isFCMSupported, requestPermission, onMessageListener, getTokenFirebase, firebaseConfig } from '../firebase/config-firebase';
import useAxios from '../hooks/axios';
import * as ConstantAPI from '../constants/api';

const AppRouter = observer(() => {
  const { initialized } = useKeycloak();
  const { userStore, viewStore, notificationStore } = useStore();
  const axios = useAxios();

  const getToken = async () => {
    const token = await getTokenFirebase();

    if (token) {
      notificationStore.setTokenFirebase(token);

      try {
        await axios.post(ConstantAPI.API_URL_NOTI_REGISTER_DEVICE, { token }, { silentLoading: true });
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error('Error: Không nhận được token Firebase');
    }
  }

  useEffect(() => {
    try {
      async function getUserDetail() {
        const response = await axios.get(ConstantAPI.API_URL_USER_DETAIL);

        if (response && response.data) {
          userStore.setUserName(response.data.fullName || '');
        } else {
          console.error('Có lỗi xảy ra khi lấy thông tin user')
        }
      }

      if (initialized) {
        if (!userStore.name) {
          getUserDetail();
        }

        if (!notificationStore.isSendToken) {
          getToken();
          notificationStore.setIsSendToken(true);
        }

        if (notificationStore.refreshListNoti) {
          notificationStore.getNotificationList();
        }
      }
    } catch (error) {
      console.log('Có lỗi xảy ra khi lấy dữ liệu: ', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, notificationStore.refreshListNoti]);

  if (!initialized) {
    return (<LoadingApp></LoadingApp>);
  }

  if (isFCMSupported) {
    requestPermission();

    if ("serviceWorker" in navigator) {
      const broadcast = new BroadcastChannel('message-channel');
      const configEncode = encodeURIComponent(JSON.stringify(firebaseConfig));

      navigator.serviceWorker.ready.then((registration) => {
        if (!registration) {
          console.error('Service Worker registration failed')
        }

        broadcast.onmessage = (event) => {
          notificationStore.enableRefreshListNoti();

          const payload = event.data || {};
          if (payload.data && payload.data.nodeIdParent === viewStore.folderId) {
            viewStore.enableRefreshList();
          }
        };
      })

      navigator.serviceWorker.register(`/firebase-messaging-sw.js?firebaseConfig=${configEncode}`)
        .then((registration) => {
          console.log("Registration successful, scope is:", registration.scope);

          window.onbeforeunload = () => {
            navigator.serviceWorker.getRegistrations()
              .then((registrations) => {
                for (let registration of registrations) {
                  registration.unregister()
                }
              })
              .catch((err) => {
                console.error('Service Worker registration failed: ', err);
              });
          };
        })
        .catch((err) => {
          console.error("Service worker registration failed, error:", err);
        });
    }

    onMessageListener(payload => {
      notificationStore.enableRefreshListNoti();

      if (payload.data && payload.data.nodeIdParent === viewStore.folderId) {
        viewStore.enableRefreshList();
      }
    });
  }

  viewStore.getDefaultSortList();

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomeList} />
        <Route exact path="/my-documents" component={MyDocumentList} />
        <Route exact path="/my-documents/folder/:id" component={MyDocumentFolder} />
        <Route exact path="/projects" component={ProjectList} />
        <Route exact path="/projects/folder/:id" component={ProjectFolder} />
        <Route exact path="/departments" component={DepartmentList} />
        <Route exact path="/departments/folder/:id" component={DepartmentFolder} />
        <Route exact path="/awaiting-approval" component={AwaitingApprovalList} />
        <Route exact path="/awaiting-approval/folder/:id" component={AwaitingApprovalFolder} />
        <Route exact path="/view-node" component={ViewNode} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
})

export default AppRouter;
import { runInAction, makeAutoObservable } from "mobx";
import { toast } from 'react-toastify';

const DEFAULT_TITLE = 'Kết quả tìm kiếm cụm từ: ';

class SearchStore {
  getTitle = '';
  getKeyWord = '';
  getSearched = '';
  isSearched = false;
  afterSearch = false;
  resultSearch = [];
  folderTypeRoot = null;
  folderId = null;
  isSearch = false;
  submitSearchFunction = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsSearch(bool) {
    runInAction(() => {
      this.isSearch = bool;
    });
  }

  setFolderTypeRoot(type) {
    this.folderTypeRoot = type;
  }

  setAfterSearch(type) {
    this.afterSearch = type;
  }

  setFolderId(id) {
    this.folderId = id;
  }

  setSubmitSearchFunction(f) {
    this.submitSearchFunction = f;
  }

  setTitle(keyword) {
    const title = keyword ? DEFAULT_TITLE + `"${keyword}"` : '';
    this.getKeyWord = keyword;
    this.getTitle = title;
  }

  setSearched(f) {
    this.isSearched = f
    if (this.isSearched) {
      this.getSearched = '';
    }
  }

  async handleSubmitSearch(keyword, sort) {
    if (!keyword) {
      toast.error('Vui lòng nhập từ khóa để tìm kiếm');
      return { success: false };
    }

    this.resultSearch = [];
    this.isSearch = true;
    this.setTitle(keyword);
    this.setAfterSearch(false);
    const params = {
      folderType: this.folderTypeRoot,
      id: this.folderId,
      keyword: keyword.trim(),
      sortBy: sort.sortBy,
      sortOrder: sort.sortOrder,
    }

    const response = await this.submitSearchFunction({ params });
    if (response) {
      this.resultSearch = response.data;
      return { success: true };
    } else {
      return { success: false };
    }
  }
}

export default SearchStore;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { useStore } from '../stores';

import File from './File';
import Folder from './Folder';

const ViewListMode = observer((props) => {
  const { data } = props;
  const { viewStore, searchStore, fileActionStore } = useStore();

  const checkSort = (value) => {
    if (viewStore.sortState.setSortBy !== value) return '';
    return viewStore.sortState[value] === 'ASC' ? 'dropdown-indicator-up' : 'dropdown-indicator-down';
  }

  const classSortBy = {
    displayName: checkSort('displayName'),
    lastViewAt: checkSort('lastViewAt')
  }

  const handleSortBy = async (value) => {
    viewStore.setSortList(value);
    let sort = {
      sortBy: viewStore.sortBy,
      sortOrder: viewStore.sortOrder,
    }

    if (searchStore.isSearch) {
      const result = await searchStore.handleSubmitSearch(searchStore.getKeyWord, sort);

      if (result && result.success) {
        viewStore.setIsShowBreadcrumb(true);
      }
    }
    viewStore.enableRefreshList();
  }

  const markAllSelectedCheckbox = (check = true) => {
    const checboxes = JSON.parse(JSON.stringify(viewStore.isSelectedCheckbox));

    Object.keys(viewStore.isSelectedCheckbox).forEach(key => {
      checboxes[key] = check;
    })

    viewStore.setIsSelectedCheckbox(null, null, checboxes);
  }

  const checkSelectedAll = () => {
    if (!Object.keys(viewStore.isSelectedCheckbox)?.length) {
      viewStore.setIsSelectedAll(false);
      viewStore.setIsCheckSelectedAll(false);
      return
    }

    const isSelectedAll = Object.values(viewStore.isSelectedCheckbox)?.every(val => val);
    viewStore.setIsSelectedAll(isSelectedAll);
    viewStore.setIsCheckSelectedAll(false);
  }

  const handleChangeCheckboxAll = () => {
    const isSelectedAll = !viewStore.isSelectedAll;
    markAllSelectedCheckbox(isSelectedAll);

    viewStore.setIsSelectedAll(isSelectedAll);
  };

  const handleClickApprove = async (e, popupState) => {
    e.preventDefault();
    fileActionStore.setShowModal('ApproveMultiple', true);
    popupState.close();
  }

  useEffect(() => {
    if (viewStore.isCheckSelectedAll) {
      checkSelectedAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewStore.isCheckSelectedAll])

  useEffect(() => {
    if (data && data.length) {
      let objData = {};
      for (let i = 0; i < data.length; i++) {
        objData[data[i].id] = false;
      }
      viewStore.setIsSelectedCheckbox(null, null, objData);
    } else {
      viewStore.setIsSelectedCheckbox(null, null, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className="nk-files nk-files-view-list">
      <div className="nk-files-head">
        <div className="nk-file-item">
          <div className="nk-file-info">
            {viewStore.isShowCheckbox && (
              <span style={{ marginLeft: 6 }}>
                <Checkbox
                  checked={viewStore.isSelectedAll}
                  onChange={handleChangeCheckboxAll}
                  name="isSelectedAll"
                  color="primary"
                />
              </span>
            )}
            <div
              className={`tb-head dropdown-toggle ${classSortBy.displayName}`}
              onClick={() => handleSortBy('displayName')}
            >
              Tên
            </div>
          </div>
          <div className="nk-file-meta">
            <div className="dropdown">
              <div
                className={`tb-head dropdown-toggle ${classSortBy.lastViewAt}`}
                onClick={() => handleSortBy('lastViewAt')}
              >
                Lần truy cập gần nhất
              </div>
            </div>
          </div>
          <div className="nk-file-members">
            <div className="tb-head">Members</div>
          </div>
          {viewStore.isShowCheckbox && (
            <div className="nk-file-actions">
              <PopupState variant="popover" popupId="file-actions-popup">
              {(popupState) => (
                <div
                  className={`dropdown ${popupState.isOpen ? 'show' : ''}`}
                  style={{ marginRight: '-5px' }}
                >
                  <a
                    onClick={e => e.preventDefault()}
                    {...bindTrigger(popupState)}
                    className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className={`icon ni ni-more-h`}></em>
                  </a>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    disableScrollLock={true}
                  >
                    <div>
                      <ul className="link-list-plain no-bdr">
                        <li>
                          <a href="# " onClick={(e) => handleClickApprove(e, popupState)}>
                            <em className="icon ni ni-share"></em>
                            <span>Duyệt file/folder đã chọn</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Popover>
                </div>
              )}
              </PopupState>
            </div>
          )}
        </div>
      </div>
      <div className="nk-files-list">
        {data.map(d => {
          if (d.type) {
            return (
              <File
                key={d.id}
                info={d}
              >
              </File>
            )
          } else {
            return (
              <Folder
                key={d.id}
                info={d}
              >
              </Folder>
            )
          }
        })}
      </div>
    </div>
  );
});

ViewListMode.propTypes = {
  data: PropTypes.array.isRequired
};

export default ViewListMode;
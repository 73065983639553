import axios from 'axios';
import queryString from 'query-string';

import { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';

import { useStore } from '../stores';
import { ERROR_MESSAGE } from '../constants/messages';
import { API_URL_NOTI_REGISTER_DEVICE } from '../constants/api';
import { API_AUTHORIZED_BY_KEY } from '../constants/permission';

function useAxios() {
  const { keycloak, initialized } = useKeycloak();
  const { viewStore, fileActionStore } = useStore();
  const [axiosInstance, setAxiosInstance] = useState({});
  const [token, setToken] = useState(keycloak.token);

  keycloak.onAuthRefreshSuccess = () => {
    setToken(keycloak.token);
  }

  useEffect(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'content-type': 'application/json',
        'Authorization': initialized ? `Bearer ${keycloak.token}` : undefined,
      },
      paramsSerializer: params => queryString.stringify(params)
    });

    instance.interceptors.request.use(async (config) => {
      if (!config.silentLoading) {
        viewStore.enableLoading();
      }

      if (API_AUTHORIZED_BY_KEY.includes(config.url)) {
        delete config.headers['Authorization'];
      }

      return config;
    });

    instance.interceptors.response.use(async (response) => {
      viewStore.disableLoading();
      viewStore.disableRefreshList();
      viewStore.disableRefreshCurrentInfo();

      if (response && response.data) {
        return response.data;
      }

      return response;
    }, (error) => {
      viewStore.disableLoading();
      viewStore.disableRefreshList();
      viewStore.disableRefreshCurrentInfo();

      if (axios.isCancel(error)) throw error;

      if (error.response?.status === 403) {
        toast.error(ERROR_MESSAGE['403'].message);
        fileActionStore.closeAllModals();
        return
      }

      const errorCode = error.response && error.response.data ? error.response.data.code?.toString() : null;
      if (errorCode && ERROR_MESSAGE[errorCode]) {
        if (error.response?.config?.url === API_URL_NOTI_REGISTER_DEVICE) {
          return false;
        }

        if (errorCode === '-51') {
          let userIds = error.response.data.userIds?.toString()?.replaceAll(',', ', ') || 'này';
          let message = 'Không thể thêm quyền cho tài khoản ' + userIds;

          toast.error(message, { autoClose: 5000 });
          return
        }

        toast.error(ERROR_MESSAGE[errorCode].message);
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại!');
      }

      throw error;
    });

    setAxiosInstance({ instance })

    return () => {
      setAxiosInstance({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, token])

  return axiosInstance.instance;
}

export default useAxios;
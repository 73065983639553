import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Chip,
  TextField,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  InputAdornment
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Draggable from "react-draggable";

import { useStore } from '../../stores';
import SVGIcon from '../SVGIcon';
import SelectCustom from '../SelectCustom';
import { formatNameFileFolder } from '../../utils/formatters';
import useDebounce from '../../hooks/debound';
import {
  PERMISSION_ALL,
  FULL_PERMISSIONS,
  DEFINE_PERMISSIONS,
  DEFINE_LIST_PERMISSIONS,
  DEFINE_LIST_CHECK_ALL,
  PERMISSION_READ
} from '../../constants/permission';
import { arraysEqual, duplicateArray, findValueSelected, checkDisableMenu } from '../../utils/functions';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControlLabel: {
    marginBottom: '0px',
    width: '100%'
  },
  alertCustom: {
    padding: '0.5rem 3.25rem',
    marginBottom: '0.5rem !important'
  },
  labelUpdate: {
    fontWeight: 'bold'
  }
}));

const DISABLED_BTN = {
  create: true,
  update: true
};

const UpdatePermission = observer((props) => {
  const { fileActionStore, viewStore } = useStore();
  const classes = useStyles();
  const [listPermissionApproved, setListPermissionApproved] = useState([]);
  const [listPermissionApprovedFilter, setListPermissionApprovedFilter] = useState([]);
  const [listPermissions, setListPermissions] = useState([]);
  const [inputEmail, setInputEmail] = useState([]);
  const [isSelectedCheckbox, setIsSelectedCheckbox] = useState(DEFINE_LIST_CHECK_ALL);
  const [isShowCreatePermission, setIsShowCreatePermission] = useState(false);
  const valueRefTag = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabledBtn, setIsDisabledBtn] = useState(DISABLED_BTN);
  const [inputSearch, setInputSearch] = useState('');
  const [suggestExistingUsers, setSuggestExistingUsers] = useState([]);
  const debouncedSearchTerm = useDebounce(inputSearch, 600);

  const info = fileActionStore.info;
  const openModal = fileActionStore.isShowPermission;
  const COLOR_CHIP_SUCCESS = 'primary';
  const COLOR_CHIP_ERROR = 'secondary';
  const isShowSearchInput = !isShowCreatePermission && listPermissionApproved.length > 2;

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setShowModal('Permission', false)
  };

  const updateInputEmail = async (event, type = 'keydown') => {
    setErrorMessage('');
    const value = (type === 'submit') ? valueRefTag.current.value : event.target.value;
    let newInputEmail = [...inputEmail];
    let newValue = value.length > 0 && value.trim();
    let color = COLOR_CHIP_SUCCESS;

    if (newValue.length > 0) {
      const resultSubmit = await handleCheckIsExisted(newValue);
      if (resultSubmit && !resultSubmit.success) {
        color = COLOR_CHIP_ERROR;
        setErrorMessage('Tài khoản này không tồn tại trong hệ thống');
      }
      newInputEmail = [...newInputEmail, { value: newValue, color }];
    }
    setInputEmail(newInputEmail);

    if (type === 'submit') {
      return newInputEmail.map(item => item.value);
    }
  };

  const handleKeyDown = event => {
    switch (event.key) {
      case ',':
      case 'Enter':
        // Khi có attribute aria-activedescendant thì là user đang chọn option từ gợi ý
        if (!event.target.getAttribute('aria-activedescendant')) {
          event.preventDefault();
          event.stopPropagation();
          updateInputEmail(event);
          setSuggestExistingUsers([]);
        }
        break;

      default:
        setInputSearch(event.target.value);
    }
  };

  const handleSearchUser = (e) => {
    const keyword = e.target.value ? e.target.value.toLowerCase() : '';

    let result = listPermissionApproved.filter(user => {
      const findFullName = user.fullName && user.fullName.toLowerCase().indexOf(keyword) !== -1;
      const findEmail = user.email && user.email.toLowerCase().indexOf(keyword) !== -1;
      const findUsername = user.username && user.username.toLowerCase().indexOf(keyword) !== -1;

      return findFullName || findEmail || findUsername;
    })

    if (!result.length) {
      result.push({ isNotFound: true });
    }

    setListPermissionApprovedFilter(result);
  }

  const handleChangeSelected = (value, permissionId) => {
    const ind = listPermissionApproved.findIndex(item => item.permissionId === permissionId);
    listPermissionApproved[ind].error = false;

    if (value && value.length) {
      let idSelected = findValueSelected(value, listPermissionApproved[ind].permissions);

      if (!listPermissionApproved[ind].permissions.length) {
        idSelected = value.find(item => item === PERMISSION_ALL);
      }

      if (idSelected === PERMISSION_ALL) {
        // Xóa hết permission khác khi chọn permission all
        value.length = 0;
        value.push(PERMISSION_ALL);
      } else {
        // Auto thêm quyền đọc file/folder
        let indReadPermission = value.findIndex(item => item === PERMISSION_READ);
        if (indReadPermission === -1) {
          value.push(PERMISSION_READ);
        }

        // Xóa permission all khi chưa đủ 10 quyền
        let indAllPermission = value.findIndex(item => item === PERMISSION_ALL);
        if (value.length >= 2 && indAllPermission !== -1) {
          value.splice(indAllPermission, 1);
        }
      }
    } else {
      listPermissionApproved[ind].error = true;
    }

    setIsDisabledBtn({ ...isDisabledBtn, update: false });
    listPermissionApproved[ind].permissions = value;
    setListPermissionApproved(listPermissionApproved);
  };

  const handleChangeCheckbox = (event) => {
    const selected = checkAllPermissionsCreate(event.target.name, event.target.checked)
    setIsSelectedCheckbox({ ...selected });

    const isDisabled = Object.values(selected).every(item => !item);
    setIsDisabledBtn({ ...isDisabledBtn, create: isDisabled });
  };

  const checkAllPermissionsCreate = (keyInput, valueInput) => {
    const data = { [keyInput]: valueInput };
    if (!data) return [];

    let checked = { ...isSelectedCheckbox, ...data };
    if (keyInput === 'checkedAll' && valueInput) {
      Object.keys(DEFINE_LIST_CHECK_ALL).forEach(key => {
        checked[key] = true;
      })
    }
    else if (keyInput === 'checkedAll' && !valueInput) {
      checked = DEFINE_LIST_CHECK_ALL;
    }
    else {
      const previousCheckedRead = checked.checkedRead;
      checked.checkedAll = false;
      checked.checkedRead = false;

      let countChecked = Object.values(checked).reduce((total, isChecked) => {
        return total += isChecked ? 1 : 0
      }, 0);

      // Auto thêm quyền đọc file/folder khi click vô các quyền 2-10
      checked.checkedRead = (countChecked >= 1) || (countChecked === 0 && previousCheckedRead);

      // Kiểm tra đủ 10 quyền gán checkAll == true
      countChecked += checked.checkedRead ? 1 : 0;
      checked.checkedAll = countChecked === FULL_PERMISSIONS.length;
    }

    return checked;
  }

  const checkAllPermissionsUpdate = (permissions) => {
    if (!permissions || !permissions.length) return [];

    permissions = duplicateArray(permissions);
    if (permissions.length === 1 && permissions[0] === PERMISSION_ALL) {
      permissions = FULL_PERMISSIONS;
    } else if (arraysEqual(permissions, FULL_PERMISSIONS)) {
      permissions = [PERMISSION_ALL];
    }

    return permissions;
  }

  const mapperListPermissionsApproved = (data) => {
    if (!data || !data.length) return [];

    let listPerApproved = [];
    data.forEach(item => {
      let permissions = [];

      for (let i = 1; i <= FULL_PERMISSIONS.length; i++) {
        let key = 'permission' + i;
        item[key] && permissions.push(DEFINE_PERMISSIONS[key]);
      }
      permissions = checkAllPermissionsUpdate(permissions);

      listPerApproved.push({
        fullName: item.fullName,
        username: item.username,
        email: item.email,
        permissionId: item.permissionId,
        permissions
      })
    })
    setListPermissionApproved(listPerApproved);
  }

  const mapperPermissionSendApi = (valueList) => {
    let permisUser = {};

    if (valueList.permissions.includes(PERMISSION_ALL)) { // check permission all
      Object.keys(DEFINE_PERMISSIONS).forEach(key => {
        if (key === 'permission0') return;
        permisUser = { ...permisUser, [key]: true }
      })
    } else {
      Object.keys(DEFINE_PERMISSIONS).forEach(key => {
        if (key === 'permission0') return;
        if (valueList.permissions.includes(DEFINE_PERMISSIONS[key])) {
          permisUser = { ...permisUser, [key]: true }
        } else {
          permisUser = { ...permisUser, [key]: false }
        }
      })
    }

    return permisUser;
  }

  const handleCheckIsExisted = async (value) => {
    const resultSubmit = await fileActionStore.checkUserIsExisted(value);
    return resultSubmit;
  }

  const handleSuggestExistingUser = async (value) => {
    if (!value) {
      setSuggestExistingUsers([{ username: '' }]);
      return { success: false }
    }

    const resultSubmit = await fileActionStore.suggestExistingUser(value);

    let originData = [];
    if (resultSubmit && resultSubmit.data) {
      originData = resultSubmit.data.map(item => {
        return {
          text: `${item.username} - ${item.email}`,
          value: item.email
        }
      })
    }

    const selectedValueData = inputEmail && inputEmail.length ? inputEmail.map(item => item.value) : null;
    const suggestData = selectedValueData ? originData.filter(item => !selectedValueData.includes(item.value)) : originData;

    setSuggestExistingUsers(suggestData);

    return resultSubmit;
  }

  const handleClickSubmitCreate = async (e) => {
    let params = [];
    let permissions = [];

    Object.keys(isSelectedCheckbox).forEach(key => {
      if (!isSelectedCheckbox[key]) return;
      let permis = key.replace('checked', '');
      permissions.push(permis);
    })
    
    // convert permission send api
    permissions = mapperPermissionSendApi({ permissions });

    const emails = await updateInputEmail(e, 'submit');
    emails?.forEach(email => {
      params.push({
        ...permissions,
        usernameOrMail: email
      })
    })

    const resultSubmit = await fileActionStore.handleSubmitCreatePermission(params);
    if (resultSubmit && resultSubmit.success) {
      resetData();
      viewStore.enableRefreshList();
      viewStore.setIsRefreshListPermission(true);
    }
  }

  const handleClickSubmitUpdate = async (e) => {
    let params = [];
    let countEmpty = 0;

    listPermissionApproved.forEach(valueList => {
      let permisUser = {
        permissionId: valueList.permissionId
      }
      permisUser = { ...permisUser, ...mapperPermissionSendApi(valueList) };
      params.push(permisUser);

      !valueList.permissions.length && countEmpty++;
    })

    if (countEmpty > 0) {
      toast.error('Vui lòng chọn ít nhất 1 quyền');
      return
    }

    const resultSubmit = await fileActionStore.handleSubmitUpdatePermission(params);
    if (resultSubmit && resultSubmit.success) {
      handleCloseModal(e);
      viewStore.enableRefreshList();
    }
  }

  const handleClickSubmitDelete = async (e, info) => {
    e.preventDefault();

    fileActionStore.setInfoPermission(info)
    fileActionStore.setShowModal('DeletePermission', true);
  }

  const resetData = () => {
    setListPermissionApproved([]);
    setIsSelectedCheckbox(DEFINE_LIST_CHECK_ALL);
    setInputEmail([]);
    setErrorMessage('');
    setIsDisabledBtn(DISABLED_BTN);
  }

  const getPermission = async () => {
    setListPermissionApproved([]);

    let response = await fileActionStore.getPermission();
    if (response.success) {
      mapperListPermissionsApproved(response.data);
    }

    viewStore.setIsRefreshListPermission(false);
  }

  useEffect(() => {
    if (inputEmail.length > 0) {
      setIsShowCreatePermission(true);
    } else {
      setIsShowCreatePermission(false);
    }
  }, [inputEmail]);

  useEffect(() => {
    async function getListPermission() {
      setListPermissions(DEFINE_LIST_PERMISSIONS);
    }
    
    if (openModal) {
      getListPermission();
      getPermission();
    }

    return () => {
      resetData();
    }
    // eslint-disable-next-line
  }, [openModal])

  useEffect(() => {
    if (viewStore.isRefreshListPermission) {
      getPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewStore.isRefreshListPermission]);

  useEffect(() => {
    if (isShowCreatePermission) {
      setIsSelectedCheckbox(DEFINE_LIST_CHECK_ALL);
      setListPermissionApprovedFilter(listPermissionApproved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowCreatePermission]);

  useEffect(() => {
    if (listPermissionApproved) {
      setListPermissionApprovedFilter(listPermissionApproved);
    }
  }, [listPermissionApproved]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSuggestExistingUser(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '535px' }} tabIndex="-1" role="dialog" id="update-permission" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <div className="nk-file-title">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <SVGIcon ext={info.extension || 'folder'}></SVGIcon>
                          </span>
                        </div>
                        <div className="nk-file-name">
                          <div className="nk-file-name-text">
                            <span className="title">
                              {formatNameFileFolder(info)} {`>> ${isShowCreatePermission ? 'Cấp quyền' : 'Phân quyền'}`}
                            </span>
                          </div>
                        </div>
                      </div>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-2 mt-n2">
                      {!isShowCreatePermission && (
                        <div className={classes.labelUpdate}>
                          1. Cấp quyền
                        </div>
                      )}
                      <div>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={suggestExistingUsers}
                          value={inputEmail}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip color={option.color} variant="outlined" label={option.value} {...getTagProps({ index })} />
                            ))
                          }
                          onChange={(event, newValue) => {
                            setInputEmail(newValue);
                            setErrorMessage('');
                          }}
                          filterSelectedOptions
                          renderOption={(option) => option.text || ''}
                          getOptionLabel={(option) => option.text || ''}
                          renderInput={(params) => {
                            params.inputProps.onKeyDown = handleKeyDown;
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Email hoặc tên đăng nhập"
                                size="small"
                                style={{ marginBottom: '10px' }}
                                inputRef={valueRefTag}
                                onInput={e => setInputSearch(e.target.value)}
                              />
                            );
                          }}
                          disabled={checkDisableMenu('AddPermission', info)}
                        />
                        {errorMessage &&
                          <div className={`alert alert-icon alert-danger ${classes.alertCustom}`} role="alert">
                            <em className="icon ni ni-alert-circle" style={{ top: '0.6rem' }}></em>
                            {errorMessage}
                          </div>
                        }
                      </div>
                      {!isShowCreatePermission && (
                        <div>
                          <div className={classes.labelUpdate} style={{ marginTop: '10px' }}>
                            2. Cập nhật quyền
                          </div>
                          <div style={{ maxHeight: '42vh', overflow: 'auto', minHeight: isShowSearchInput ? '242px' : 'unset' }}>
                            <div className="card-inner p-0">
                              <div className="nk-tb-list nk-tb-ulist">
                                {listPermissionApprovedFilter.length > 0 &&
                                  listPermissionApprovedFilter.map((item, index) => (
                                    !item.isNotFound ? (
                                      <div key={item.permissionId} className="nk-tb-item">
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            <div className="user-info">
                                              <span className="tb-lead"> {item.fullName} </span>
                                              <span> {item.email} </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="nk-tb-col text-right d-flex"
                                          style={{ alignItems: 'center', justifyContent: 'flex-end', paddingRight: '15px' }}
                                        >
                                          <SelectCustom
                                            id={'code'}
                                            value={item.permissions}
                                            list={listPermissions}
                                            style={{ width: '165px' }}
                                            error={item.error}
                                            disabledItem={checkDisableMenu('UpdatePermission', info)}
                                            onChangeSelected={(e) => handleChangeSelected(e.target.value, item.permissionId)}
                                          />
                                          {!checkDisableMenu('DeletePermission', info) && (
                                            <Tooltip title="Xóa quyền">
                                              <div>
                                                <a
                                                  href="# "
                                                  className="btn btn-sm btn-icon btn-trigger"
                                                  onClick={(e) => handleClickSubmitDelete(e, item)}
                                                >
                                                  <em className="icon ni ni-cross-circle" style={{ fontSize: '1.6em' }}></em>
                                                </a>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div key={index} style={{ padding: '5px 10px', fontStyle: 'italic' }}>
                                        Không tìm thấy dữ liệu phù hợp
                                      </div>
                                    )
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isShowCreatePermission && (
                        <>
                          <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                              <Checkbox
                                checked={isSelectedCheckbox.checkedAll}
                                onChange={handleChangeCheckbox}
                                name="checkedAll"
                                color="primary"
                                size="small"
                              />
                            }
                            label="Toàn quyền"
                          />
                          <div className="d-flex justify-content-between">
                            <div>
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedRead}
                                    onChange={handleChangeCheckbox}
                                    name="checkedRead"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="1. Đọc folder/Đọc file"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedApproveFile}
                                    onChange={handleChangeCheckbox}
                                    name="checkedApproveFile"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="2. Duyệt file mới/OCR và chỉnh lý"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedApproveFolder}
                                    onChange={handleChangeCheckbox}
                                    name="checkedApproveFolder"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="3. Duyệt folder mới/Duyệt chỉnh lý OCR"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedViewProperties}
                                    onChange={handleChangeCheckbox}
                                    name="checkedViewProperties"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="4. Xem thuộc tính"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedEditProperties}
                                    onChange={handleChangeCheckbox}
                                    name="checkedEditProperties"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="5. Sửa thuộc tính"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedDelete}
                                    onChange={handleChangeCheckbox}
                                    name="checkedDelete"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="6. Xóa"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedViewPermission}
                                    onChange={handleChangeCheckbox}
                                    name="checkedViewPermission"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="7. Xem phân quyền"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedEditPermission}
                                    onChange={handleChangeCheckbox}
                                    name="checkedEditPermission"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="8. Sửa phân quyền"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedChangeOwner}
                                    onChange={handleChangeCheckbox}
                                    name="checkedChangeOwner"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="9. Giật quyền"
                              />
                              <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                  <Checkbox
                                    checked={isSelectedCheckbox.checkedMove}
                                    onChange={handleChangeCheckbox}
                                    name="checkedMove"
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="10. Di chuyển folder/file"
                              />
                            </div>
                          </div>
                          <TextareaAutosize
                            style={{ width: '100%', marginTop: '10px' }}
                            minRows={3}
                            maxRows={5}
                            placeholder="Tin nhắn (không bắt buộc)"
                          />
                        </>
                      )}
                      <div className="nk-modal-action justify-end">
                        {isShowSearchInput && (
                          <div className="nk-fmg-search">
                            <TextField
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <em className="icon ni ni-search"></em>
                                  </InputAdornment>
                                )
                              }}
                              type="text"
                              variant="standard"
                              placeholder="Tìm kiếm tài khoản"
                              size="small"
                              onInput={handleSearchUser}
                            />
                          </div>
                        )}
                        <div>
                          <ul className="btn-toolbar g-4 align-center">
                            <li><a href="# " className="link link-primary" onClick={handleCloseModal}> Bỏ qua </a></li>
                            <li>
                              <button
                                className="btn btn-primary"
                                onClick={isShowCreatePermission ? handleClickSubmitCreate : handleClickSubmitUpdate}
                                disabled={isShowCreatePermission ? isDisabledBtn.create : isDisabledBtn.update}
                              >
                                { isShowCreatePermission ? 'Cấp quyền' : 'Cập nhật' }
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
});

export default UpdatePermission;
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Draggable from "react-draggable";

import { useStore } from '../../stores';
import { formatNameFileFolder } from '../../utils/formatters';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const CancelRequestOCR = observer(() => {
  const { viewStore, fileActionStore, OCRStore, searchStore } = useStore();
  const classes = useStyles();
  const [reason, setInputReason] = useState('');
  const [isShowErrMsg, setIsShowErrMsg] = useState(false);

  const info = fileActionStore.info;
  const modalTitle = 'Từ chối duyệt chỉnh lý';
  const openModal = fileActionStore.isShowCancelRequestOCR;

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setShowModal('CancelRequestOCR', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit(event);
    }
  }

  const handleClickSubmit = async (e) => {
    e.preventDefault();

    if (!reason) {
      setIsShowErrMsg(true);
      return
    }

    handleCloseModal(e);
    const resultSubmit = await OCRStore.handleSubmitCancelRequestOCRFile(info.id, { reason });

    if (resultSubmit && resultSubmit.success) {
      if (searchStore.isSearch) searchStore.setAfterSearch(true)
      viewStore.enableRefreshList();
    }
  }

  useEffect(() => {
    return () => {
      setInputReason('');
      setIsShowErrMsg(false);
    }
    // eslint-disable-next-line
  }, [openModal])

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '500px' }} tabIndex="-1" role="dialog" id="cancel-request-ocr" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <h5 className="modal-title">{modalTitle}</h5>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-0 mt-n2">
                      <span> Bạn muốn từ chối duyệt chỉnh lý cho {info.type ? 'file' : 'folder'} <b>{formatNameFileFolder(info)}</b>?</span>
                      <div style={{ marginTop: '10px' }}>
                        <textarea
                          type="text"
                          className={`form-control ${isShowErrMsg ? 'error' : ''}`}
                          placeholder="Lý do từ chối"
                          style={{ minHeight: 90, maxHeight: 300 }}
                          value={reason}
                          ref={el => { if (el) el.focus(); }}
                          onInput={e => {setInputReason(e.target.value); setIsShowErrMsg(false);}}
                          onKeyDown={handleKeyDown}
                        />
                        {isShowErrMsg && <span className="error"> Vui lòng nhập lý do </span>}
                      </div>
                      <div className="nk-modal-action justify-end">
                        <ul className="btn-toolbar g-4 align-center">
                          <li><a href="# " className="link link-primary" onClick={handleCloseModal}> Bỏ qua </a></li>
                          <li><button className="btn btn-primary" onClick={e => handleClickSubmit(e)}> Đồng ý </button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
})

export default CancelRequestOCR;
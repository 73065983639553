import React, { useState, useEffect } from 'react';
import { useStore } from '../stores';
import useDebounce from '../hooks/debound';

function SearchInput(props) {
  const { viewStore, searchStore } = useStore();
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchTerm = useDebounce(inputValue, 1000);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleKeyDownSubmit();
    }
  }

  const handleKeyDownSubmit = async () => {
    const keyword = inputValue || null;
    let sort = {
      sortBy: viewStore.sortBy,
      sortOrder: viewStore.sortOrder,
    }
    const result = await searchStore.handleSubmitSearch(keyword, sort);

    if (result && result.success) {
      viewStore.setIsShowBreadcrumb(true);
      viewStore.enableRefreshList();
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleKeyDownSubmit(debouncedSearchTerm);
    } else {
      clearSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const clearSearch = () => {
    setInputValue('');
    searchStore.setIsSearch(false);
    viewStore.enableRefreshList();
  }

  const clearValue = () => {
    if (searchStore.isSearched) {
      setInputValue('');
      searchStore.setSearched(false);
    } 
  }
  
  return (
    <div className="nk-fmg-search vertical-bar">
      <input
        type="text"
        className="form-control border-transparent form-focus-none"
        placeholder="Tìm kiếm files, folders"
        style={{padding: "0px"}}
        value={inputValue}
        onInput={e => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        onChange={clearValue()}
      />
      {inputValue && (
        <div
          className="clearInput heightIcon"
          onClick={() => clearSearch()}
        >
          <em className="icon ni ni-cross"></em>
        </div>
      )}
      <div className="actions btn-group heightIcon">
        <em className="icon ni ni-search" onClick={handleKeyDownSubmit}></em>
      </div>
    </div>
  );
}

export default SearchInput;
import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from './Header';
import Sidebar from './Sidebar';
import RenameFile from './file-action/RenameFile';
import DeleteFile from './file-action/DeleteFile';
import ApproveFile from './file-action/ApproveFile';
import MoveFile from './file-action/MoveFile';
import DetailFile from './file-action/DetailFile';
import ViewLog from './file-action/ViewLog';
import ChangeOwner from './file-action/ChangeOwner';
import UpdateTag from './file-action/UpdateTag';
import ViewPDF from './file-action/ViewPDF';
import ViewImage from './file-action/ViewImage';
import ConfirmOCR from './file-action/ConfirmOCR';
import ApproveOCR from './file-action/ApproveOCR';
import UpdatePermission from './file-action/UpdatePermission';
import ViewOCR from './file-action/ViewOCR';
import EditOCR from './file-action/EditOCR';
import CancelOCR from './file-action/CancelOCR';
import DeletePermission from './file-action/DeletePermission';
import CancelRequestOCR from './file-action/CancelRequestOCR';
import ApproveMultipleFile from './file-action/ApproveMultipleFile';

import { useStore } from '../stores';

const Layout = observer((props) => {
  const { children } = props;
  const { viewStore } = useStore();

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      opacity: '0.3 !important',
      zIndex: theme.zIndex.drawer + 1000,
      color: '#fff',
    },
  }));
  const classes = useStyles();

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap">
          <Header></Header>
          <div className="nk-content p-0">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="nk-fmg">
                  <Sidebar></Sidebar>
                  <div>
                    <Backdrop className={classes.backdrop} open={viewStore.isLoading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
        {viewStore.isShowSidebar && (
          <div
            className="nk-sidebar-overlay"
            onClick={() => viewStore.setIsShowSidebar(false)}
          >
          </div>
        )}
      </div>
      <DetailFile />
      <ChangeOwner />
      <ViewLog />
      <MoveFile />
      <RenameFile />
      <DeleteFile />
      <ApproveFile />
      <UpdateTag />
      <ViewPDF />
      <ViewImage />
      <ConfirmOCR />
      <ApproveOCR />
      <UpdatePermission />
      <ViewOCR />
      <EditOCR />
      <CancelOCR />
      <DeletePermission />
      <CancelRequestOCR />
      <ApproveMultipleFile />
    </div>
  );
});

export default Layout;
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Draggable from "react-draggable";

import { useStore } from '../../stores';
import SVGIcon from '../SVGIcon';
import { formatDatetime, formatNameFileFolder } from '../../utils/formatters';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const ViewLog = observer((props) => {
  const { fileActionStore } = useStore();
  const classes = useStyles();
  const [infoLog, setInfoLog] = useState([]);

  const info = fileActionStore.info;
  const openModal = fileActionStore.isShowViewLog;

  const handleCloseModal = (e) => {
    e.preventDefault();

    fileActionStore.setShowModal('ViewLog', false)
  };

  const handleClickReturn = (e) => {
    fileActionStore.setShowModal('Detail', true);
    handleCloseModal(e);
  };

  useEffect(() => {
    async function getLog() {
      let response = await fileActionStore.getViewLog();
      if (response.success) {
        setInfoLog(response.data);
      }
    }
    if (openModal) {
      getLog();
    }

    return () => {
      setInfoLog([]);
    }
    // eslint-disable-next-line
  }, [openModal])

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Draggable
            onDrag={(e) => { e.preventDefault(); }}
            bounds="parent"
            handle=".modal-header"
            cancel=".close"
          >
            <Fade in={openModal}>
              <div style={{ width: '650px' }} tabIndex="-1" role="dialog" id="view-log" aria-modal="true">
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header align-center border-bottom-0">
                      <div className="nk-file-title">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <SVGIcon ext={info.extension || 'folder'}></SVGIcon>
                          </span>
                        </div>
                        <div className="nk-file-name">
                          <div className="nk-file-name-text">
                            <span className="title">
                              {formatNameFileFolder(info)} {'>> Logs'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <a href="# " className="close" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                        <em className="icon ni ni-cross-sm"></em>
                      </a>
                    </div>
                    <div className="modal-body pt-2 mt-n2" style={{ maxHeight: '500px', overflow: 'auto' }}>
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th style={{width: '10%'}}> Thời gian </th>
                            <th style={{width: '30%', minWidth: '135px'}}> Người thực hiện </th>
                            <th style={{width: '60%'}}> Hành động </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          infoLog.map((item, index) => (
                            <tr key={index}>
                              <td> {formatDatetime(item.createdAt)} </td>
                              <td> {item.name} </td>
                              <td> {item.note} </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer modal-footer-stretch bg-light">
                      <div className="modal-footer-between">
                        <div className="g">
                          <a href="# " className="link link-light" onClick={handleClickReturn}>
                            <em className="icon ni ni-curve-down-left"></em>
                            Quay lại
                          </a>
                        </div>
                        <div className="g">
                          <ul className="btn-toolbar g-3">
                            <li><a href="# " className="btn btn-outline-light btn-white" onClick={handleCloseModal}> Đóng </a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Draggable>
        </Modal>
      </ul>
    </div>
  );
});

export default ViewLog;
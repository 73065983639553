import moment from "moment";

export const BASE_64 = 'data:image/png;base64, ';

export const formatDate = (date) => {
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY');
}

export const formatDatetime = (date) => {
  if (!date) return '';
  return moment(date).format('HH:mm:ss DD/MM/YYYY');
}

export const formatViewedDatetime = (date) => {
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatOCRState = (state) => {
  const contents = {
    1: 'Đang chờ OCR',
    2: 'Đã hoàn thành OCR',
    3: 'Chờ duyệt chỉnh lý',
    4: 'Đã duyệt chỉnh lý'
  }
  return contents[state];
}

export const formatNameFileFolder = (info) => {
  return info.displayName + (info.extension ? `.${info.extension}` : '');
}

export const backgroundSymbol = (index) => {
  const background = {
    1: 'bg-purple',
    2: 'bg-pink',
    3: 'bg-light'
  }
  return background[index];
}

export const toLowerCase = (text) => {
  if (!text) return '';
  return text.toLowerCase();
}

export const capitalizeFirstLetter = s => (s && s[0].toUpperCase() + s.slice(1)) || '';

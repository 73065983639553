import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useStore } from '../stores';
import useWindowSize from '../hooks/window-size';

function Sidebar(props) {
  const { viewStore } = useStore();
  const windowSize = useWindowSize();
  const styleSidebar = {
    left: viewStore.isShowSidebar ? '270px' : '0px'
  }

  useEffect(() => {
    if (windowSize.width > 992) {
      viewStore.setIsShowSidebar(false);
    } else if (viewStore.isShowSidebar) {
      viewStore.setIsShowSidebar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  return (
    <div className="nk-fmg-aside toggle-screen-lg" data-content="files-aside" data-toggle-overlay="true" data-toggle-body="true"
      data-toggle-screen="lg" data-simplebar="init" style={styleSidebar}>
      <div className="simplebar-wrapper" style={{ margin: '0px' }}>
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer"></div>
        </div>
        <div className="simplebar-mask">
          <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
            <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden' }}>
              <div className="simplebar-content" style={{ padding: '0px' }}>
                <div className="nk-fmg-aside-wrap">
                  <div className="nk-fmg-aside-top">
                    <ul className="nk-fmg-menu">
                      <li>
                        <NavLink exact to="/" className="nk-fmg-menu-item" activeClassName="active">
                          <em className="icon ni ni-home-alt"></em>
                          <span className="nk-fmg-menu-text">Trang chủ</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/my-documents" className="nk-fmg-menu-item" activeClassName="active">
                          <em className="icon ni ni-folder"></em>
                          <span className="nk-fmg-menu-text">Tài liệu của tôi</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/projects" className="nk-fmg-menu-item" activeClassName="active">
                          <em className="icon ni ni-folder"></em>
                          <span className="nk-fmg-menu-text">Dự án</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/departments" className="nk-fmg-menu-item" activeClassName="active">
                          <em className="icon ni ni-folder"></em>
                          <span className="nk-fmg-menu-text">Phòng ban</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/awaiting-approval" className="nk-fmg-menu-item" activeClassName="active">
                          <em className="icon ni ni-share-alt"></em>
                          <span className="nk-fmg-menu-text">Tài liệu chờ duyệt</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="simplebar-placeholder" style={{ width: 'auto', height: '325px' }}></div>
      </div>
      <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
        <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div>
      </div>
      <div className="simplebar-track simplebar-vertical" style={{ visibility: 'hidden' }}>
        <div className="simplebar-scrollbar" style={{ height: '0px', display: 'none', transform: 'translate3d(0px, 0px, 0px)' }}>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
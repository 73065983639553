import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, useHistory, useParams } from "react-router-dom";

import { useStore } from '../../stores';
import { formatNameFileFolder } from '../../utils/formatters';
import FileActionPopup from '../FileActionPopup';
import SVGIcon from '../SVGIcon';
import { PAGE_LIST } from '../../constants/pages';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalWrapper: {
    width: '100%',
    height: 'calc(100vh - 63px)',
    marginBottom: '20px',
    position: 'absolute',
    top: '63px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#6e6e6e'
    },
    '&::-webkit-scrollbar': {
      width: '12px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#F5F5F5'
    }
  },
  modalDialog: {
    margin: '0 auto !important'
  },
  modalHeader: {
    width: '100%',
    position: 'fixed',
    top: '0px',
    left: '0px',
    zIndex: '1',
    background: '#e7e7e7',
    fontWeight: 'bold',
    borderRadius: 0
  },
  modalContent: {
    position: 'relative',
    borderRadius: 0,
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    alignItems: 'center'
  },
  fileDetail: {
    maxWidth: '65vw',
    minWidth: '300px',
    textAlign: 'center',
    '& img': {
      boxShadow: 'rgb(50 50 93 / 25%) 0px 10px 15px 1px, rgb(0 0 0 / 30%) -2px 7px 20px 1px'
    }
  },
  externalLink: {
    marginLeft: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#798bff',
    fontWeight: 'bold'
  }
}));

function NextIcon() {
  return (
    <svg className=" a-s-fa-Ha-pa" width="24px" height="24px" viewBox="0 0 24 24" focusable="false" fill="#000000">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  );
}

const ViewImage = observer((props) => {
  const { fileActionStore } = useStore();
  const [info, setInfo] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState({});

  const history = useHistory();
  const classes = useStyles();
  const getParam = useParams();

  const idFolder = getParam.id || '';
  const id = fileActionStore.info.id;
  const openModal = fileActionStore.isShowImage;

  const handleCloseModal = (e) => {
    e.preventDefault();
    fileActionStore.setFileImageUrl('');
    fileActionStore.setShowModal('Image', false);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const openInNewTab = (e) => {
    window.open(fileActionStore.fileImageUrl)
  }

  const handleClickBreadcrumb = (e) => {
    e.preventDefault();

    if (breadcrumb.id === idFolder) {
      handleCloseModal(e);
    } else {
      history.push(breadcrumb.uri);
    }
  }

  useEffect(() => {
    async function getDocInfo() {
      let response = await fileActionStore.getDocumentInfo(id);
      if (response.success) {
        const page = PAGE_LIST.find(page => page.folderType === response.data?.folderTypeRoot);

        if (page && response.data?.breadCrumb?.length) {
          let res_data_breadcrumb = response.data.breadCrumb;
          res_data_breadcrumb.forEach((item, index) => {
            item.uri = index === 0 ? page.uri : `${page.uri}/folder/${item.id}`;
          })
          setBreadcrumb(res_data_breadcrumb[res_data_breadcrumb.length - 1]);
        }

        setInfo({ ...fileActionStore.info, ...response.data });
        setIsLoaded(true);
      }
    }

    if (openModal && id) {
      getDocInfo();
    }

    return () => {
      setInfo({});
      setIsLoaded(false);
    }
    // eslint-disable-next-line
  }, [openModal, id])

  return (
    <div className="nk-fmg-actions" style={{ paddingBottom: '5px' }}>
      <ul className="nk-block-tools g-3">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <>
              <div className={`modal-header align-center border-bottom-0 ${classes.modalHeader}`}>
                <div className="nk-file-title">
                  <Link
                    style={{ color: '#526484'}}
                    key={breadcrumb.id}
                    to={breadcrumb.uri || '# '}
                    onClick={handleClickBreadcrumb}
                  >
                    <span>{breadcrumb.name}</span>
                  </Link>
                  <NextIcon />
                  {formatNameFileFolder(fileActionStore.info)}
                  <FileActionPopup info={info} icon={'ni-caret-down-fill'}></FileActionPopup>
                  <Tooltip title="Mở file trong tab mới">
                    <em className={`icon ni ni-external-alt ${classes.externalLink}`} onClick={openInNewTab}></em>
                  </Tooltip>
                </div>
                <a href="# " className="close" onClick={handleCloseModal}>
                  <em className="icon ni ni-cross-sm"></em>
                </a>
              </div>
              <div className={classes.modalWrapper} tabIndex="-1" role="dialog" id="file-details" aria-modal="true" onClick={handleCloseModal}>
                <div className={`modal-dialog modal-md ${classes.modalDialog}`} role="document">
                  <div className={`modal-content view-pdf ${classes.modalContent}`}>
                    <div className={`nk-file-details ${classes.fileDetail}`} onClick={handleChildClick}>
                      {isLoaded && (
                        fileActionStore.fileImageUrl ? (
                          <img
                            src={fileActionStore.fileImageUrl}
                            alt={info.displayName}
                            style={{ borderRadius: '5px', height: '100%' }}
                          />
                        ) : (
                          <SVGIcon ext={(info && info.extension) || ''}></SVGIcon>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Fade>
        </Modal>
      </ul>
    </div>
  );
})

export default ViewImage;